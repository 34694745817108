
import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import './index.css';


const ProfileChangePassword = () => {
   const requestsData = useSelector(state => state.companyReducer);
   const requests = requestsData.requests;

   return <>
      <Card>
         <div className="Profile-container">
            INside ProfileChangePassword
         </div>
      </Card>
   </>
}



export default ProfileChangePassword;