import React, { useEffect, useState } from "react";
import EasyCompareLogo from "./EasyCompareLogo";
import "./Navbar.css";
import { CgProfile, CgMenu, CgClose } from "react-icons/cg";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18n from "../../i18n";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import jwt_decode from "jwt-decode";
import LogoutBtn from "./Logout";
import { useMediaQuery } from "react-responsive";

const languages = [
  { value: "de", text: "German", code: "de" },
  { value: "en", text: "English", code: "gb" },
  { value: "fr", text: "French", code: "fr" },
  { value: "it", text: "Italian", code: "it" },
];



export const currentLang = cookies.get("i18next") || "de";
// window.location.reload()


const Navbar = (props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [loggedIn, setLoggedIn] = useState(false);
  const [navURL, setNavURL] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-width: 1024px)",
  });

 


  



  useEffect(() => {
    if (cookies.get("jwtToken") !== undefined) {
      setLoggedIn(true);
      let decoded = jwt_decode(cookies.get("jwtToken"));
      if (decoded.role.includes("company")) {
        setNavURL("/account/requests");
      } else if (decoded.role.includes("ADMIN")) {
        setNavURL("/admin/companydetails");
      } else if (decoded.role.includes("USER")) {
        setNavURL("/user/requests");
      } else {
        setNavURL("");
      }
    } else {
      setLoggedIn(false);
      setNavURL("");
    }
  }, []);
  useEffect(() => {
    setOpenDrawer(false);
  }, [isDesktopOrLaptop]);
  
  const [languageButtonClicked, setLanguageButtonClicked] = useState(false);


  useEffect(() => {
    if (languageButtonClicked) {
      
      window.location.reload()
      
      // Reset the state to false if needed
      setLanguageButtonClicked(false);
    }
  }, [languageButtonClicked]);
  

  

  return (
    <>
      <div className={isDesktopOrLaptop ? "nav-main mobileView" : "nav-main desktopView"}>
        <div className="drawer-icon">
          <span onClick={() => setOpenDrawer(!openDrawer)}>
            {" "}
            {openDrawer ? <CgClose size={30}/> : <CgMenu size={30}/>}
          </span>
        </div>
        <div className="nav-logo">
          <Link to="/">
            {" "}
            <span className="logo-easy-navbar">easy-<span className="logo-compare-navbar">compare</span></span>
            {/* <EasyCompareLogo /> */}
          </Link>
        </div>

        <ul
          className={
            openDrawer
              ? "nav-menu open-mobile-menu"
              : "nav-menu"
          }
          style={{ visibility: props.menuVisibility }}>
          <NavLink to="/">{t("Moving")}</NavLink>
          <NavLink to="/Cleaning">{t("Cleaning")}</NavLink>
          <NavLink to="/Painting">{t("Painting")}</NavLink>
          <NavLink to="/Carpenter">{t("Carpenter")}</NavLink>

          <div className="MultiLang">
            <label htmlFor="LangBtn"></label>
            <div>
              <Select
                sx={{
                  boxShadow: "none",
                  cursor: "pointer",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
                labelId="demo-multiple-checkbox-label"
                id="LangBtn"
                name="LangBtn"
                defaultValue={currentLang}
                value={currentLang}
                onClick={() => setLanguageButtonClicked(true)}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                {languages.map((item) => (
                  
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    style={{ display: "flex", gap: "1rem" }}>
                    <span className={`fi fi-${item.code}`}> </span>
                    <ListItemText primary={item.text} />
                  </MenuItem>
                
                ))}
              </Select>
            </div>
          </div>

        </ul>
        <ul
          className={
            openDrawer
              ? "nav-signup open-mobile-menu"
              : "nav-signup"
          }
          style={{ visibility: props.signUpVisibility }}>
          {loggedIn ? (
            <>
              <NavLink to={navURL}>{t("Dashboard")}</NavLink>
              <LogoutBtn />
            </>
          ) : (
            <>
              <NavLink to="/Login">{t("Login")}</NavLink>
              <NavLink to="/Registration">{t("Register-as-company")}</NavLink>
              <li>
                {/* change here */}
                {/* <NavLink to="/User/Registration">
                  <span
                    type="button"
                    className={isDesktopOrLaptop ? "" : "btn btn-primary"}
                    >
                    <CgProfile /> {t("Register")}
                  </span>
                </NavLink> */}
              </li>
            </>
          )}

          
        </ul>
      </div>
    </>
  );
  
};



export default Navbar;

// export { currentLang };
