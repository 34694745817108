import React from "react";
import { useTranslation } from 'react-i18next'
import "./index.css";
const ServiceInfo = () => {
   const { t } = useTranslation();

   return <>
      <div>
         <span className="sub-heading hidden"> {t("Moving-Service")}</span>
         <div className="main-heading">{t("MovingServiceInfoHeading")}</div>
         <div className="description">
            <p>
               {t("movingService-desc1")}
            </p>
            <p>
               {t("movingService-desc2")}
            </p>
            <p>
               {t("movingService-desc3")}
            </p> 
         </div>
         <div>
            {/* <a className="btn btn-primary" style={{ display: "inline" }}>{t("Get-Started-Btn")}</a> */}
         </div>
      </div>
   </>
}

export default ServiceInfo;