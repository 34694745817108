import React, { useState } from "react";
import '../../common/Hero.css';
import './AdminLogin.css';
import Axios from "axios";
import SkyScraper from '../../../images/skyscraper.jpg';
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { companyloginAction } from "../../../redux/actions/companyAction";
import { saveJWT } from "../../../redux/actions/saveJWT";
import { useTranslation } from 'react-i18next';
import { emailRegex } from "../../../utils/utils";

export default function AdminLogin() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [userType, setUserType] = useState('');
	const dispatch = useDispatch();

	const handleSubmit = (event) => {
		event.preventDefault();
		const { emailId, password } = document.forms[0];

		const requestData = {
			companyEmail: emailId.value,
			password: password.value,
			type: "ADMIN",
		};

		const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";

		Axios.post(REACT_APP_BACKEND_SERVER2 + "/login", requestData).then((response) => {
			if (response.data.response_code === '200') {
				Cookies.set('jwtToken', response.data.jwt, { path: '/' });
				var decoded = jwt_decode(Cookies.get('jwtToken'));
				dispatch(saveJWT(response.data.jwt));
				if (decoded.role.includes("company")) {
					dispatch(companyloginAction(response.data.company));
					navigate("/account/requests");
				} else if (decoded.role.includes("ADMIN")) {
					navigate("/admin/companydetails");
				} else if (decoded.role.includes("USER")) {
					navigate("/user/requests");
				} else {
					alert("Something went wrong.. Please try again");
				}
			} else {
				console.log("Unexpected response code:", response.data.response_code);
			}
		}).catch((err) => {
			console.log("Error occurred during login:", err);
		});
	};

	const fnSubSelectChange = (e) => {
		setUserType(e.target.value);
	};

	// Validations
	const [formErrors, setFormErrors] = useState({
		userType: "",
		emailId: "",
		password: "",
	});

	const inputEvent = (e) => {
		const { name, value } = e.target;
		setFormErrors(prev => {
			const stateObj = { ...prev, [name]: "" };

			switch (name) {
				case 'emailId':
					stateObj[name] = emailRegex.test(value) && value.length > 0 ? "" : "Invalid email address.";
					break;
				case 'password':
					stateObj[name] = value.length < 8 && value.length > 0 ? "Minimum 8 characters required." : "";
					break;
				default:
					break;
			}
			return stateObj;
		});
	}

	return (
        <>
            <Navbar menuVisibility={"hidden"} />
            <div className="hero login-page" style={{
                backgroundImage: `url(${SkyScraper})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover"
                }}>
                <div className="login-container">
                    <div className="login-box">
                        <div className="avatar">
                            <i className="fas fa-user-lock"></i>
                        </div>
                        <h1>{t('Sign-In')}</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="textbox">
                                <input
                                    type="text"
                                    id="emailId"
                                    name="emailId"
                                    placeholder={t('Email-Address')}
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => setFormErrors(prev => ({ ...prev, emailId: "" }))}
                                />
                                {formErrors.emailId && <span className='error'>{formErrors.emailId}</span>}
                            </div>
                            <div className="textbox">
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder={t('Password')}
                                    autoComplete="current-password"
                                    onChange={(e) => setFormErrors(prev => ({ ...prev, password: "" }))}
                                />
                                {formErrors.password && <span className='error'>{formErrors.password}</span>}
                            </div>
                            <div className="remember-me">
                                <input type="checkbox" id="remember" name="remember" />
                                <label htmlFor="remember">{t('Remember-Me')}</label>
                            </div>
                            <button type="submit" className="btn">{t('Sign-In')}</button>
                        </form>
                        <div className="forgot-password">
                            <a href="#">{t('Forgot-password')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
