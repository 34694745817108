import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { getAuthAxios } from "../../../../utils/utils";
import { FaChartBar } from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "@mui/material";
import getGraphRequestData from "../../../../utils/getGraphRequestData";
import { dummyGraphdata } from "../../../../utils/getGraphRequestData";
import { useTranslation } from "react-i18next";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Overview = () => {
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({});
  const [currentWeekCount, setCurrentWeekCount] = useState(0);
  const [finalGraphData, setFinalGraphData] = useState({
    labels: [""],
    datasets: [
      {
        label: "",
        data: [10, 20, 15, 25, 30, 20, 15],
        backgroundColor: "blue",
      },
    ],
  });

  useEffect(() => {
    authAxios
      .get("/company/charts")
      .then((response) => {
        if (response.status === 200) {
          setChartData(response.data.chartData);
        } else {
          alert(JSON.stringify(response));
        }
      })
      .catch((err) => {
        console.log("Error Happened at index.js");
      });
  }, []);

  useEffect(() => {
    if (Object.keys(chartData).length > 0) {
      let [graphData, currentWeekCount] = getGraphRequestData(
        chartData.perDayData
      );
      // let [graphData, currentWeekCount] = getGraphRequestData(dummyGraphdata);
      setCurrentWeekCount(currentWeekCount);
      setFinalGraphData(graphData);
    }
  }, [chartData]);

  // ///////////////////////////////

  const options = { maintainAspectRatio: false };

  // {"perDayData":{"14/03/2023":1,"13/03/2023":1,"12/03/2023":8},"perMonthData":{"03":10},"perYearData":{"2023":{"03":10}},"dayCount":1,"monthCount":10,"yearCount":10}
  // ///////////////////////////////

  return (
    <>
      <div className="chart-container">
        <Card sx={{ padding: "1rem" }}>
          <div className="request-counter-container">
            <div className="counter-label">
              <span>{t("req-counter")}</span>
            </div>
            <div className="counter-stats">
              <Card>
                <div className="counter-box">
                  <span className="counter-box-logo">
                    <FaChartBar />
                  </span>
                  <span className="counter-box-label">{t("today")}</span>
                  <span className="counter-box-count">
                    {chartData.dayCount}
                  </span>
                </div>
              </Card>
              <Card>
                <div className="counter-box">
                  <span className="counter-box-logo">
                    <FaChartBar />
                  </span>
                  <span className="counter-box-label">{t("this-week")}</span>
                  <span className="counter-box-count">{currentWeekCount}</span>
                </div>
              </Card>
              <Card>
                <div className="counter-box">
                  <span className="counter-box-logo">
                    <FaChartBar />
                  </span>
                  <span className="counter-box-label">{t("this-month")}</span>
                  <span className="counter-box-count">
                    {chartData.monthCount}
                  </span>
                </div>
              </Card>
              <Card>
                <div className="counter-box">
                  <span className="counter-box-logo">
                    <FaChartBar />
                  </span>
                  <span className="counter-box-label">{t("this-year")}</span>
                  <span className="counter-box-count">
                    {chartData.yearCount}
                  </span>
                </div>
              </Card>
            </div>
          </div>

          <div className="request-statistics">
            <div>
              <span className="counter-label">{t("weekly-stat")}</span>
            </div>
            <Card>
              <div className="stats-chart" style={{ width: "100%" }}>
                <Bar data={finalGraphData} options={options}></Bar>
              </div>
            </Card>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Overview;
