import React from "react";
import Navbar from "./common/Navbar";
import { useTranslation } from 'react-i18next';
import './PrivacyAndPolicy.css';
import { Card, CardContent } from "@mui/material";
// import OldPaper from '../images/old-paper-5.jpg';

const PrivacyAndPolicy = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="hero-about-us">
                <Navbar />
            </div>

            <header>
                <h1>{t("Privacy-Policy")}</h1>
                {/* <p className="extras">{t("subheading1-privacy")}</p>
                <p className="extras">{t("proficiency-privacy")}</p> */}
            </header>

            <Card>
                <div className="parent-for-privacy">
                    <div className="outline" style={{backgroundColor: "white"}}>    
                    <div className="privpolicy-content">
                        <p className="paragraphs-privacy">
                            {t("IntroPart1")}
                        </p>
                        <br />
                        <p className="paragraphs-privacy">
                            {t("IntroPart2")}
                        </p>
                        <br />
                        <p className="paragraphs-privacy">
                            {t("IntroPart3")}
                        </p>
                    </div>                    
                        <div className="privpolicy-content">
                            <h2>{t("Owner")}</h2>
                            <p className="paragraphs-privacy"> {t("intro")}<br/>
                                <b>{t("Easy Compare")}</b> <br/>
                                <b>{t("Greifenseestrasse")} 25</b> <br/>
                                <b>{t("8604 Volketswil")}</b> <br/>
                                <b>{t("Switzerland")}</b><br/> 
                                <b>{t("E-mail: info@easy-compare.ch")}</b><br/>
                                <b>{t("Tel: +41 43 535 65 43")}</b>
                            </p>

                            <h2>{t("Header1")}</h2>
                            <p className="paragraphs-privacy"> 
                            {t("Header2")}
                            </p>



                            <h2>{t("Header3")}</h2>
                            <p className="paragraphs-privacy">  
                                (1) {t("Info1")}<br/>
                                (2) {t("Info2")}<br/>
                                (3) {t("Info3")}<br/>
                                (4) {t("Info4")}<br/>
                                (5) {t("Info5")}<br/>
                                (6) {t("Info6")}<br/>
                                (7) {t("Info7")}<br/>
                                (8) {t("Info8")}<br/>
                                (9) {t("Info9")}<br/>
                                <br/>
                                <br/>
                                {t("Para2")}
                            </p>



                            <h2>{t("Header4")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para3")}
                            </p>



                            <h2>{t("Header4")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para4")}
                            </p>


                            <h2>{t("Header5")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para5")}
                            </p>



                            <h2>{t("Header6")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para6")}
                            </p>

                            <h2>{t("Header7")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para7")}
                            </p>

                            <h2>{t("Header8")}</h2>
                            <ol>
                                <li>
                                    <b>{t("SubHeading1")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para8")}
                                    </p>
                                </li>

                                <li>
                                    <b>{t("SubHeading2")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para9")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading3")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para10")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading4")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para11")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading5")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para12")}
                                    </p>
                                </li>
                            </ol>
                            

                            <h2>{t("Header9")}</h2>
                            <ol>
                                <li>
                                    <b>{t("SubHeading6")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para13")}
                                    </p>
                                </li>

                                <li>
                                    <b>{t("SubHeading7")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para14")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading8")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para15")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading9")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para16")}
                                    </p>
                                </li>

                                <li>
                                    <b>{t("SubHeading10")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para17")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading11")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para18")}
                                    </p>
                                </li>
                            </ol>


                            <h2>{t("Header10")}</h2>
                            <ol>
                                <li>
                                <b>{t("SubHeading12")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para19")}
                                    </p>
                                </li>

                                <li>
                                    <b>{t("SubHeading13")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para20")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading14")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para21")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading15")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para22")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading16")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para23")}
                                    </p>
                                </li>
                            </ol>


                            <h2>{t("Header11")}</h2>
                            <ol>
                                <li>
                                    <b>{t("SubHeading17")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para24")}
                                    </p>
                                </li>

                                <li>
                                    <b>{t("SubHeading18")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para25")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading19")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para26")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading20")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para27")}
                                    </p>
                                </li>

                                <li>
                                    <b>{t("SubHeading21")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para28")}
                                    </p>
                                </li>


                                <li>
                                    <b>{t("SubHeading22")}</b>
                                    <p className="paragraphs-privacy">
                                        {t("Para29")}
                                    </p>
                                </li>
                            </ol>
                            <br />
                            <br />

                            <h2>{t("Header12")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para42")}
                            </p>

                            <h2>{t("Header13")}</h2>
                            <p className="paragraphs-privacy"> 
                                {t("Para43")}
                            </p>

                            <ol>
                                <li>
                                    <h3>{t("SubHeading23")}</h3>
                                    <p className="paragraphs-privacy">
                                        {t("GoogleMapsPart1")} <br/>
                                        <a href="mailto:support-deutschland@google.com">support-deutschland@google.com</a>,<br/> {t("GlobalWebsite")} <a href="https://www.google.com/" target="__blank">https://www.google.com/</a>. {t("GoogleMapsPart2")} <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermit
                                        tlung-ins-ausland.html" target="__blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>
                                        . {t("GoogleMapsPart3")} <a href="https://privacy.google.com/intl/de/businesses/mapscontrollerterms/" target="__blank">https://privacy.google.com/intl/de/businesses/mapscontrollerterms/</a>. {t("GoogleMapsPart6")} <a href="https://www.dataprivacyframework.gov/list" target="__blank">https://www.dataprivacyframework.gov/list</a>.
                                        {t("GoogleMapsPart4")} <a href="https://policies.google.com/privacy" target="__blank">https://policies.google.com/privacy</a>. {t("GoogleMapsPart5")} <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de" target="__blank"></a>.
                                    </p>
                                </li>

                                <li>
                                    <h3>{t("SubHeading24")}</h3>
                                    <p className="paragraphs-privacy">
                                        {t("GstaticPart1")} <a href="mailto:support-deutschland@google.com">support-deutschland@google.com</a>, {t("GlobalWebsite")} <a href="https://www.google.com/" target="__blank">https://www.google.com/</a>. {t("GstaticPart2")}  <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" target="__blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.
                                        {t("GstaticPart3")} <a href="https://www.dataprivacyframework.gov/list" target="__blank">https://www.dataprivacyframework.gov/list</a>. {t("GstaticPart4")}   <a href="https://policies.google.com/privacy" target="__blank">https://policies.google.com/privacy</a>.
                                        {t("GstaticPart5")}  <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de" target="__blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de</a>.
                                    </p>
                                </li>
                            </ol>


                            <h1>{t("Header14")}</h1>
                            <p className="paragraphs-privacy">
                                {t("Para55")}
                            </p>


                            <ol>
                                <li>
                                    <h3>{t("SubHeading25")}</h3>
                                    <p className="paragraphs-privacy">
                                        {t("GoogleAPIPart1")} <a href="mailto:support-deutschland@google.com">support-deutschland@google.com</a>,  {t("GlobalWebsite")} <a href="https://www.google.com/" target="__blank">https://www.google.com/</a>. {t("GoogleAPIPart2")}  <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermitlung-ins-ausland.html" target="__blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermitlung-ins-ausland.html</a>.
                                        {t("GoogleAPIPart3")} <a href="https://www.dataprivacyframework.gov/list" target="__blank">https://www.dataprivacyframework.gov/list</a>. {t("GoogleAPIPart4")} <a href="https://policies.google.com/privacy" target="__blank">https://policies.google.com/privacy</a>.
                                        {t("GoogleAPIPart5")} <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de" target="__blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de</a>.
                                    </p>
                                </li>

                                <li>
                                    <h3>{t("SubHeading26")}</h3>
                                    <p className="paragraphs-privacy">
                                        {t("GoogleFontsPart1")} <a href="mailto:support-deutschland@google.com">support-deutschland@google.com</a>,  {t("GlobalWebsite")} <a href="https://www.google.com/" target="__blank">https://www.google.com/</a>. {t("GoogleFontsPart2")}  <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermitlung-ins-ausland.html" target="__blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermitlung-ins-ausland.html</a>.
                                        {t("GoogleFontsPart3")} <a href="https://www.dataprivacyframework.gov/list" target="__blank">https://www.dataprivacyframework.gov/list</a>. {t("GoogleFontsPart4")} <a href="https://policies.google.com/privacy" target="__blank">https://policies.google.com/privacy</a>.
                                        {t("GoogleFontsPart5")} <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de" target="__blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl=de</a>.
                                    </p>
                                </li>

                                <li>
                                    <h3>{t("SubHeading26")}</h3>
                                    <p className="paragraphs-privacy">
                                        {t("RechtstextPart1")} {t("GlobalWebsite")} <a href="https://www.legally-ok.com/" target="__blank">https://www.legally-ok.com/</a>. {t("RechtstextPart2")} <a href="https://www.legally-ok.com/datenschutz/" target="__blank">https://www.legally-ok.com/datenschutz/</a>.
                                    </p>
                                </li>
                            </ol>


                            <h2>{t("Header15")}</h2>
                            <br />
                            <br />

                            <h3>{t("Header16")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para56")}
                            </p>

                            <h3>{t("Header17")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para57")}
                            </p>

                            <h3>{t("Header18")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para58")}
                            </p>

                            <h3>{t("Header19")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para59")}
                            </p>

                            <table style={{width: "50%"}}>
                                <tr>
                                    <th><b>{t("Misc1")}</b></th>
                                    <td>i18next</td>
                                </tr>
                                <tr>
                                    <th><b>{t("Misc2")}</b></th>
                                    <td>www.easy-compare.ch, easy-compare.ch</td>
                                </tr>
                                <tr>
                                    <th><b>{t("Misc3")}</b></th>
                                    <td>Website operator(#responsible-entity)</td>
                                </tr>
                                <tr>
                                    <th><b>{t("Misc4")}</b></th>
                                    <td>{t("Misc8")}</td>
                                </tr>
                                <tr>
                                    <th><b>{t("Misc5")}</b></th>
                                    <td>{t("Misc9")}</td>
                                </tr>
                                <tr>
                                    <th><b>{t("Misc6")}</b></th>
                                    <td>{t("Misc10")}</td>
                                </tr>
                                <tr>
                                    <th><b>{t("Misc7")}</b></th>
                                    <td>{t("Misc11")}</td>
                                </tr>
                            </table>


                            <h2>{t("Header20")}</h2>
                            <p className="paragraphs-privacy">
                                {t("Para44")}
                            </p>


                            <h2>{t("Header21")}</h2>
                            <p className="paragraphs-privacy">
                                {t("Para45")}
                            </p>


                            <h2>{t("Header22")}</h2>
                            <br />
                            <br />
                            <h3>{t("Header23")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para46")}
                            </p>

                            <h3>{t("Header24")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para47")}
                            </p>

                            <h3>{t("Header25")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para48")}
                            </p>
                            
                            <h3>{t("Header26")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para49")}
                            </p>
                            <ol style={{marginLeft: "30px"}}>
                                <li>
                                    <b>{t("Para30")}</b>
                                </li>

                                <li>
                                    <b>{t("Para31")}</b>
                                </li>


                                <li>
                                    <b>{t("Para32")}</b>
                                </li>


                                <li>
                                    <b>{t("Para33")}</b>
                                </li>


                                <li>
                                    <b>{t("Para34")}</b>
                                </li>
                            </ol>

                            <p className="paragraphs-privacy">
                                {t("Para50")}
                            </p>
                            <ol style={{marginLeft: "30px"}}>
                                <li>
                                    <b>{t("Para35")}</b>
                                </li>

                                <li>
                                    <b>{t("Para36")}</b>
                                </li>


                                <li>
                                    <b>{t("Para37")}</b>
                                </li>


                                <li>
                                    <b>{t("Para38")}</b>
                                </li>
                            </ol>



                            <h3>{t("Header27")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para51")}
                            </p>

                            <h3>{t("Header28")}</h3>
                            <p className="paragraphs-privacy">
                                You can exercise your rights at any time by contacting us using the contact details below:<br />
                                Easy Compare<br />
                                Greifenseestrasse 25<br />
                                8604 Volketswil<br />
                                Switzerland<br />
                                E-mail: info@easy-compare.ch<br />
                                Tel: +41 43 535 65 43<br />
                            </p>


                            <h2>{t("Header29")}</h2>
                            <p className="paragraphs-privacy">
                                {t("Para52")}
                            </p>
                            <ol style={{marginLeft: "30px"}}>
                                <li>
                                    <b>{t("Para39")}</b>
                                </li>

                                <li>
                                    <b>{t("Para40")}</b>
                                </li>


                                <li>
                                    <b>{t("Para41")}</b>
                                </li>
                            </ol>

                            <p className="paragraphs-privacy">
                                {t("Para53")}
                            </p>


                            <h3>{t("Header30")}</h3>
                            <p className="paragraphs-privacy">
                                {t("Para54Part1")} <a href="https://www.edoeb.admin.ch/edoeb/de/home/deredoeb/kontakt.html" target="__blank">https://www.edoeb.admin.ch/edoeb/de/home/deredoeb/kontakt.html</a>. {t("Para54Part2")}
                            </p>
                            



                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default PrivacyAndPolicy;