import { Form} from 'react-bootstrap'
import { useSelector } from "react-redux";
import React, { useState } from "react"
import './index.css';
import { makeStyles } from "@material-ui/core/styles";
import {Input, InputLabel, Button, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Card } from '@mui/material';

const ProfileGallery = () => {
   const requestsData = useSelector(state => state.companyReducer);
   const requests = requestsData.requests;
   const [images, setImages] = useState([]);
   const [formErrors, setformErrors] = useState('');
   const [fileUrl, setFileUrl] = useState('');
   const classes = useStyles();
   
   const handleImageChange = (event) => {
      setImages([...images, ...event.target.files]);
      setformErrors("");
      const formData = new FormData();
      images.forEach((image, index) => {
         formData.append(`image-${index}`, image);
      });

      setFileUrl(formData);
   };

   const handleImageDelete = (index) => {
      setImages(images.filter((_, i) => i !== index));
      const formData = new FormData();
      images.forEach((image, index) => {
         formData.append(`image-${index}`, image);
      });

      setFileUrl(formData);
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();

      images.map((image, index) => {
         console.log(image);
         formData.append(`image-${index}`, image)
      });

      try {
         const response = await fetch("/api/images", {
            method: "POST",
            body: formData,
         });
         const data = await response.json();
         console.log(data);
      } catch (error) {
         console.error(error);
      }
   };



   return <>
   <Card>
      <div className="Profile-container">
         <div className="category-component">
            <div className="category-container">
               <span className="card-box" style={{ flexDirection: "column" }}>
                  <Form.Group className="text-center picture-desc mb-1">
                     <InputLabel htmlFor="images">Upload photos, sketches so our partners can get a better idea of your project.</InputLabel>
                     <label htmlFor="images">
                        <Input
                           id="images"
                           type="file"
                           multiple
                           className={classes.input}
                           onChange={handleImageChange}
                        />
                        <Button variant="contained" component="span">
                           Browse
                        </Button>
                     </label>
                     {images.length > 0 ?
                        <List>
                           {images.map((image, index) => (
                              <ListItem key={index}>
                                 <ListItemAvatar>
                                    <Avatar
                                       alt={image.name}
                                       src={URL.createObjectURL(image)}
                                       className={classes.avatar}
                                    />
                                 </ListItemAvatar>
                                 <ListItemText primary={image.name} />
                                 <IconButton onClick={() => handleImageDelete(index)}>
                                    <DeleteIcon />
                                 </IconButton>
                              </ListItem>
                           ))}
                        </List> :
                        <List>

                        </List>
                     }
                  </Form.Group>
                  {formErrors && <span className='error'>{formErrors}</span>}
               </span>
               <span className="prevNext-btn card-box">

               </span>
            </div>
         </div>
      </div>
   </Card>
   </>
}

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      display: "flex",
      flexDirection: "column",
   },
   input: {
      display: "none",
   },
   avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
   },
}));

export default ProfileGallery;