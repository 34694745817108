import React from "react";
import tickmarkImg from '../../images/tickmarkImg.svg';
import { useTranslation } from 'react-i18next'
import './PaintingPricing.css'


const EmployeeOffer = (props) => {
   const { t } = useTranslation();
   return <>
      <div>
         <span className="sub-heading hidden"> {t("Painting-service")}</span>
         <div className="main-heading" style={{ fontWeight: '600', fontSize: '2.4rem' }}>{t("Painting-Heading")}</div>
         <div className="description-other">
            {
               (props.OfferContent).map((value, index) => {
                  return <span className="options" key={index}>
                     <img className="tickmarkImg" src={tickmarkImg} alt="tickmarkImg" />
                     <span>{value}</span>
                  </span>
               })
            }
         </div>
      </div>
   </>
}

export default EmployeeOffer;