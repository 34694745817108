import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { getAuthAxios } from "../../../utils/utils";
import "../AccountPage/index.css";
import { recordsPerPage } from "../../../utils/utils";
import "../../common/common.css";
import ReactPaginate from "react-paginate";
import Loader from "../../common/Loader";
const TopupRequests = () => {
  const [topUpRequests, setTopUpRequests] = useState();
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get("/admin/topUpRequests")
      .then((response) => {
        if (response.status === 200) {
          setTopUpRequests(response.data.topUpList);
          setIsLoading(false);
        } else {
          alert(JSON.stringify(response));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("catch block CompanyDetails" + err);
      });
  }, []);

  const [startItemIndex, setStartItemIndex] = useState(0);
  const [pageCount, setPageCount] = useState();

  const currentItems = useMemo(() => {
    if (topUpRequests !== undefined && topUpRequests.length !== 0) {
      setPageCount(Math.ceil(topUpRequests.length / recordsPerPage));
      const endItemIndex = startItemIndex + recordsPerPage;
      // console.log(`Loading items from ${startItemIndex} to ${endItemIndex}`);
      return topUpRequests.slice(startItemIndex, endItemIndex);
    } else {
      return topUpRequests;
    }
  }, [topUpRequests, startItemIndex]);

  const handlePageClick = (event) => {
    const newStartIndex =
      (event.selected * recordsPerPage) % topUpRequests.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newStartIndex}`);
    setStartItemIndex(newStartIndex);
  };

  return (
    <>
      <div>
        {topUpRequests !== undefined &&
        topUpRequests !== null &&
        topUpRequests.length !== 0 ? (
          <div className="accordian-request-container">
            {currentItems.map((list) => (
              <Accordian key={list.id} list={list} />
            ))}
          </div>
        ) : (
          isLoading &&  <Loader /> 
        )}
      </div>
      <div className="custom-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

const Accordian = ({ list }) => {
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const [details, setDetails] = useState(list.company);
  const [status, setStatus] = useState(list.status);
  const [isLoading, setIsLoading] = useState(false);
  const handleAccept = () => {
    if (
      window.confirm("Are you sure You want accept the request ?")
    ) {
      setIsLoading(true);
      authAxios
        .post("/admin/topUpRequest/decision", { topUpId:list.id,approved:true })
        .then((response) => {
          if (response.status === 200) {
            alert("Request has been accepted successfully.");
            setStatus("Accepted");
            setIsLoading(false);
          } else {
            alert(JSON.stringify(response));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          alert("catch block" + err);
        });
    }
  };
  const handleReject = () => {
    if (
      window.confirm("Are you sure You want reject the request ?")
    ) {
      setIsLoading(true);
      authAxios
        .post("/admin/topUpRequest/decision", { topUpId:list.id,approved:false })
        .then((response) => {
          if (response.status === 200) {
            alert("Request has been rejected successfully.");
            setStatus("Rejected");
            setIsLoading(false);
          } else {
            alert(JSON.stringify(response));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          alert("catch block" + err);
        });
    }
  };

  return (
    <>
    {isLoading &&  <Loader /> }
      <div className="topup-container">
        <div className="wallet">
         <div className="header-content admin-active">
            <span>
            <h2>{details.name}</h2>
            <br/>
            {list.createdAt.split("T")[0]}
            </span>
            <div style={{paddingRight: "1rem",display:"flex",flexDirection:"column",gap:"5px"}}>
               <h3 style={{textAlign:"center",border:"1px solid blue",padding:"2px"}}>$ {list.amount}</h3>
               <Button variant="contained" color={`${status==="New"?"primary":status==="Accepted"?"success":"warning"}`} size="small">{status}</Button>
            </div>
         </div>
            <Grid container spacing={1} sx={{ mb: "7px" }}>
               <Grid item xs={4} sx={{ fontWeight: "900" }}>Commercial Register Id</Grid>
               <Grid item xs={8} >{details.email}</Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mb: "7px" }}>
               <Grid item xs={4} sx={{ fontWeight: "900" }}>Contact</Grid>
               <Grid item xs={8} >{details.email}</Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mb: "7px" }}>
               <Grid item xs={4} sx={{ fontWeight: "900" }}>Website</Grid>
               <Grid item xs={8}>{details.website}</Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mb: "7px" }}>
               <Grid item xs={4} sx={{ fontWeight: "900" }}>Address</Grid>
               {/* <Grid item xs={8}>{details.address.location}</Grid> */}
            </Grid>
            <Grid container spacing={1} sx={{ mb: "7px" }}>
               <Grid item xs={4} sx={{ fontWeight: "900" }}>Post Code</Grid>
               {/* <Grid item xs={8}>{details.address.postCode}</Grid> */}
            </Grid>
         <div className='admin-btn' style={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
            {
               status==="New"&&
               <>
                  <Button onClick={handleAccept} color={"success"} variant="contained" sx={{ alignSelf: "center" }}>Accept</Button>
                  <Button onClick={handleReject} color={"warning"} variant="contained" sx={{ alignSelf: "center" }}>Reject</Button>
               </>
            }
         </div>
        </div>
      </div>

    </>
  );
};
export default TopupRequests;
