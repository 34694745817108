import { NavLink, Link } from "react-router-dom";
import "./index.css";
import { useTranslation } from "react-i18next";

const UserAccountMenu = () => {
  const { t } = useTranslation();
  return (
    <>
      <ul className="AccountMenu">
        <NavLink to="/user/requests" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("request")}</div>
        </NavLink>
        <NavLink
          to="/User/ServiceRequest?requestType=UserRequest"
          className="text-decoration-none"
        >
          <div className="rounded-0 border py-2">{t("raise-request")}</div>
        </NavLink>
        <NavLink to="/user/userprofile" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("profile")}</div>
        </NavLink>
      </ul>
    </>
  );
};

export default UserAccountMenu;
