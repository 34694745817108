import "./index.css";
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react"; // Import useEffect hook
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { ContactSupportOutlined } from "@material-ui/icons";

const Subcatgories = {
  Moving: [
    "Private Move",
    "Business Move",
    "International Move",
    "Clearance and disposal",
  ],
  Cleaning: ["Apartment", "House", "Office", "Business"],
  Painting: ["Inside", "Outside"],
  Carpenter: [
    "Interior design",
    "Kitchen Builder",
    "Furniture Design",
    "Bathroom Builder",
    "Floor Layers",
    "Windows & Doors",
  ],
};

const Category = (props) => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const WindowrequestType = params.get("requestType");
  const [currentid, setCurrentid] = useState(props.resultCleaning.requestType);
  const [formErrors, setformErrors] = useState("");

  const fnClickNext = () => {
    if (currentid) {
      const newState = Object.assign({}, props.resultCleaning, {
        requestType: currentid,
      });
      props.setResultCleaning(newState);
      props.setNextalarm(-1);
      props.setCurrentstep(props.currentstep + 1);
    } else {
      setformErrors(t("Select-Category"));
    }
  };

  const resetSubCategory = () => {
    props.resultCleaning.subCategory = "";
    props.resultCleaning.MoveOutCategory = "";
    props.resultCleaning.floorCovering = "";
    props.resultCleaning.windowsWith = "";
    props.resultCleaning.heating = "";
    props.resultCleaning.whatToPaint = [];
  };

  useEffect(() => {
    // Call fnClickNext whenever currentid matches one of the categories
    if (currentid && Subcatgories[WindowrequestType].includes(currentid)) {
      if (currentid !== props.resultCleaning.requestType) { // Check if currentid is not the same as the initial requestType
        fnClickNext();
        // setCurrentid(""); Reset currentid after processing
      }
    }
  }, [currentid, props.currentstep, props.resultCleaning.requestType, Subcatgories, WindowrequestType]);



  return (
    <>
      <MediaQuery minWidth={1026}>
        <div className="category-component">
          <div className="category-container">
            <label> {t("Select-Request-Type")}</label>
            <span className="card-box">
              {Subcatgories[WindowrequestType].map((SubCatName, index) => (
                <RequestCard
                  key={index} // Add a unique key to each RequestCard
                  SubCategory={SubCatName}
                  currentid={currentid}
                  setCurrentid={setCurrentid}
                  setformErrors={setformErrors}
                  resetSubCategory={resetSubCategory}
                />
              ))}
            </span>
            <span className="card-box">
              <Button variant="warning" disabled={true}>
                {" "}
                {t("prev")}
              </Button>
              <Button variant="warning" onClick={fnClickNext} >
                {t("next")}
              </Button>
            </span>
            {formErrors && <span className="error">{formErrors}</span>}
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
        <div className="category-component">
          <div className="category-container">
            <label> {t("Select-Request-Type")}</label>
            <span className="card-box-mobile">
              {Subcatgories[WindowrequestType].map((SubCatName, index) => (
                <RequestCard
                  key={index} // Add a unique key to each RequestCard
                  SubCategory={SubCatName}
                  currentid={currentid}
                  setCurrentid={setCurrentid}
                  setformErrors={setformErrors}
                  resetSubCategory={resetSubCategory}
                />
              ))}
            </span>
            <span className="card-box">
              <Button variant="warning" disabled={true}>
                {" "}
                {t("prev")}
              </Button>
              <Button variant="warning" onClick={fnClickNext}>
                {t("next")}
              </Button>
            </span>
            {formErrors && <span className="error">{formErrors}</span>}
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

const RequestCard = ({
  SubCategory,
  currentid,
  setCurrentid,
  setformErrors,
  resetSubCategory,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setCurrentid(SubCategory);
    setformErrors("");
    resetSubCategory();
  };

  return (
    <>
      <MediaQuery minWidth={1026}>
        <div
          className={`category-icon-container ${
            currentid === SubCategory ? "btn-primary" : ""
          }`}
          onClick={handleClick}
        >
          {t(SubCategory)}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <div
          className={`category-icon-container-mobile ${
            currentid === SubCategory ? "btn-primary" : ""
          }`}
          onClick={handleClick}
        >
          {t(SubCategory)}
        </div>
      </MediaQuery>
    </>
  );
};

export default Category;
