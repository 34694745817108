import React, { useState, useEffect } from "react"
import Axios from "axios";
import Category from "./Category"
import Order from "./Order"
import Address from "./Address"
import Detail from "./Detail"
import Pictures from "./Pictures"
import Preference from "./Preference"
import Contact from "./Contact"
import PhoneVerify from "./PhoneVerify"
import AccountHeader from "../../Account/AccountPage/AccountHeader"
import CustomProgressBar from "./CustomProgressBar" 
import loginImage from '../../../images/home/cleaning.png';
import "./index.css";

const ServiceRequest = () => {
  const params = new URLSearchParams(window.location.search);
  const requestType=params.get('requestType');
  const [currentstep, setCurrentstep] = useState(0);
  const [nextalarm, setNextalarm] = useState(-1);
  const [resultCleaning, setResultCleaning] = useState({
    requestType: "",
    category: "",
    categoryName:"",
    categorydate: new Date(),
    moveIndate: new Date(),
    categorytimeflex: "1 day",
    MovingOutTimeFlex:"1 week",
    subCategory:"",
    MoveOutCategory:"",
    moveInAddress: "",
    postcode1: "",
    latitude1: null,
    longitude1: null,
    address2: "",
    addressnum: "",
    postcode: "",
    latitude: null,
    longitude: null,
    moveOutAddress: "",
    mimportant: "price",
    TermsCondition:"Yes",
    floorarea: "100",
    roomnum: "5",
    floors:"1",
    MoveInfloor:"1",
    Distance:"10",
    MovingDistance:"10",
    noOfPeople:"1",
    Elevator:"No",
    MoveInElevator:"No",
    degreeOfSoiling: "Heavy soiled",
    whatclean: [],
    additionalarea: [],
    orderdescription: "",
    morefile: "",
    offerType: "Rough",
    firstname: "",
    lastname: "",
    email: "",
    password: "", 
    confirmpasswor: "",
    phonenumber: "",
    cost: 0.0,
    availabletime: "1pm-5pm",
    floorCovering:"",
    windowsWith:"",
    heating:"",
    CleanBalcony:"No",
    BalconyArea:"",
    whatToPaint:[],
    images: [],
    PaintedLastTime:"",
    SmokingHousehold:"No",
    isolatingcoating:"Yes",
  });

  const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";

  useEffect(() => {
    if (requestType) {
      Axios.get(REACT_APP_BACKEND_SERVER2 + '/category')
        .then((res) => {
          if (res.data.status === "Success") {
            let categoryId = "";
            const params = new URLSearchParams(window.location.search);
            if (params.get("requestType") === "UserRequest") {
              categoryId = "";
            } else {
              const category = res.data.categories.find(cat => cat.type === requestType);
              categoryId = category ? category.id : "";
            }
            const newState = { ...resultCleaning, requestType, category: categoryId };
            setResultCleaning(newState);
          } else {
            console.error('Something went wrong. Refresh and continue.');
          }
        })
        .catch(error => {
          console.error('Error fetching category data:', error);
          console.error("Something went wrong. Refresh and continue.");
        });
    }
  }, [requestType]);

 
  const steps = [
    {
      component: (
        <Category
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },
    {
      component: (
        <Order 
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },

    
    
    {
      component: (
        <Detail
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },

    {
      component: (
        <Pictures
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },

    {
      component: (
        <Address
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },
    {
      component: (
        <Contact
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },
    {
      component: (
        <PhoneVerify
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },
    {
      component: (
        <Preference
          nextalarm={nextalarm}
          setNextalarm={setNextalarm}
          currentstep={currentstep}
          setCurrentstep={setCurrentstep}
          resultCleaning={resultCleaning}
          setResultCleaning={setResultCleaning}
        />
      ),
    },
  ];

  return (
    <div>
      <AccountHeader menuBtnVisibility={"hidden"} />
      <div className="hero login-page" style={{ backgroundImage: `url(${loginImage})` }}>
        <CustomProgressBar currentstate={currentstep * 15} />
        <CurrentComponent steps={steps} currentstep={currentstep} />
      </div>
    </div>
  );
};


const CurrentComponent = (props) => {
  return ((props.steps[props.currentstep].component));
}

export default ServiceRequest;
