import React from "react";
import "./EasyCompareLogo.css";
import EasyCompareLogoImg from "../../images/home/EasyCompareLogo.png"
import easycomparelogo from "../../images/easycomparelogo.jpeg"
import easysvglogo from '../../images/easy-compare-logo.svg'
import MediaQuery from "react-responsive";

const EasyCompareLogo=()=>{
   return<>

      {/* <div className="rubik">
         <span className="e">
            e
         </span>
         <span className="c">
            c
         </span>
      </div> */}

      <MediaQuery minWidth={1026}>
         <span className="logo-easy">easy-<span className="logo-compare">c</span><span className="">ompare</span>
         </span>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
         <span className="logo-easy-mobile">easy-<span className="logo-compare-mobile">c</span><span className="">ompare</span>
         </span>
      </MediaQuery>
      {/* <img className="logo-easy" src={EasyCompareLogoImg} alt="EasyCompareLogo" /> */}
   </>
} 

export default EasyCompareLogo;