import React from 'react';
import Hero from '../common/Hero';
import ServiceModel from '../common/ServiceModel';
import ProcessSteps from "../ProcessSteps";
import ThreeStepCardComponent from "../ThreeStepCardComponent";
import FormIllustration from '../../images/FormIllustration.svg';
import OfferIllustration from '../../images/OfferIllustration.svg';
import OrderIllustration from '../../images/CleaningPage/CleaningIconForOrder.svg';
import Vector3 from '../../images/Vector3.svg';
import ServicesOffered from '../common/ServicesOffered';
import OfferModel from '../common/OfferModel';
import Flex2fragments from '../layouts/Flex2fragments';
import cleaningHomeImage from '../../images/home/cleaningHomeImage.png'
import ApartmentIcon from '../../images/CleaningPage/ApartmentIcon.png';
import BuildingCleaning from '../../images/CleaningPage/BuildingCleaning.png';
import FinalCleaning from '../../images/CleaningPage/FinalCleaning.png';
import OfficeCleaning from '../../images/CleaningPage/OfficeCleaning.png';
import Removalcleaning from '../../images/CleaningPage/Removalcleaning.png';
import WindowCleaning from '../../images/CleaningPage/WindowCleaning.png';
import HowWeWorkCleaning from '../../images/CleaningPage/HowWeWorkCleaning.png';
import Clean from '../../images/CleaningPage/Icon.svg';
import Testimonials from '../Testimonials';
import ServiceInfo from './ServiceInfo';
import CleaningAccordian from './CleaningAccordian';
import Pricing from './Pricing';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next'
import AdvantagesComponent from '../AdvantagesComponent';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import PDFViewer from './PDFViewer';

const Cleaning = () => {
   const navigate = useNavigate();
   const [showScrollButton, setShowScrollButton] = useState(false);
   const { t } = useTranslation();
   let cleanAccordObj = {};
   cleanAccordObj[t("CleaningHeading1")] = [t("CleaningDesc1"), t("CleaningDesc2"), t("CleaningDesc3"), t("CleaningDesc4"), t("CleaningDesc5"), t("CleaningDesc6"), t("CleaningDesc7"), t("CleaningDesc8"), t("CleaningDesc9"), t("CleaningDesc10")];
   cleanAccordObj[t("CleaningHeading2")] = [t("CleaningH2Desc1"), t("CleaningH2Desc2"), t("CleaningH2Desc3"), t("CleaningH2Desc4"), t("CleaningH2Desc5"), t("CleaningH2Desc6"), t("CleaningH2Desc7"), t("CleaningH2Desc8"), t("CleaningH2Desc9")];
   cleanAccordObj[t("CleaningHeading3")] = [t("CleaningH3Desc1"), t("CleaningH3Desc2"), t("CleaningH3Desc3"), t("CleaningH3Desc4"), t("CleaningH3Desc5"), t("CleaningH3Desc6"), t("CleaningH3Desc8")];
   useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const handleScrollToTop = () => {
      navigate("/User/ServiceRequest?requestType=Cleaning");
    };
   return <>
   {showScrollButton && (
        <div className="scroll-button" onClick={handleScrollToTop}>
          <h3>Start Request</h3>
        </div>
      )}
      <Hero image={cleaningHomeImage}>
         <ServiceModel Serviceaction={t("Cleaning-Navbar-Label")} ServiceCardIcon={Clean} ServiceDescription={t("Cleaning-Navbar-Desc")} selopt={"Cleaning"} />
      </Hero>
      <MediaQuery minWidth={1026}>
         <ProcessSteps label={t("Cleaning-Navbar-TagLine")}>
            <ThreeStepCardComponent svg={FormIllustration} title={t('Process-step-1')} content={t("Process-step1-desc")} />
            <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
            <ThreeStepCardComponent svg={OfferIllustration} title={t('Process-step-2')} content={t("Process-step2-desc")} />
            <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
            <ThreeStepCardComponent svg={OrderIllustration} title={t("Process-step-3")} content={t("Process-step3-desc")} />
         </ProcessSteps>
         <AdvantagesComponent />
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
         <ProcessSteps label={t("Cleaning-Navbar-TagLine")}>
            <ThreeStepCardComponent svg={FormIllustration} title={t('Process-step-1')} content={t("Process-step1-desc")} />
            {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
            <ThreeStepCardComponent svg={OfferIllustration} title={t('Process-step-2')} content={t("Process-step2-desc")} />
            {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
            <ThreeStepCardComponent svg={OrderIllustration} title={t("Process-step-3")} content={t("Process-step3-desc")} />
         </ProcessSteps>
         <AdvantagesComponent />
      </MediaQuery>

      <ServicesOffered subHeading={t("Cleaning-Service")} heading={t("CleaningService-Heading")} headEnd={t("offers-word")}>
         <OfferModel OfferIcon={ApartmentIcon} OfferName={t("Cleaningoffers1")} description={t("CleaningoffersDesc1")} />
         <OfferModel OfferIcon={FinalCleaning} OfferName={t("Cleaningoffers2")} description={t("CleaningoffersDesc2")} />
         <OfferModel OfferIcon={Removalcleaning} OfferName={t("Cleaningoffers3")} description={t("CleaningoffersDesc3")} />
         <OfferModel OfferIcon={OfficeCleaning} OfferName={t("Cleaningoffers4")} description={t("CleaningoffersDesc4")} />
         <OfferModel OfferIcon={WindowCleaning} OfferName={t("Cleaningoffers5")} description={t("CleaningoffersDesc5")} />
         <OfferModel OfferIcon={BuildingCleaning} OfferName={t("Cleaningoffers6")} description={t("CleaningoffersDesc6")} />
      </ServicesOffered>
      <Flex2fragments img={HowWeWorkCleaning} classes={"mt-10"} flexReverse={0}>
         <ServiceInfo />
      </Flex2fragments>
      <CleaningAccordian accordionHeading={cleanAccordObj} />
      {/* <Pricing /> */}

      <PDFViewer />
      {/* <Testimonials/> */}
   </>
}

export default Cleaning;