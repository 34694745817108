import React, { useState } from "react";
import {Button } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { MenuProps,getStyles } from "../../../utils/utils";

import { currentLang } from "../../common/Navbar";


const Detail = (props) => {
  const params = new URLSearchParams(window.location.search);
  const requestType=params.get('requestType');
  const { t } = useTranslation();
  const theme = useTheme();
  const [orderdescription, setOrderdescription] = useState(
    props.resultCleaning.orderdescription
  );
  const [selectedOption1, setSelectedOption1] = useState(
    props.resultCleaning.whatclean
  );
  const [selectedOption2, setSelectedOption2] = useState(
    props.resultCleaning.additionalarea
  );

  const [formErrors, setformErrors] = useState("");

  const fnOrderdesciption = (e) => {
    setOrderdescription(e.target.value);
    setformErrors("");
  };
  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      whatclean: selectedOption1,
      additionalarea: selectedOption2,
      orderdescription: orderdescription,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (!orderdescription) {
      setformErrors(t("order-desc"));
    } else {
      const newState = Object.assign({}, props.resultCleaning, {
        whatclean: selectedOption1,
        additionalarea: selectedOption2,
        orderdescription: orderdescription,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    }
  };
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  

  const options1en = {
    'Moving':{
      'Private Move': ["No stopping zone","packing pictures/art","packing household items","dismantling furniture","dismantling lamps","dismantling the washing machine","disposal","storing furniture"
      ],
      'Business Move':["No stopping zone","packing pictures/art","packing inventory","dismantling furniture","dismantling lamps","dismantling the washing machine","disposal","storing furniture"],
      'International Move':["No stopping zone","packing pictures/art","packing inventory","dismantling furniture","dismantling lamps","dismantling the washing machine","disposal","storing furniture"],
      'Clearance and disposal':["No stopping zone","clearing rubbish","clearing the basement","clearing the screed","clearing the garage","clearing the household","dismantling furniture","dismantling lamps","dismantling the washing machine"],
  },
    'Cleaning':{
      'Apartment':["cleaning the basement","cleaning the screed","cleaning the garage","cleaning the washing machine/tumble dryer"],
      'House':["cleaning the basement","cleaning the screed","cleaning the garage","cleaning the washing machine/tumble dryer","clean the boiler room","clean the boiler"],
      'Office':["cleaning the basement","cleaning the screed","cleaning the garage","cleaning the washing machine/tumble dryer"],
      'Business':["cleaning the basement","cleaning the screed","cleaning the garage","cleaning the washing machine/tumble dryer"],
    },
    'Painting':{
      'Inside':["covering work","repair work","stucco/plaster work"],
      'Outside':["scaffolding","masking work","repair work","stucco/gypsum work"],
      'Business':["scaffolding","masking work","repair work","stucco/gypsum work"],
    },
    'Carpenter':{
      'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
      'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
      'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
      'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
      'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
      'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
    },
  };
  const options1de = {
    'Moving':{
      'Private Move': ["Halteverbot","Bilder/Kunst verpacken","Hausrat verpacken","Möbel abbauen","Lampen abbauen","Waschmaschine abbauen","Entsorgung","Möbel einlagern"
      ],
      'Business Move':["Halteverbot","Bilder/Kunst verpacken","Inventar verpacken","Möbel abbauen","Lampen abbauen","Waschmaschine abbauen","Entsorgung","Möbel einlagern"],
      'International Move':["Halteverbot","Bilder/Kunst verpacken","Inventar verpacken","Möbel abbauen","Lampen abbauen","Waschmaschine abbauen","Entsorgung","Möbel einlagern"],
      'Clearance and disposal':["Halteverbot","Müllentsorgung","Keller räumen","Estrich räumen","Garage räumen","Haushalt räumen","Möbel abbauen","Lampen abbauen","Waschmaschine abbauen"],
  },
    'Cleaning':{
      'Apartment':["Keller reinigen","Estrich reinigen","Garage reinigen","Waschmaschine/Trockner reinigen"],
      'House':["Keller reinigen","Estrich reinigen","Garage reinigen","Waschmaschine/Trockner reinigen","Heizraum reinigen","Kessel reinigen"],
      'Office':["Keller reinigen","Estrich reinigen","Garage reinigen","Waschmaschine/Trockner reinigen"],
      'Business':["Keller reinigen","Estrich reinigen","Garage reinigen","Waschmaschine/Trockner reinigen"],
    },
    'Painting':{
      'Inside':["Abdeckarbeiten","Reparaturarbeiten","Stuck/Verputzarbeiten"],
      'Outside':["Gerüstbau","Abdeckarbeiten","Reparaturarbeiten","Stuck/Gipsarbeiten"],
      'Business':["Gerüstbau","Abdeckarbeiten","Reparaturarbeiten","Stuck/Gipsarbeiten"],
    },
    'Carpenter':{
      'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
      'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
      'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
      'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
      'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
      'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
    },
};

const options1fr = {
  'Moving': {
      'Private Move': ["Zone sans stationnement", "Emballage des tableaux/art", "Emballage des biens domestiques", "Démontage des meubles", "Démontage des lampes", "Démontage de la machine à laver", "Élimination", "Stockage des meubles"],
      "Business Move": ["Zone sans stationnement", "Emballage des tableaux/art", "Emballage de l'inventaire", "Démontage des meubles", "Démontage des lampes", "Démontage de la machine à laver", "Élimination", "Stockage des meubles"],
      'International Move': ["Zone sans stationnement", "Emballage des tableaux/art", "Emballage de l'inventaire", "Démontage des meubles", "Démontage des lampes", "Démontage de la machine à laver", "Élimination", "Stockage des meubles"],
      'Clearance and disposal': ["Zone sans stationnement", "Débarras des déchets", "Vider la cave", "Vider la chape", "Vider le garage", "Vider le logement", "Démontage des meubles", "Démontage des lampes", "Démontage de la machine à laver"],
  },
  'Cleaning': {
      'Apartment': ["Nettoyage de la cave", "Nettoyage de la chape", "Nettoyage du garage", "Nettoyage de la machine à laver/sèche-linge"],
      'House': ["Nettoyage de la cave", "Nettoyage de la chape", "Nettoyage du garage", "Nettoyage de la machine à laver/sèche-linge", "Nettoyer la chaufferie", "Nettoyer la chaudière"],
      'Office': ["Nettoyage de la cave", "Nettoyage de la chape", "Nettoyage du garage", "Nettoyage de la machine à laver/sèche-linge"],
      'Business': ["Nettoyage de la cave", "Nettoyage de la chape", "Nettoyage du garage", "Nettoyage de la machine à laver/sèche-linge"],
  },
  'Painting': {
      'Inside': ["Travaux de protection", "Travaux de réparation", "Travaux de stuc/plâtre"],
      'Outside': ["Échafaudage", "Travaux de masquage", "Travaux de réparation", "Travaux de stuc/plâtre"],
      'Business': ["Échafaudage", "Travaux de masquage", "Travaux de réparation", "Travaux de stuc/plâtre"],
  },
  'Carpenter':{
    'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
    'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
  },
};
const options1it = {
  'Moving': {
      'Private Move': ["Zona di divieto di sosta", "Imballaggio di quadri/arte", "Imballaggio di oggetti per la casa", "Smontaggio di mobili", "Smontaggio di lampade", "Smontaggio della lavatrice", "Smaltimento", "Stoccaggio di mobili"],
      'Business Move': ["Zona di divieto di sosta", "Imballaggio di quadri/arte", "Imballaggio dell'inventario", "Smontaggio di mobili", "Smontaggio di lampade", "Smontaggio della lavatrice", "Smaltimento", "Stoccaggio di mobili"],
      'International Move': ["Zona di divieto di sosta", "Imballaggio di quadri/arte", "Imballaggio dell'inventario", "Smontaggio di mobili", "Smontaggio di lampade", "Smontaggio della lavatrice", "Smaltimento", "Stoccaggio di mobili"],
      'Clearance and disposal': ["Zona di divieto di sosta", "Sgombero dei rifiuti", "Svuotare la cantina", "Svuotare la soletta", "Svuotare il garage", "Svuotare la casa", "Smontaggio di mobili", "Smontaggio di lampade", "Smontaggio della lavatrice"],
  },
  'Cleaning': {
      'Apartment': ["Pulizia della cantina", "Pulizia della soletta", "Pulizia del garage", "Pulizia della lavatrice/asciugatrice"],
      'House': ["Pulizia della cantina", "Pulizia della soletta", "Pulizia del garage", "Pulizia della lavatrice/asciugatrice", "Pulizia del locale caldaia", "Pulizia della caldaia"],
      'Office': ["Pulizia della cantina", "Pulizia della soletta", "Pulizia del garage", "Pulizia della lavatrice/asciugatrice"],
      'Business': ["Pulizia della cantina", "Pulizia della soletta", "Pulizia del garage", "Pulizia della lavatrice/asciugatrice"],
  },
  'Painting': {
      'Inside': ["Lavori di copertura", "Lavori di riparazione", "Lavori di stucco/intonaco"],
      'Outside': ["Impalcatura", "Lavori di mascheratura", "Lavori di riparazione", "Lavori di stucco/gesso"],
      'Business': ["Impalcatura", "Lavori di mascheratura", "Lavori di riparazione", "Lavori di stucco/gesso"],
  },
  'Carpenter':{
    'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
    'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
  },
};

const options1 = {
  en: options1en,
  de: options1de,
  fr: options1fr,
  it: options1it,
};
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  

  const options2en = {
    'Moving':{
      'Private Move': ["No stopping zone","hanging up pictures/art","unpacking the household","assembling furniture","assembling lamps","assembling the washing machine"],
      'Business Move':["No stopping zone","unpack inventory","hang up pictures/art","assemble furniture","assemble lamps","assemble washing machine"],
      'International Move':["No stopping zone","unpack inventory","hang up pictures/art","Assemble furniture","assemble lamps","assemble washing machine"],
      'Clearance and disposal':["No stopping zone","clearing rubbish","clearing the basement","clearing the screed","clearing the garage","clearing the household","dismantling furniture","dismantling lamps","dismantling the washing machine"],
  },
  'Cleaning':{
    'Apartment':["removal cleaning","standing order cleaning","basic cleaning","window cleaning","terrace cleaning"],
    'House':["removal cleaning","standing order cleaning","basic cleaning","window cleaning","terrace cleaning","facade cleaning","construction cleaning"],
    'Office':["removal cleaning","standing order cleaning","basic cleaning","window cleaning","terrace cleaning"],
    'Business':["removal cleaning","standing order cleaning","basic cleaning","window cleaning","terrace cleaning"],
  },
  'Painting':{
    'Inside':["Renew walls","ceilings","doors","door frames","window frames","radiators","staircase","built-in closet","wallpaper"],
    'Outside':["facade painting","facade cleaning","wood painting","painting doors","painting door frames","painting shutters"],
    'Business':["Renew walls","ceilings","doors","door frames","window frames","radiators","staircase","built-in closet","wallpaper"],
  },
  'Carpenter':{
    'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
    'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Furniture Design':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Bathroom Builder':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Windows & Doors':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
  },
};

const options2de = {
  'Moving':{
    'Private Move': ["Halteverbotszone","Bilder/Kunst aufhängen","Haushalt auspacken","Möbel montieren","Lampen montieren","Waschmaschine montieren"],
    'Business Move':["Halteverbotszone","Inventar auspacken","Bilder/Kunst aufhängen","Möbel montieren","Lampen montieren","Waschmaschine montieren"],
    'International Move':["Halteverbotszone","Inventar auspacken","Bilder/Kunst aufhängen","Möbel montieren","Lampen montieren","Waschmaschine montieren"],
    'Clearance and disposal':["Halteverbotszone","Entrümpelung","Keller entrümpeln","Estrich entrümpeln","Garage entrümpeln","Haushalt entrümpeln","Möbel demontieren","Lampen demontieren","Waschmaschine demontieren"],
  },
  'Cleaning':{
    'Apartment':["Umzugsreinigung","Unterhaltsreinigung","Grundreinigung","Fensterreinigung","Terrassenreinigung"],
    'House':["Umzugsreinigung","Unterhaltsreinigung","Grundreinigung","Fensterreinigung","Terrassenreinigung","Fassadenreinigung","Bauendreinigung"],
    'Office':["Umzugsreinigung","Unterhaltsreinigung","Grundreinigung","Fensterreinigung","Terrassenreinigung"],
    'Business':["Umzugsreinigung","Unterhaltsreinigung","Grundreinigung","Fensterreinigung","Terrassenreinigung"],
  },
  'Painting':{
    'Inside':["Wände erneuern","Decken erneuern","Türen erneuern","Türzargen erneuern","Fensterrahmen erneuern","Heizkörper erneuern","Treppenhaus erneuern","Einbauschrank erneuern","Tapeten erneuern"],
    'Outside':["Fassadenanstrich","Fassadenreinigung","Holz streichen","Türen streichen","Türzargen streichen","Läden streichen"],
    'Business':["Wände erneuern","Decken erneuern","Türen erneuern","Türzargen erneuern","Fensterrahmen erneuern","Heizkörper erneuern","Treppenhaus erneuern","Einbauschrank erneuern","Tapeten erneuern"],
  },
  'Carpenter':{
    'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
    'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
  },
};

const options2fr = {
  'Moving': {
      'Private Move': ["Zone sans stationnement", "Accrochage de tableaux/art", "Déballage du ménage", "Montage de meubles", "Montage de lampes", "Montage de la machine à laver"],
      "Business Move": ["Zone sans stationnement", "Déballage de l'inventaire", "Accrochage de tableaux/art", "Montage de meubles", "Montage de lampes", "Montage de la machine à laver"],
      'International Move': ["Zone sans stationnement", "Déballage de l'inventaire", "Accrochage de tableaux/art", "Montage de meubles", "Montage de lampes", "Montage de la machine à laver"],
      'Clearance and disposal': ["Zone sans stationnement", "Débarras des déchets", "Débarras de la cave", "Débarras de la dalle", "Débarras du garage", "Débarras du ménage", "Démontage de meubles", "Démontage de lampes", "Démontage de la machine à laver"],
  },
  'Cleaning': {
      'Apartment': ["Nettoyage de déménagement", "Nettoyage régulier", "Nettoyage de base", "Nettoyage des vitres", "Nettoyage de la terrasse"],
      'House': ["Nettoyage de déménagement", "Nettoyage régulier", "Nettoyage de base", "Nettoyage des vitres", "Nettoyage de la terrasse", "Nettoyage de façade", "Nettoyage de chantier"],
      'Office': ["Nettoyage de déménagement", "Nettoyage régulier", "Nettoyage de base", "Nettoyage des vitres", "Nettoyage de la terrasse"],
      'Business': ["Nettoyage de déménagement", "Nettoyage régulier", "Nettoyage de base", "Nettoyage des vitres", "Nettoyage de la terrasse"],
  },
  'Painting': {
      'Inside': ["Rénovation des murs", "Plafonds", "Portes", "Encadrements de porte", "Cadres de fenêtres", "Radiateurs", "Escaliers", "Placards encastrés", "Papier peint"],
      'Outside': ["Peinture de façade", "Nettoyage de façade", "Peinture de bois", "Peinture de portes", "Peinture d'encadrements de porte", "Volets"],
      'Business': ["Rénovation des murs", "Plafonds", "Portes", "Encadrements de porte", "Cadres de fenêtres", "Radiateurs", "Escaliers", "Placards encastrés", "Papier peint"],
  },
  'Carpenter':{
    'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
    'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
  },
};


const options2it = {
  'Moving': {
      'Private Move': ["Zona di divieto di sosta", "Appendere quadri/arte", "Sballare il trasloco", "Montaggio mobili", "Montaggio lampade", "Montaggio lavatrice"],
      'Business Move': ["Zona di divieto di sosta", "Sballare inventario", "Appendere quadri/arte", "Montaggio mobili", "Montaggio lampade", "Montaggio lavatrice"],
      'International Move': ["Zona di divieto di sosta", "Sballare inventario", "Appendere quadri/arte", "Montaggio mobili", "Montaggio lampade", "Montaggio lavatrice"],
      'Clearance and disposal': ["Zona di divieto di sosta", "Sgombero rifiuti", "Sgombero cantina", "Sgombero soletta", "Sgombero garage", "Sgombero casa", "Smontaggio mobili", "Smontaggio lampade", "Smontaggio lavatrice"],
  },
  'Cleaning': {
      'Apartment': ["Pulizia al trasloco", "Pulizia regolare", "Pulizia di base", "Pulizia delle finestre", "Pulizia del terrazzo"],
      'House': ["Pulizia al trasloco", "Pulizia regolare", "Pulizia di base", "Pulizia delle finestre", "Pulizia del terrazzo", "Pulizia della facciata", "Pulizia dei cantieri"],
      'Office': ["Pulizia al trasloco", "Pulizia regolare", "Pulizia di base", "Pulizia delle finestre", "Pulizia del terrazzo"],
      'Business': ["Pulizia al trasloco", "Pulizia regolare", "Pulizia di base", "Pulizia delle finestre", "Pulizia del terrazzo"],
  },
  'Painting': {
      'Inside': ["Rinnovare le pareti", "Soffitti", "Porte", "Cornici delle porte", "Telaio delle finestre", "Radiatori", "Scale", "Armadi a incasso", "Carta da parati"],
      'Outside': ["Pittura della facciata", "Pulizia della facciata", "Pittura del legno", "Pittura delle porte", "Pittura delle cornici delle porte", "Tapparelle"],
      'Business': ["Rinnovare le pareti", "Soffitti", "Porte", "Cornici delle porte", "Telaio delle finestre", "Radiatori", "Scale", "Armadi a incasso", "Carta da parati"],
  },
  'Carpenter':{
    'Interior design':["closet","bookshelf","sideboard","wardrobe","table","chairs"," partition wall","office furniture","bed","bunk bed","children's playroom","other"],
    'Kitchen Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Bathroom Builder':["bathroom furniture","vanity","shower cubicle","linen closet","built-in closet","fitted kitchen","custom-made kitchen","new worktop","new built-in appliances","new hinges","other"],
    'Doors and Winows':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Floor Layers':["Interior","exterior","bathroom","kitchen","stairs","roof","furniture restoration","other"],
    'Furniture Design':["wooden windows","aluminum windows","PVC windows","round windows","semi-round windows","arched windows","segmental arched windows","pitched roof windows","trapezoidal windows","triangular windows","oval windows","front door","standard door","fire protection door","soundproof door","burglar-resistant door","security door","hinged/revolving door","sliding door","folding door","swing door","other"],
  },
};

const options2 = {
  en: options2en,
  de: options2de,
  fr: options2fr,
  it: options2it,
};
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOption1(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOption2(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="category-component">
      <div className="category-container">
        <span className="card-box" style={{ flexDirection: "column" }}>
        {/* <div>
        <TextField
          id="standard-multiline-flexible"
          label="Please Describe Your Project Without Entering Personal Details"
          multiline
          maxRows={10}
          variant="standard"
          fullWidth
        />
      </div> */}

          <TextField
            id="outlined-multiline-static"
            onChange={fnOrderdesciption}
            label={t("order-description")}
            multiline
            placeholder={t("order-placeholder")}
            value={orderdescription}
            rows={5}
          />
          {formErrors && !orderdescription && <span className="error">{formErrors}</span>}
        </span>
        <span className="prevNext-btn card-box">
          <Button variant="warning" onClick={fnClickPrev}>
            {t("prev")}
          </Button>
          <Button variant="warning" onClick={fnClickNext}>
            {t("next")}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Detail;
