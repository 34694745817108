import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { flexbox } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
export default function OfferDialogBox(props) {

   const handleClose = () => {
      props.setOpen(false);
   };

   return (
      <div>

         <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
         >
            <BootstrapDialogTitle id="customized-dialog-title" sx={{ background: "#4A3AFF", color: "white" }} onClose={handleClose}>Buy contact details</BootstrapDialogTitle>
            <DialogContent dividers>
               <Typography variant='h5' sx={{ bg: "var(--primary-blue)", mb: "1rem" }}>
                  Get the right away Contact details, if you participate in the marketing effort.
               </Typography>
               <Typography sx={{ d: flexbox }}><CheckIcon color='primary' fontSize="small" /> Max. 3 buyers per request</Typography>
               <Typography sx={{ d: flexbox }}><CheckIcon color='primary' fontSize="small" /> No final commission</Typography>
               <Typography sx={{ d: flexbox }}><CheckIcon color='primary' fontSize="small" /> Contact details verified as far as possible</Typography>
               <Typography sx={{ d: flexbox }}><CheckIcon color='primary' fontSize="small" /> No refund if awarded / not available</Typography>
               <Typography sx={{ d: flexbox }}><CheckIcon color='primary' fontSize="small" /> No phone, contact requested by verified email</Typography>
            </DialogContent>
            <DialogActions>
               <div style={{ textAlign: "center", display: "flex", gap: "2%", width: "100%" }}>
                  <Button onClick={props.handleAccept} variant="contained" sx={{ alignSelf: "center" }}>tBuy with Credit</Button>
                  <Button onClick={props.handleAcceptWithCard} variant="contained" sx={{ alignSelf: "center" }}>Buy with Credit Card</Button>
                  <br/>
                  Feedback or free telephone support at:
                  + 41 44 552 05 14
               </div>
            </DialogActions>
         </BootstrapDialog>
      </div>
   );
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
   '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
   },
   '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
   },
}));


function BootstrapDialogTitle(props) {
   const { children, onClose, ...other } = props;

   return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
         {children}
         {onClose ? (
            <IconButton
               aria-label="close"
               onClick={onClose}
               sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
               }}
            >
               <CloseIcon />
            </IconButton>
         ) : null}
      </DialogTitle>
   );
}

BootstrapDialogTitle.propTypes = {
   children: PropTypes.node,
   onClose: PropTypes.func.isRequired,
};