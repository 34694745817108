import React from "react";
import { BsCheck } from "react-icons/bs";
import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import './index.css'

const ThankYouCard = () => {
  return (
    <div className="category-component">
      <div className="category-container">
        <div className="card-box">
          {/* <h1 style={{display: "flex", justifyContent:"center", alignItems:"center"}} className="text-center">Thank You for your request!</h1> */}
          <div className="somediv">
            <h3 style={{display: "flex", justifyContent:"center", alignItems:"center", textAlign: "center"}}> Vielen Dank für Ihre Anfrage! <br />
  Wir senden Ihnen eine E-Mail mit Ihren Angaben als Bestätigung.<br />
  Sobald sich ein Partner für Ihren Auftrag interessiert, informieren wir Sie.</h3>
          </div>
        </div>

        <span className="prevNext-btn card-box">
            <button className="btn btn-success"><BsCheck /> 
              Request Received
            </button>

            <Link to='/'>
              <Button variant="warning" >
                Go Home
              </Button>
            </Link>
        </span>
      </div>
    </div>
  );
};

export default ThankYouCard;
