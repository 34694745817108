// RequestListing.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { setRequestAction } from '../../../redux/reducers/setRequest';
import RequestDrop from '../Requests/RequestDrop';

const RequestListing = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setRequestDetails = (details) => {
    dispatch(setRequestAction.setRequest(details));
    navigate('/account/requests/detail?company=' + props.companyDetails.name);
  };

  return (
    <Accordion className="Accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        className="accordion-button"
      >
        <div className="header-content">
          {/* {props.details.category.type} in {props.details.address.location} */}
          <br></br>
          {props.details.date.split('T')[0]}
          <br />
          <div style={{ paddingRight: '1rem' }}>
            CHF 22
            <br />
            {props.details.status === 'Processing' || props.details.status === 'Sent' ? (
              <Button variant="contained" size="small" onClick={() => setRequestDetails(props.details)}>
                <a>For purchase</a>
              </Button>
            ) : props.details.acceptedBy === props.companyDetails.email ? (
              <Button
                variant="contained"
                color={'success'}
                sx={{ backgroundColor: 'green', color: 'white' }}
                size="small"
                onClick={() => setRequestDetails(props.details)}
              >
                <a>Purchased</a>
              </Button>
            ) : (
              <Button variant="outlined" color="error" size="small">
                Sold out
              </Button>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <RequestDrop list={props.details} />
      </AccordionDetails>
    </Accordion>
  );
};

export default RequestListing;
