import React, { useState } from "react";
import '../common/Hero.css';
import './Login.css';
import Axios from "axios";
import loginImage from '../../images/home/home.png';
import { useNavigate } from "react-router-dom";
import Navbar from "../common/Navbar";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch } from "react-redux";
import { companyloginAction } from "../../redux/actions/companyAction";
import { saveJWT } from "../../redux/actions/saveJWT";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { emailRegex } from "../../utils/utils";

export default function Login() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [userType, setUserType] = useState('');
	const [email, setEmail] = useState('');
	const dispatch = useDispatch();

	const handleSubmit = (event) => {
		event.preventDefault();
		const { emailId, password } = document.forms[0];

		const requestData = {
			companyEmail: emailId.value,
			password: password.value,
			type: "company",
		};

		const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";

		Axios.post(REACT_APP_BACKEND_SERVER2 + "/login", requestData).then((response) => {
			console.log(requestData)
			if (response.data.response_code === '200') {
				Cookies.set('jwtToken', response.data.jwt, { path: '/' });
				var decoded = jwt_decode(Cookies.get('jwtToken'));
				dispatch(saveJWT(response.data.jwt));
				if(decoded.role.includes("company")){
					dispatch(companyloginAction(response.data.company));
					navigate("/account/requests");
				}else if(decoded.role.includes("ADMIN")){
					navigate("/admin/companydetails");
				}else if(decoded.role.includes("USER")){
					navigate("/user/requests");
				}else{
					alert("Something went wrong.. Please try again");
				}

			} else {
				console.error(response)
				console.log("Smth else happened at ID 091238");
			}
		}).catch((err) => {
			console.log(requestData)
			console.log("Error happened at ID 012839832");
		});
	};

	const handleResetPassword = () => {
		if (email) {
		  const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";
		  Axios.post(REACT_APP_BACKEND_SERVER2 + "/resetPassword", { email })
			.then((response) => {
			  console.log('Response:', response); // Log the entire response object
	
			  // Assuming that a successful response contains the specific success message
			  if (response.status === 200 && typeof response.data === 'string' && response.data.includes('Reset password instructions sent to')) {
				alert("Password reset email sent.");
			  } else {
				console.log(response);
				alert("Failed to send reset email. Due to the fact that the email could be wrong or user not registered. Please try again.");
			  }
			})
			.catch((err) => {
			  console.log(err);
			  alert("An error occurred while sending reset email.");
			});
		} else {
		  alert("Please enter your email address.");
		}
	  };

	const fnSubSelectChange = (e) => {
		setUserType(e.target.value);
	};

	const inputEvent = (e) => {
		const { name, value } = e.target;
		if (name === "emailId") {
			setEmail(value);
		}
		setformErrors(prev => {
			const stateObj = { ...prev, [name]: "" };
			switch (name) {
				case 'emailId':
					stateObj[name] = emailRegex.test(value) && value.length > 0 ? "" : "Invalid email address.";
					break;
				case 'password':
					stateObj[name] = value.length < 8 && value.length > 0 ? "Mininum 8 characters required." : "";
					break;
				default:
					break;
			}
			return stateObj;
		});
	};

	const [formErrors, setformErrors] = useState({
		userType: "",
		emailId: "",
		password: "",
	});

	return (
		<>
			<Navbar menuVisibility={"hidden"} />
			<div className="hero login-page" style={{ backgroundImage: `url(${loginImage})` }}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Box style={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							{t('Sign-In')}
						</Typography>
						<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, bg: "white" }}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-helper-label">{t('')}</InputLabel>
								<Select
									labelId="userType"
									required
									id="userType"
									name="userType"
									value={userType}
									label="Login As"
									onChange={fnSubSelectChange}
									style={{ display: 'none' }}
								>
									<MenuItem value={"company"}>COMPANY</MenuItem>
								</Select>
								{formErrors.userType && <span className='error'>{formErrors.userType}</span>}
							</FormControl>
							<TextField margin="normal" required fullWidth id="emailId" label={t('Email-Address')} name="emailId" onChange={inputEvent}
								autoComplete="emailId" autoFocus />
							{formErrors.emailId && <span className='error'>{formErrors.emailId}</span>}
							<TextField margin="normal" required fullWidth name="password" label={t('Password')} type="password" id="password" onChange={inputEvent}
								autoComplete="current-password" />
							{formErrors.password && <span className='error'>{formErrors.password}</span>}<br></br>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label={t('Remember-Me')}
							/>
							<button className="mui-button-clone" onClick={handleSubmit}>
								{t('Sign-In')}
							</button>
							<Grid container>
								<Grid item xs>
									<Link href="#" variant="body2" onClick={handleResetPassword}>
										{t('Forgot-password')}
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Container>
			</div>
		</>
	);
}
