import React from "react";
import './Hero.css'
import Navbar from "./Navbar";
import ScrollDown from '../../images/ScrollDown.svg';
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import MediaQuery from "react-responsive";


const Hero = (props) => {

   return <>
   <MediaQuery minWidth={1026}>
      <div className="hero" style={{ backgroundImage: `url(${props.image})` }}>
         <Navbar />
        {props.children}
         <img className="scrollDown" src={ScrollDown} alt="ScrollDown" />
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
      <div className="hero-mobile" style={{ backgroundImage: `url(${props.image})` }}>
         <Navbar />
        {props.children}
         <img className="scrollDown" src={ScrollDown} alt="ScrollDown" />
      </div>
      </MediaQuery>
   </>
}

const ServiceModel = (props) => {
   const { t } = useTranslation();
   return <>

<MediaQuery minWidth={1026}>
      <div className="service-model">
         <div className="service-model-head">
            <img className="clean-svg" src={props.ServiceCardIcon} alt="Clean" />
            <div className="sub-heading-2x"><span>{props.Serviceaction} </span></div>
         </div>
         <div>
            {props.ServiceDescription}
         </div>
         <select>
            <option defaultValue value={props.selopt}>{props.selopt}</option>
         </select>
         <Link to={"/User/ServiceRequest?requestType="+props.selopt} className="mx-2 bg-color-icon-div1 text-color-2 pt-3 nav-link">
            <span className="btn btn-primary"> {t("Start-Request")}&nbsp; <AiOutlineArrowRight /></span>
         </Link>
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
      <div className="service-model-mobile">
         <div className="service-model-head">
            <img className="clean-svg" src={props.ServiceCardIcon} alt="Clean" />
            <div className="sub-heading-2x"><span>{props.Serviceaction} </span></div>
         </div>
         <div>
            {props.ServiceDescription}
         </div>
         <select>
            <option defaultValue value={props.selopt}>{props.selopt}</option>
         </select>
         <Link to={"/User/ServiceRequest?requestType="+props.selopt} className="mx-2 bg-color-icon-div1 text-color-2 pt-3 nav-link">
            <span className="btn btn-primary"> {t("Start-Request")}&nbsp; <AiOutlineArrowRight /></span>
         </Link>
      </div>
      </MediaQuery>
   </>
}

export default Hero;