import React from "react";
import Hero from "../common/Hero";
import arrowup from "../../images/uparrow.svg";
import ServiceModel from "../common/ServiceModel";
import ProcessSteps from "../ProcessSteps";
import ThreeStepCardComponent from "../ThreeStepCardComponent";
import FormIllustration from "../../images/FormIllustration.svg";
import OfferIllustration from "../../images/OfferIllustration.svg";
import OrderIllustration from "../../images/OrderIllustration.svg";
import Vector3 from "../../images/Vector3.svg";
import AdvantagesComponent from "../AdvantagesComponent";
import Testimonials from "../Testimonials";
import PrivacyPolicy from "../PrivacyPolicy";
import HomeImage from "../../images/home/home.png";
import Icon from "../../images/moving/Icon.svg";
import PrivacyPolicyBG from "../../images/PrivacyPoicyBG.png";
import { useTranslation } from "react-i18next";
import Flex2fragments from "../layouts/Flex2fragments";
import ServiceInfo from "./ServiceInfo";
import PackingServiceInfo from "./PackingServiceInfo";
import Packing from "../../images/moving/Packing.png";
import Packing1 from "../../images/moving/packing2.png";
import MovingProcessRelocation from "./MovingProcessRelocation";
import ReadMoreComponent from "../common/ReadMoreComponent";
import ServicesOffered from "../common/ServicesOffered";
import OfferModel from "../common/OfferModel";
import MovingAccordian from "./MovingAccording";
import CostEffectiveMovingIcon from "../../images/moving/ServicesOfferedIcon/CostEffectiveMovingIcon.svg";
import JunkRemovalIcon from "../../images/moving/ServicesOfferedIcon/JunkRemovalIcon.svg";
import MovingServiceIcon from "../../images/moving/ServicesOfferedIcon/MovingServiceIcon.svg";
import NearbyServiceIcon from "../../images/moving/ServicesOfferedIcon/NearbyServiceIcon.svg";
import OfficeMovingIcon from "../../images/moving/ServicesOfferedIcon/OfficeMovingIcon.svg";
import RelocationIcon from "../../images/moving/ServicesOfferedIcon/RelocationIcon.svg";
import { useMediaQuery } from "react-responsive";
import MediaQuery from "react-responsive";
import { useState } from "react";
import { useEffect } from "react";
import './buttonstyle.css'
import PDFViewer from "./PDFViewer";
import { useNavigate } from "react-router-dom";

const Moving = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    navigate("/User/ServiceRequest?requestType=Moving");
  };

  let moveAccordObj = {};
  moveAccordObj[t("MovingAccorHeading1")] = [
    t("MovingAccorH1Desc1"),
    t("MovingAccorH1Desc2"),
    t("MovingAccorH1Desc3"),
    t("MovingAccorH1Desc4"),
    t("MovingAccorH1Desc5"),
    t("MovingAccorH1Desc6"),
    t("MovingAccorH1Desc7"),
  ];
  moveAccordObj[t("MovingAccorHeading2")] = [
    t("MovingAccorH2Desc1"),
    t("MovingAccorH2Desc2"),
    t("MovingAccorH2Desc3"),
    t("MovingAccorH2Desc4"),
    t("MovingAccorH2Desc5"),
    t("MovingAccorH2Desc6"),
    t("MovingAccorH2Desc7"),
    t("MovingAccorH2Desc8"),
    t("MovingAccorH2Desc9"),
  ];
  moveAccordObj[t("MovingAccorHeading3")] = [
    t("MovingAccorH3Desc1"),
    t("MovingAccorH3Desc2"),
    t("MovingAccorH3Desc3"),
    t("MovingAccorH3Desc4"),
  ];
  return (
    <>

      {showScrollButton && (
        <div className="scroll-button" onClick={handleScrollToTop}>
          <h3>Start Request</h3>
        </div>
      )}

      <Hero image={HomeImage}>
        <ServiceModel
          Serviceaction={t("Moving-Navbar-Label")}
          ServiceCardIcon={Icon}
          ServiceDescription={t("Moving-Navbar-Desc")}
          selopt={"Moving"}
        />
      </Hero>

      <MediaQuery minWidth={1026}>
        <ProcessSteps label={t("Moving-Navbar-TagLine")}>
          <ThreeStepCardComponent
            svg={FormIllustration}
            title={t("Process-step-1")}
            content={t("Process-step1-desc")}
          />
          <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
          <ThreeStepCardComponent
            svg={OfferIllustration}
            title={t("Process-step-2")}
            content={t("Process-step2-desc")}
          />
          <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
          <ThreeStepCardComponent
            svg={OrderIllustration}
            title={t("Process-step-3")}
            content={t("Process-step3-desc")}
          />
        </ProcessSteps>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
        <ProcessSteps label={t("Moving-Navbar-TagLine")}>
          <ThreeStepCardComponent
            svg={FormIllustration}
            title={t("Process-step-1")}
            content={t("Process-step1-desc")}
          />
          {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
          <ThreeStepCardComponent
            svg={OfferIllustration}
            title={t("Process-step-2")}
            content={t("Process-step2-desc")}
          />
          {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
          <ThreeStepCardComponent
            svg={OrderIllustration}
            title={t("Process-step-3")}
            content={t("Process-step3-desc")}
          />
        </ProcessSteps>
      </MediaQuery>

      <AdvantagesComponent />

      <ServicesOffered
        subHeading={t("Moving-Service")}
        heading={t("MovingService-Heading")}
        headEnd={t("offers-word")}
      >
        <OfferModel
          OfferIcon={NearbyServiceIcon}
          OfferName={t("MovingOffer1")}
          description={t("MovingOfferDesc1")}
        />
        <OfferModel
          OfferIcon={MovingServiceIcon}
          OfferName={t("MovingOffer2")}
          description={t("MovingOfferDesc2")}
        />
        <OfferModel
          OfferIcon={JunkRemovalIcon}
          OfferName={t("MovingOffer3")}
          description={t("MovingOfferDesc3")}
        />
        <OfferModel
          OfferIcon={RelocationIcon}
          OfferName={t("MovingOffer4")}
          description={t("MovingOfferDesc4")}
        />
        <OfferModel
          OfferIcon={OfficeMovingIcon}
          OfferName={t("MovingOffer5")}
          description={t("MovingOfferDesc5")}
        />
        <OfferModel
          OfferIcon={CostEffectiveMovingIcon}
          OfferName={t("MovingOffer6")}
          description={t("MovingOfferDesc6")}
        />
      </ServicesOffered>

      <Flex2fragments img={Packing1} classes={"mt-10"} flexReverse={0}>
        <ServiceInfo />
      </Flex2fragments>

      <Flex2fragments img={Packing} classes={""} flexReverse={1}>
        <PackingServiceInfo />
      </Flex2fragments>

      <MovingProcessRelocation>
        <ReadMoreComponent
          Content={[
            ["01", t("MovingWPTitle1"), t("MovingWPDesc1")],
            ["02", t("MovingWPTitle2"), t("MovingWPDesc2")],
            ["03", t("MovingWPTitle3"), t("MovingWPDesc3")],
            ["04", t("MovingWPTitle4"), t("MovingWPDesc4")],
          ]}
        />
      </MovingProcessRelocation>

      <MovingAccordian accordionHeading={moveAccordObj} />
      
      <PDFViewer/>
      {/* <PrivacyPolicy image={PrivacyPolicyBG} title={t("Privacy-Policy-Label")} policy={t("Privacy-Policy-Desc")} /> */}
      {/* <Testimonials /> */}

      {/* </MediaQuery> */}
    </>
  );
};

export default Moving;
