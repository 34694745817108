import { useSelector } from "react-redux";

import "./index.css";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const requestsData = useSelector((state) => state.companyReducer);
  const requests = requestsData.requests;
  const { t } = useTranslation();

  return (
    <>
      <div className="Profile-container">
        {1 ? (
          <div className="profile-section">
            <div className="profile-details">
              <div>
                <div className="company-name mb-1"> {t("name")} :</div>
                <div className="company-address mb-1">{t("address")}:</div>
                <div className="company-contactNumber mb-1">{t("con")}:</div>
                <div className="company-email mb-1">Email:</div>
              </div>
            </div>
          </div>
        ) : (
          <div>{t("no-data")}</div>
        )}
      </div>
    </>
  );
};

export default UserProfile;
