import { createSlice } from "@reduxjs/toolkit";
const requestFilterReducer=createSlice({
   name:"requestFilters",
   initialState:{
      filters:{
         category:[],
         focus:[],
         requestStatus:[],
      },
   },
   reducers:{
      resetFilters(state){
         state.filters.category=[];
         state.filters.focus=[];
         state.filters.requestStatus=[];
      },
      updateFiltersCategory(state,action){
         state.filters.category=action.payload;
      },
      updateFiltersFocus(state,action){
         state.filters.focus=action.payload;
      },
      updateFiltersRequestStatus(state,action){
         state.filters.requestStatus=action.payload;
      }
   }
});


export default requestFilterReducer.reducer;
export const requestFilterAction=requestFilterReducer.actions;