const requestsReducer = (state = [], action) => {
    switch (action.type) {
      case "GETREQUESTS":
        return action.payload || state; // Return the payload if it exists, otherwise return the current state
      default:
        return state;
    }
  };
  
  export default requestsReducer;
  
  