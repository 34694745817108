import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { colorsArray } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
const RequestDrop = (props) => {
  const list = props.list;
  const { t } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("category")}
        </Grid>
        <Grid item xs={9}>
          {list.category.type}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("con")} {t("request")}
        </Grid>
        <Grid item xs={9}>
          {list.offerType}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("date")}
        </Grid>
        <Grid item xs={9}>
          {list.date}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("object")}
        </Grid>
        <Grid item xs={9}></Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("no-room")}
        </Grid>
        <Grid item xs={9}>
          {list.numberOfRooms} rooms
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("obj-area")}
        </Grid>
        <Grid item xs={9}>
          {list.objectArea} m2
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("poll")}
        </Grid>
        <Grid item xs={9}>
          {list.pollution}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          {t("areas")}
        </Grid>
        <Grid item xs={9}>
          {list.areas.map((val, index) => {
            return (
              <>
                <span
                  className=""
                  key={index}
                  style={{
                    background: colorsArray[index % 5],
                    padding: "1px 8px",
                    borderRadius: "5px",
                    marginRight: "5px",
                    color: "white",
                    marginLeft: "0px",
                  }}
                >
                  {val}
                </span>
              </>
            );
          })}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          Extras
        </Grid>
        <Grid item xs={9}>
          {list.extras.split(" ").map((val, index) => {
            return val !== "" ? (
              <span
                className=""
                key={index + "extra"}
                style={{
                  background: colorsArray[index % 5],
                  padding: "1px 8px",
                  borderRadius: "5px",
                  margin: "5px",
                  color: "white",
                  marginLeft: "0px",
                }}
              >
                {val}
              </span>
            ) : (
              <></>
            );
          })}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          Distance
        </Grid>
        <Grid item xs={9}>
          {list.distance}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          Focus
        </Grid>
        <Grid item xs={9}>
          {list.focus}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          Tel. reachable
        </Grid>
        <Grid item xs={9}>
          {list.reachableFrom}pm-{list.reachableTill}pm
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          Discount
        </Grid>
        <Grid item xs={9}>
          {list.discount}
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
        <Grid item xs={3} sx={{ fontWeight: "900" }}>
          Address
        </Grid>
        <Grid item xs={9}>
          {list.companyPlace} <br /> {list.postalCode}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestDrop;
