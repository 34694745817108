import React, { useState } from "react";
import axios from "axios";
import "./PayPalIntegrationAdvanced.css";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";

function detectCardType(cardNumber) {
  // Remove all spaces from the card number
  const cardNumberWithoutSpaces = cardNumber.replace(/\s/g, "");

  // Define regular expressions for common card types
  const cardTypes = [
    { type: "visa", pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },
    { type: "mastercard", pattern: /^5[1-5][0-9]{14}$/ },
    { type: "amex", pattern: /^3[47][0-9]{13}$/ },
    { type: "discover", pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/ },
    // Add more card types and patterns as needed
  ];

  // Use regular expressions to identify the card type
  for (const cardType of cardTypes) {
    if (cardType.pattern.test(cardNumberWithoutSpaces)) {
      return cardType.type;
    }
  }

  return "unknown";
}

function formatCreditCardNumber(e) {
  const input = e.target;
  const numericValue = input.value.replace(/\D/g, "");
  const formattedValue = numericValue.replace(/(\d{4})(?=\d)/g, "$1 ");
  input.value = formattedValue;
}

function formatExpiryDate(e) {
  const input = e.target;
  let value = input.value.replace(/\D/g, "");

  if (value.length > 2) {
    value = value.slice(0, 2) + " / " + value.slice(2);
  }

  input.value = value;
}

function PayPalIntegration() {
  const companyDetails = useSelector((state) => state.companyReducer);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCVV] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState(10.0);

  const [cardNumberError, setCardNumberError] = useState("");
  const [cardHolderError, setCardHolderError] = useState("");
  const [expiryDatesError, setExpirtyDateError] = useState("");
  const [cvvHolderError, setCvvHolderError] = useState("");

  const PayPalServerUrl = process.env.REACT_APP_BACKEND_SERVER1;
  const BackEndUrl = process.env.REACT_APP_BACKEND_SERVER2;

  const validateForm = () => {
    let isValid = true;

    if (!cardNumber.trim()) {
      setCardNumberError("Card number cannot be empty");
      isValid = false;
    } else {
      setCardNumberError("");
    }

    if (!cardHolder.trim()) {
      setCardHolderError("Card holder name cannot be empty");
      isValid = false;
    } else {
      setCardHolderError("");
    }

    if (!expiry.trim()) {
      setExpirtyDateError("Expiry Date cannot be empty");
      isValid = false;
    } else {
      setExpirtyDateError("");
    }

    if (!cvv.trim()) {
      setCvvHolderError("CVV cannot be empty");
      isValid = false;
    } else {
      setCvvHolderError("");
    }

    return isValid;
  };

  const handleSavePaymentInfo = () => {
    // Prepare the credit card info object
    const cardType = detectCardType(cardNumber);
    const cardNumberWithoutSpaces = cardNumber.replace(/\s/g, "");
    const creditCardInfo = {
      cardHolderName: cardHolder,
      fullCardNumber: cardNumberWithoutSpaces,
      cardType: cardType,
      expiryDate: expiry,
      cvv: cvv,
      userId: companyDetails.id,
    };

    if (!validateForm()) {
      return;
    }

    // Make a POST request to save the credit card info
    axios
      .post(`https://api.easy-compare.chapi/saveCreditCardInfo`, creditCardInfo, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(
          "Credit card information saved successfully:",
          response.data,
        );
        // Check for duplicate response from the server
        if (response.data === "Duplicate credit card information.") {
          window.alert(
            "Duplicate credit card information. Please enter unique details.",
          );
        }
        // You can add further handling here, e.g., notifying the user.
      })
      .catch((error) => {
        alert(
          "Error Saving Credit Card Info. It could be a duplicate already!",
        );
        console.error("Error saving credit card information:", error);
        // Handle errors, e.g., showing an error message to the user.
      });
  };

  return (
    <>
      <MediaQuery minWidth={1026}>
        <div className="payment-container">
          <div className="top-row">
            <div className="twoby2-1">
              <label htmlFor="ccn">Credit Card Number:</label>
              <input
                className="inputs"
                id="ccn"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength="19"
                placeholder="xxxx xxxx xxxx xxxx"
                value={cardNumber}
                onChange={(e) => {
                  formatCreditCardNumber(e);
                  setCardNumber(e.target.value);
                }}
              />
              <div className="error-message">{cardNumberError}</div>
            </div>
            <div className="twoby2-2">
              <label htmlFor="cardowner">Card Holder</label>
              <input
                id="cardowner"
                type="text"
                className="inputs"
                value={cardHolder}
                onChange={(e) => setCardHolder(e.target.value)}
              />
              <div className="error-message">{cardHolderError}</div>
            </div>
          </div>
          <div className="bottom-row">
            <div className="twoby2-3">
              <label htmlFor="expiry-date">Expiry Date:</label>
              <input
                id="expiry-date"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{7}"
                maxLength="7"
                value={expiry}
                onChange={(e) => {
                  formatExpiryDate(e);
                  setExpiry(e.target.value);
                }}
                className="inputs"
                placeholder="MM / YY"
              />
              <div className="error-message">{expiryDatesError}</div>
            </div>
            <div className="twoby2-4">
              <label htmlFor="cvv">CVV:</label>
              <input
                className="inputs"
                id="cvv"
                type="tel"
                inputMode="numeric"
                pattern="[0-9]{2,3}"
                autoComplete="cc-csc"
                maxLength="3"
                placeholder="123"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
              />
              <div className="error-message">{cvvHolderError}</div>
            </div>
          </div>

          <button className="buybtn" onClick={handleSavePaymentInfo}>
            Save Information
          </button>

          {/* <div>
        <button className="buybtn" onClick={handleSavePaymentInfo}>Save the Information</button>
      </div> */}

          {/* <label htmlFor="amount">Amount:</label>
      <input
        id="amount"
        type="number"
        step="0.01"
        min="0.01"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        className="inputs"
      />

      <label htmlFor="currency">Currency:</label>
      <select
        id="currency"
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
        className="inputs"
      >
        <option value="USD">USD</option>
        <option value="EUR">EUR</option>
      </select> */}
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
        <div className="payment-container-mobile">
            <div className="twoby2-1">
              <label htmlFor="ccn">Credit Card Number:</label>
              <input
                className="inputs"
                id="ccn"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength="19"
                placeholder="xxxx xxxx xxxx xxxx"
                value={cardNumber}
                onChange={(e) => {
                  formatCreditCardNumber(e);
                  setCardNumber(e.target.value);
                }}
              />
              <div className="error-message">{cardNumberError}</div>
            </div>
            <div className="twoby2-2">
              <label htmlFor="cardowner">Card Holder</label>
              <input
                id="cardowner"
                type="text"
                className="inputs"
                value={cardHolder}
                onChange={(e) => setCardHolder(e.target.value)}
              />
              <div className="error-message">{cardHolderError}</div>
            </div>
            <div className="twoby2-3">
              <label htmlFor="expiry-date">Expiry Date:</label>
              <input
                id="expiry-date"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{7}"
                maxLength="7"
                value={expiry}
                onChange={(e) => {
                  formatExpiryDate(e);
                  setExpiry(e.target.value);
                }}
                className="inputs"
                placeholder="MM / YY"
              />
              <div className="error-message">{expiryDatesError}</div>
            </div>
            <div className="twoby2-4">
              <label htmlFor="cvv">CVV:</label>
              <input
                className="inputs"
                id="cvv"
                type="tel"
                inputMode="numeric"
                pattern="[0-9]{2,3}"
                autoComplete="cc-csc"
                maxLength="3"
                placeholder="123"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
              />
              <div className="error-message">{cvvHolderError}</div>
            </div>

          <button className="buybtn" onClick={handleSavePaymentInfo}>
            Save Information
          </button>
        </div>
      </MediaQuery>
    </>
  );
}

export default PayPalIntegration;
