import React from "react";
import EasyCompareLogo from "./EasyCompareLogo";
import "./Footer.css";
import {
  TiSocialTwitter,
  TiSocialLinkedin,
  TiSocialYoutube,
} from "react-icons/ti";
import { RiInstagramFill, RiFacebookFill } from "react-icons/ri";
import { TbMail } from "react-icons/tb";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <MediaQuery minWidth={1026}>
        <footer>
          <div className="container footer-container">
            <div className="footer-section1">
              <div className="footer-sub">
                <div className="logostuff">
                  <EasyCompareLogo />
                </div>
                <p>{t("Footer-content")}</p>
                <span className="social">
                  <RiFacebookFill /> <TiSocialTwitter /> <RiInstagramFill />{" "}
                  <TiSocialLinkedin /> <TiSocialYoutube />
                </span>
              </div>
            </div>

            <div className="footer-section1">
              <div className="footer-sub hidden">
                <h4>Product</h4>
                <a className="links" href="/">
                  Moving
                </a>
                <a className="links" href="/Cleaning">
                  Cleaning
                </a>
                <a className="links" href="/Painting">
                  Painting
                </a>
                <a className="links" href="/Carpenting">
                  Carpenter
                </a>
              </div>
              <div className="footer-sub">
                <h4>{t("Company")}</h4>
                <a className="links" href="/aboutus">
                  {t("About Us")}
                </a>
                <a className="links" href="/contactus">
                  {t("Contact Us")}
                </a>
                {/* <a className="links" href="/tipsandtricks">
                  {t("Tips and Tricks")}
                </a> */}
              </div>
              {/* <div className="footer-sub">
                <h4>{t("Support")}</h4>
                <a className="links">{t("Help Center")}</a>
                <a className="links">{t("Server Status")}</a>
                <a className="links">{t("Report a bug")}</a>
                <a className="links">{t("Chat Support")}</a>
              </div> */}
              <div className="footer-sub">
                <h4>{t("Contact Us")}</h4>
                <span>
                  <TbMail style={{ color: "white" }} />
                  <a className="links" href="">
                    info@easy-compare.ch
                  </a>
                </span>
                <span>
                  <BsTelephone style={{ color: "white" }} />
                  <a className="links" href="">
                    +41 43 535 65 43
                  </a>
                </span>
                <span>
                  <HiOutlineLocationMarker style={{ color: "white" }} />
                  <a className="links" href="">
                    {" "}
                    Greifenseestrasse 25
                    <br />
                    8604 Volketswil-CH
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="copyright">
              <hr></hr>
              <div className="copyright-content">
                <span>{t("Copyright")}</span>
                <span>
                  {t("All Rights Reserved")} |{" "}
                  <span>
                    <a className="" href="/TermsCondition">
                      {t("Terms and Conditions")}
                    </a>
                  </span>{" "}
                  |{" "}
                  <span>
                    <a
                      className="links"
                      href="/PrivacyPolicyContent"
                    >
                      {t("Privacy-Policy")}
                    </a>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </footer>
      </MediaQuery>



      <MediaQuery maxWidth={1024}>
      <footer style={{ background: "#001062", color: "white" }}>
          <div className="container footer-container-mobile">
            <div className="footer-section1-mobile">
              <div className="footer-sub-mobile">
                <div className="logostuff-mobile">
                  <EasyCompareLogo />
                </div>
                <p>{t("Footer-content")}</p>
                <span className="social-mobile">
                  <RiFacebookFill /> <TiSocialTwitter /> <RiInstagramFill />{" "}
                  <TiSocialLinkedin /> <TiSocialYoutube />
                </span>
              </div>
            </div>

            <div className="footer-section1-mobile">
              <div className="footer-sub-mobile hidden">
                <h4>Product</h4>
                <a className="links-mobile " href="/">
                  Moving
                </a>
                <a className="links-mobile " href="/Cleaning">
                  Cleaning
                </a>
                <a className="links-mobile " href="/Painting">
                  Painting
                </a>
                <a className="links-mobile " href="/Carpenting">
                  Carpenter
                </a>
              </div>
              <div className="footer-sub-mobile">
                <h4>{t("Company")}</h4>
                <a className="links" href="/aboutus">
                  {t("About Us")}
                </a>
                <a className="links" href="/contactus">
                  {t("Contact Us")}
                </a>
                <a className="links" href="">
                  {t("Tips and Tricks")}
                </a>
              </div>
              <div className="footer-sub-mobile">
                <h4>{t("Support")}</h4>
                <a className="links-mobile ">{t("Help Center")}</a>
                <a className="links-mobile ">{t("Server Status")}</a>
                <a className="links-mobile ">{t("Report a bug")}</a>
                <a className="links-mobile ">{t("Chat Support")}</a>
              </div>
              <div className="footer-sub-mobile">
                <h4>{t("Contact Us")}</h4>
                <span>
                  <TbMail style={{ color: "white" }} />
                  <a className="links-mobile" href="">
                    info@easy-compare.ch
                  </a>
                </span>
                <span>
                  <BsTelephone style={{ color: "white" }} />
                  <a className="links-mobile" href="">
                    +41 43 535 65 43
                  </a>
                </span>
                <span>
                  <HiOutlineLocationMarker style={{ color: "white" }} />
                  <a className="links-mobile" href="">
                    {" "}
                    Greifenseestrasse 25
                    <br />
                    8604 Volketswil-CH
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="container-mobile">
            <div className="copyright-mobile">
              <div className="container copyright-content-moble">
                <span>{t("Copyright")}</span>
                <span>
                  {t("All Rights Reserved")} |{" "}
                  <span>
                    <a className="" href="/TermsCondition" target="_blank">
                      {t("Terms and Conditions")}
                    </a>
                  </span>{" "}
                  |{" "}
                  <span>
                    <a
                      className="links"
                      href="/PrivacyPolicyContent"
                      target="_blank"
                    >
                      {t("Privacy-Policy")}
                    </a>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </footer>
      </MediaQuery>
    </>
  );
};
export default Footer;
