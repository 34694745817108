import React from 'react';
import Hero from '../common/Hero';
import ServiceModel from '../common/ServiceModel';
import ProcessSteps from "../ProcessSteps";
import ThreeStepCardComponent from "../ThreeStepCardComponent";
import FormIllustration from '../../images/FormIllustration.svg';
import OfferIllustration from '../../images/OfferIllustration.svg';
import OrderIllustration from '../../images/PaintingPage/PaintRollerIconForOrder.svg';
import Vector3 from '../../images/Vector3.svg';
import ServicesOffered from '../common/ServicesOffered';
import OfferModel from '../common/OfferModel';
import Flex2fragments from '../layouts/Flex2fragments';
import HomeImage from '../../images/home/painting.png';
import IconpaintingIcon from '../../images/PaintingPage/IconpaintingIcon.png';
import IconpaintingCompany from '../../images/PaintingPage/IconpaintingCompany.png';
import IconroomPainting from '../../images/PaintingPage/IconroomPainting.png';
import IconwallsPainting from '../../images/PaintingPage/IconwallsPainting.png';
import GroupprofessionalPaint from '../../images/PaintingPage/GroupprofessionalPaint.png';
import Iconfascade from '../../images/PaintingPage/Iconfascade.png';
import Iconwork from '../../images/PaintingPage/Iconwork.png';
import HowWeWorkCleaning from '../../images/PaintingPage/paintImage.png';
import EMPPaint from '../../images/PaintingPage/EMPPaint.png';
import ServiceInfo from './ServiceInfo';
import EmployeeOffer from './EmployeeOffer';
import PaintingAccordion from './PaintingAccordion';
import PaintingPricing from './PaintingPricing';
import ReadMoreComponent from '../common/ReadMoreComponent';
import CarpenterWorkingProcess from '../CarpenterPage/CarpenterWorkingProcess';
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive';
import PaintingProcess from './PaintingWorkingProcess';
import AdvantagesComponent from '../AdvantagesComponent';
import Testimonials from '../Testimonials';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import PDFViewer from './PDFViewer';


const Painting = () => {
   const navigate = useNavigate();
   const [showScrollButton, setShowScrollButton] = useState(false);
   const { t } = useTranslation();
   let paintAccordObj = {};
   paintAccordObj[t("Paint-Heading1")] = [t("Paint-Label1"), t("Paint-Desc1")];
   paintAccordObj[t("Paint-Heading2")] = [t("Paint-Label2"), t("Paint-Desc2")];
   paintAccordObj[t("Paint-Heading3")] = [t("Paint-Label3"), t("Paint-Desc3")];
   paintAccordObj[t("Paint-Heading4")] = [t("Paint-Label4"), t("Paint-Desc4")];

   useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const handleScrollToTop = () => {
      navigate("/User/ServiceRequest?requestType=Painting");
    };

   return <>
   {showScrollButton && (
        <div className="scroll-button" onClick={handleScrollToTop}>
          <h3>Start Request</h3>
        </div>
      )}

      <Hero image={HomeImage}>
         <ServiceModel Serviceaction={t("Paint-Navbar-Label")} ServiceCardIcon={IconpaintingIcon} ServiceDescription={t("Paint-Navbar-Desc")} selopt={"Painting"} />
      </Hero>

      <MediaQuery minWidth={1026}>
         <ProcessSteps label={t("Paint-Navbar-TagLine")}>
            <ThreeStepCardComponent svg={FormIllustration} title={t('Process-step-1')} content={t("Process-step1-desc")} />
            <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
            <ThreeStepCardComponent svg={OfferIllustration} title={t('Process-step-2')} content={t("Process-step2-desc")} />
            <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
            <ThreeStepCardComponent svg={OrderIllustration} title={t("Process-step-3")} content={t("Process-step3-desc")} />
         </ProcessSteps>
         <AdvantagesComponent />
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
         <ProcessSteps label={t("Paint-Navbar-TagLine")}>
            <ThreeStepCardComponent svg={FormIllustration} title={t('Process-step-1')} content={t("Process-step1-desc")} />
            {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
            <ThreeStepCardComponent svg={OfferIllustration} title={t('Process-step-2')} content={t("Process-step2-desc")} />
            {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
            <ThreeStepCardComponent svg={OrderIllustration} title={t("Process-step-3")} content={t("Process-step3-desc")} />
         </ProcessSteps>
         <AdvantagesComponent />
      </MediaQuery>

      <ServicesOffered subHeading={t("Painting-service")} heading={t("PaintingService-Heading")} headEnd={t("offers-word-paint")}>
         <OfferModel OfferIcon={IconpaintingCompany} OfferName={t("Paintoffers1")} description={t("PaintoffersDesc1")} />
         <OfferModel OfferIcon={IconroomPainting} OfferName={t("Paintoffers2")} description={t("PaintoffersDesc2")} />
         <OfferModel OfferIcon={IconwallsPainting} OfferName={t("Paintoffers3")} description={t("PaintoffersDesc3")} />
         <OfferModel OfferIcon={GroupprofessionalPaint} OfferName={t("Paintoffers4")} description={t("PaintoffersDesc4")} />
         <OfferModel OfferIcon={Iconfascade} OfferName={t("Paintoffers5")} description={t("PaintoffersDesc5")} />
         <OfferModel OfferIcon={Iconwork} OfferName={t("Paintoffers6")} description={t("PaintoffersDesc6")} />
      </ServicesOffered>
      <Flex2fragments img={HowWeWorkCleaning} classes={"mt-10"} flexReverse={0}>
         <ServiceInfo />
      </Flex2fragments>
      <Flex2fragments img={EMPPaint} classes={"mb-6"} flexReverse={1}>
         <EmployeeOffer OfferContent={[t("Paint-EmpOffer1"), t("Paint-EmpOffer2"), t("Paint-EmpOffer3"), t("Paint-EmpOffer4"), t("Paint-EmpOffer5"), t("Paint-EmpOffer6"), t("Paint-EmpOffer7"), t("Paint-EmpOffer10"), t("Paint-EmpOffer11")]} />
      </Flex2fragments>
      {/* <PaintingAccordion accordionHeading={paintAccordObj} /> */}
      <PaintingProcess>
         <ReadMoreComponent Content={[["01", t("Paint-Heading1"), t("Paint-Desc1")],["02", t("Paint-Heading2"), t("Paint-Desc2")], ["03", t("Paint-Heading3"), t("Paint-Desc3")], ["04", t("Paint-Heading4"), t("Paint-Desc4")]]}/>
      </PaintingProcess>
      {/* <PaintingPricing /> */}

      <PDFViewer />
      {/* <Testimonials /> */}
   </>
}

export default Painting;