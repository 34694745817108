import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, ButtonGroup, Button} from "react-bootstrap";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Loader from "../../common/Loader";
import axios from "axios";

const PhoneVerify = (props) => {
  const { t } = useTranslation();

  const [otp, setOtp] = useState(''); // New OTP state
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  let preresult = props.resultCleaning;

  const navigate = useNavigate();
  const [phonenumber, setPhonenumber] = useState(
    props.resultCleaning.phonenumber
  );
  const [availabletime, setAvailabletime] = useState(
    props.resultCleaning.availabletime
  );
  const [formErrors, setformErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fnPhoneNumber = (e) => {
    setPhonenumber(e.target.value);
    setformErrors("");
  };
  const fnAvailabletime = (e) => {
    setAvailabletime(e.target.value);
  };
  const fnClickPrev = () => {
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnVerifyPhone = () => {
    const pattern = /^(\+\d{1,3}[- ]?)?\d+$/;
    if (!phonenumber) {
      setformErrors(t("enter-phone"));
    } else if (!pattern.test(phonenumber)) {
      setformErrors(t("enter-only-number"));
    } else {
      let areas = [];
      let paintList =[];
      let extras = "";
      preresult.whatclean.map((list) => areas.push(list));
      preresult.whatToPaint.map((list) => paintList.push(list));
      preresult.additionalarea.map((list) => (extras += " " + list));

      let matches = availabletime.match(/\d+/g);

      const formData = new FormData();
      const imageArr = [];
      preresult.images.forEach((image, index) => {
        formData.append(`image-${index}`, image);
        imageArr.push(image);
        console.log(image);
      }); 
// debug from here, see if the data get posted first
      let postdata = {
        categoryId: preresult.category,
        requestType: preresult.requestType,
        user: {
          firstname: preresult.firstname,
          lastname: preresult.lastname,
          email: preresult.email,
          password: preresult.password,
          contactNo: phonenumber,
          address: {
            location: preresult.moveOutAddress,
            postCode: preresult.postcode,
            latitude: preresult.latitude,
            longitude: preresult.longitude,
          },
        },
        date: preresult.categorydate,
        numberOfRooms: parseInt(preresult.roomnum),
        purchaseGuarantee: "",
        objectArea: parseInt(preresult.floorarea),
        pollution: preresult.pollution,
        areas: areas,
        extras: extras,
        address: {
          location: preresult.moveOutAddress,
          postCode: preresult.postcode,
          latitude: preresult.latitude,
          longitude: preresult.longitude,
        },
        moveInaddress: {
          moveInlocation: preresult.moveInAddress,
          postcode1: preresult.postcode1,
          latitude1: preresult.latitude1,
          longitude1: preresult.longitude1,
        },
        distance: preresult.Distance,
        cost: preresult.cost,
        focus: preresult.mimportant,
        offerType: preresult.offerType,
        frequency: "",
        noOfBoxes: 0,
        discount: 0,
        reachableFrom: matches[0],
        reachableTill: matches[1],
        description: preresult.orderdescription,
        images: preresult.images,
        categoryName: preresult.categoryName,
        moveIndate:preresult.moveIndate,
        categorytimeflex:preresult.categorytimeflex,
        MovingOutTimeFlex:preresult.MovingOutTimeFlex,
        subCategory:preresult.subCategory,
        MoveOutCategory:preresult.MoveOutCategory,
        TermsCondition:preresult.TermsCondition,
        floors:preresult.floors,
        MoveInfloor:preresult.MoveInfloor,
        MovingDistance:preresult.MovingDistance,
        noOfPeople:preresult.noOfPeople,
        Elevator:preresult.Elevator,
        MoveInElevator:preresult.MoveInElevator,
        degreeOfSoiling:preresult.degreeOfSoiling,
        floorCovering:preresult.floorCovering,
        windowsWith:preresult.windowsWith,
        heating:preresult.heating,
        CleanBalcony:preresult.CleanBalcony,
        BalconyArea:preresult.BalconyArea,
        PaintedLastTime:preresult.PaintedLastTime,
        SmokingHousehold:preresult.SmokingHousehold,
        isolatingcoating:preresult.isolatingcoating,
        paintList:paintList
      };
      const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";
      console.log("finalpost data", postdata);
      setIsLoading(true);
      Axios.post(REACT_APP_BACKEND_SERVER2 + "/request/save", postdata)
        .then((res) => {
          console.log(res)
          if (res.data.status === "Success") {
            setIsLoading(false);
            alert(t("req-sub-success"));
            const params = new URLSearchParams(window.location.search);
            if (params.get("requestType") === "UserRequest") {
              navigate("/user/requests");
            } else {
              navigate("/Login");
            }
          } else {
            setIsLoading(false);
            // alert(t("something-went-wrong"));
            
          }
        })
        .catch((error) => {
          console.log("Error happened at ID 1928392");
        });
      const newState = Object.assign({}, props.resultCleaning, {
        phonenumber: phonenumber,
        availabletime: availabletime,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerifyPhone = async () => {
    // Send the entered numeric value to the server
    try {
      const response = await axios.post('https://api.easy-compare.chapi/verify-otp', {
        otp: otp, // Match the backend field name
      });
  
      if (response.status === 200) {
        // Request was successful
        if (response.data === 'Phone number verified successfully') {
          // Update your phoneVerified state here
          setPhoneVerified(true);
          setShowOtpInput(false);
        } else {
          // Handle an invalid OTP
          console.log('Invalid OTP');
        }
      } else {
        // Request failed
        // You can handle errors or show an error message here
        console.error('Phone verification request failed');
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error:', error);
    }
  };
  

  const fnClickVerifyPhone = () => {
    setIsLoading(true);
  
    // Replace 'your-phone-value' with the actual phone value
    const phoneData = {
      phoneNumber: phonenumber,
    };
  
    axios
      .post('https://api.easy-compare.chapi/verify-phone', phoneData)
      .then((response) => {
        if (response.status === 200) {
          // Request was successful
          // You can handle success or show a message here
          console.log('Phone verification request successful');
          setShowOtpInput(true);
        } else {
          // Request failed
          // You can handle errors or show an error message here
          console.error('Phone verification request failed');
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error('Network error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  return (
    <>
     {isLoading &&  <Loader /> }
      <div className="category-component">
        <div className="category-container">
          <span className="card-box" style={{ flexDirection: "column" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={t("enter-phone")}
              autoComplete="first-name"
              autoFocus
              value={phonenumber}
              onChange={fnPhoneNumber}
            />
            {formErrors && <span className="error">{formErrors}</span>}
            <div style={{ paddingLeft: "5px", display: "flex", gap: "1rem" }}>
              <div className="order-desc-custom3">
                <Form.Label style={{display: "flex", justifyContent: "center", alignItems: "center"}}>{t("available")}</Form.Label>
                <ButtonGroup
                  aria-label="Basic example"
                  onClick={fnAvailabletime}
                  className="phone-desc"
                >
                  <Button
                    className={
                      availabletime === "8am-12pm"
                        ? "active btn-primary"
                        : "btn-secondary"
                    }
                    value="8am-12pm"
                  >
                    {t("8-12")}
                  </Button>
                  <Button
                    className={
                      availabletime === "12pm-1pm"
                        ? "active btn-primary"
                        : "btn-secondary"
                    }
                    value="12pm-1pm"
                  >
                    {t("12-1")}
                  </Button>
                  <Button
                    className={
                      availabletime === "1pm-5pm"
                        ? "active btn-primary"
                        : "btn-secondary"
                    }
                    value="1pm-5pm"
                  >
                    {t("1-5")}
                  </Button>
                  <Button
                    className={
                      availabletime === "5pm-8pm"
                        ? "active btn-primary"
                        : "btn-secondary"
                    }
                    value="5pm-8pm"
                  >
                    {t("5-8")}
                  </Button>
                  <Button
                    className={
                      availabletime === "from 8pm"
                        ? "active btn-primary"
                        : "btn-secondary"
                    }
                    value="from 8pm"
                  >
                    {t("from-8")}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </span>
          <span className="prevNext-btn card-box">
            <Button variant="warning" onClick={fnClickPrev}>
              {t("prev")}
            </Button>

            <Button variant="warning" onClick={fnVerifyPhone}>
                {t("Finish")}
              </Button>
          </span>
        </div>
      </div>
    </>
  );
};

export default PhoneVerify;
