import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./index.css";
const AdminMenu = () => {
  const { t } = useTranslation();
  return (
    <>
      <ul className="AccountMenu">
        <NavLink to="/admin/companydetails" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("all-company")}</div>
        </NavLink>
        <NavLink to="/admin/topuprequests" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("top-up")}</div>
        </NavLink>
      </ul>
    </>
  );
};

export default AdminMenu;
