import React from 'react';
import './AboutUs.css';
import proficiency from '../../images/proficiency.jpg';
import customerconfirm from '../../images/happy-customer.webp';
import unique from '../../images/fingerprint-better.jpg';
import chossingeasy from '../../images/checked.jpg';
import Navbar from '../common/Navbar';
import Testimonials from '../Testimonials';
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
    const { t } = useTranslation(); 
  return (
    <>
    <div className="hero-about-us">
      <Navbar />
    </div>
    
    <div className="about-us-container">
      <header>
        <h1>{t("about-us")}</h1>
        <p className="extras">{t("subheading1")}</p>
        <p className="extras">
          {t("proficiency-msg")}
        </p>
      </header>

      <div className="sections-container">
        <section className="left-section">
        <img src={proficiency} alt="Customer Confirmation" className="section-image" />
          <h2>
            <span className="section-icon">🛠️</span>{t("proficiency")}
          </h2>
          <p>
          {t("proficiency-msg")}
          </p>
        </section>

        <section className="right-section">
          <img src={customerconfirm} alt="Customer Confirmation" className="section-image" />
          <h2>
            <span className="section-icon">👥</span>{t("customer-confirm")}
          </h2>
          <p>
          {t("customer-msg")}
          </p>
        </section>

        <section className="left-section">
          <img src={unique} alt="What Makes Us Unique" className="section-image" />
          <h2>
            <span className="section-icon">🌟</span>{t("unique")}
          </h2>
          <p>
          {t("unique-msg")}
          </p>
        </section>

        <section className="right-section">
          <img src={chossingeasy} alt="Reasons to Pick Easy-Compare.ch" className="section-image" />
          <h2>
            <span className="section-icon">🤝</span>{t("pick-us")}
          </h2>
          <p>
          {t("pic-msg")}
          </p>
        </section>
      </div>
    </div>

    <Testimonials/>
    </>
  );
};

export default AboutUs;
