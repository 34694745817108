import React from 'react';
import '../CleaningPage/Pricing.css';
import './CarpenterWorkingProcess.css';
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive';

const CarpenterWorkingProcess = (props) => {
   const { t } = useTranslation();
   const requestType=window.location.toString().includes("Painting")
   console.log(requestType);
   return <>
      <MediaQuery minWidth={1026}>
         <div className="container moving mt-10 mb-6">
            <div className='carpenter-working-process'>
               <span className="sub-heading hidden">{t("Moving-Process")}</span>
               <div className="main-heading mb-4 " style={{}}>{t("ConstructWorkingProcess")}</div>
               <div className="circle-container ">
                  <div className="circle">{t("Client")}</div>
                  <div className="line"></div>
                  <div className="circle dark">{t("Company")}</div>
                  <div className="line"></div>
                  <div className="circle">{t("ConstructComp")}</div>
               </div>
               <p className="work-info">
                  {t("ConstructWorkInfo")}
               </p>
               {props.children}
            </div>
         </div>
      </MediaQuery>


      <MediaQuery maxWidth={1024}>
         <div className="moving mt-10 mb-6">
            <div className='carpenter-working-process-mobile'>
               <span className="sub-heading hidden">{t("Moving-Process")}</span>
               <div className="main-heading mb-4 " style={{}}>{t("ConstructWorkingProcess")}</div>
               <div className="circle-container ">
                  <div className="circle">{t("client")}</div>
                  <div className="line"></div>
                  <div className="circle dark">{t("Company")}</div>
                  <div className="line"></div>
                  <div className="circle">{t("ConstructComp")}</div>
               </div>
               <p className="work-info">
                  {t("ConstructWorkInfo")}
               </p>
               {props.children}
            </div>
         </div>
      </MediaQuery>
   </>
}

export default CarpenterWorkingProcess;