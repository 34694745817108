import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { emailRegex } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
const Contact = (props) => {

  // ----------------------------------------------------
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState(''); // New OTP state
  const [message, setMessage] = useState('');
  const [numericValue, setNumericValue] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);

  // ----------------------------------------------------

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [firstname, setFirstname] = useState(props.resultCleaning.firstname);
  const [lastname, setLastname] = useState(props.resultCleaning.lastname);
  const [email, setEmail] = useState(props.resultCleaning.email);
  const [password, setPassword] = useState(props.resultCleaning.password);
  const [confirmpassword, setconfirmpassword] = useState(
    props.resultCleaning.confirmpassword
  );
  const [formErrors, setformErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };


  const fnFirstName = (e) => {
    setFirstname(e.target.value);
    setformErrors((prevValues) => {
      return { ...prevValues, firstname: "" };
    });
  };
  const fnLastName = (e) => {
    setLastname(e.target.value);
    setformErrors((prevValues) => {
      return { ...prevValues, lastname: "" };
    });
  };
  const fnEmail = (e) => {
    setEmail(e.target.value);
    setformErrors((prevValues) => {
      return { ...prevValues, email: "" };
    });
  };
  const fnPassword = (e) => {
    setPassword(e.target.value);
    setformErrors((prevValues) => {
      return { ...prevValues, password: "" };
    });
  };
  const fnconfirmpassword = (e) => {
    setconfirmpassword(e.target.value);
    setformErrors((prevValues) => {
      return { ...prevValues, confirmpassword: "" };
    });
  };
  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      confirmpassword: confirmpassword,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (!firstname) {
      setformErrors((prevValues) => {
        return { ...prevValues, firstname: t("enter-first") };
      });
    } else if (!lastname) {
      setformErrors((prevValues) => {
        return { ...prevValues, lastname: t("enter-last") };
      });
    } else if (!email) {
      setformErrors((prevValues) => {
        return { ...prevValues, email: t("enter-email") };
      });
    } else if (!emailRegex.test(email)) {
      setformErrors((prevValues) => {
        return { ...prevValues, email: t("enter-valid-email") };
      });
    } else {
      const newState = Object.assign({}, props.resultCleaning, {
        firstname: firstname,
        lastname: lastname,
        email: email,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    }
  };


  const handleInputChange = (event) => {
    const { value } = event.target;
    
    // Allow only numeric input (you can add more validation)
    if (!isNaN(value) || value === '') {
      setNumericValue(value);
    }
  };

  const handleVerifyEmail = async () => {
    // Send the entered numeric value to the server
    try {
      const response = await axios.post('https://api.easy-compare.ch/api/verify-email-otp', {
        email: email, // Replace with the user's email
        otp: otp,
      });
      

      if (response.data === 'Email verified successfully') {
        // Update your emailVerified state here
        setEmailVerified(true);
        setShowOtpInput(false);
      } else {
        // Handle an invalid OTP
        console.log('Invalid OTP');
      }
    } catch (error) {
      
      // Handle the error, e.g., network issue
      console.error('Error:', error);
    }
  };

  const fnClickVerifyEmail = () => {
    setLoading(true);
  
    // Replace 'your-email-value' with the actual email value
    const emailData = {
      email: email,
    };
  
    axios
      .post('https://api.easy-compare.ch/api/send-verification-email', emailData)
      .then((response) => {
        if (response.status === 200) {
          // Request was successful
          // You can handle success or show a message here
          console.log('Email verification request successful');
          setShowOtpInput(true);
        } else {
          // Request failed
          // You can handle errors or show an error message here
          console.error('Email verification request failed');
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error('Network error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <>
      <div className="category-component">
        <div className="category-container">
          <span className="card-box" style={{ flexDirection: "column" }}>
            <Form.Group className="text-center mb-5 h4">
              <Form.Label>{t("congratulations")}</Form.Label>
            </Form.Group>
            <InputLabel id="demo-simple-select-label">Title</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Title" 
            >
              <MenuItem value={t("mrs.")}>{t("mrs.")}</MenuItem>
              <MenuItem value={t("mr.")}>{t("mr")}</MenuItem>
            </Select>
            <TextField
              margin="normal"
              required
              fullWidth
              label={t("first-name")}
              autoComplete="first-name"
              autoFocus
              value={firstname}
              onChange={fnFirstName}
            />
            {formErrors.firstname && (
              <span className="error">{formErrors.firstname}</span>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label={t("last-name")}
              autoComplete="last-name"
              value={lastname}
              onChange={fnLastName}
            />
            {formErrors.lastname && (
              <span className="error">{formErrors.lastname}</span>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="E-MAIL"
              type="email"
              autoComplete="email"
              value={email}
              onChange={fnEmail}
            />
          </span>
          <span className="card-box" style={{ flexDirection: "column" }}>
            {/* Your other form inputs here */}
          </span>
          <span className="prevNext-btn card-box">
            <Button variant="warning" onClick={fnClickPrev}>
              {t("prev")}
            </Button>
            {emailVerified ? (
              <Button variant="warning" onClick={fnClickNext}>
                {t("next")}
              </Button>
            ) : (
              <Button 
              variant="primary" 
              onClick={fnClickVerifyEmail}
              disabled={loading}
              >
                {t("verify-email")}
              </Button>
            )}
          </span>


          {showOtpInput && (
        <div>
          <label htmlFor="otp">Enter the OTP:</label>
          <br />
          <input
            type="text"
            id="otp"
            name="otp"
            value={otp}
            onChange={handleOtpChange}
          />
          <br />
          <button onClick={handleVerifyEmail}>Verify Email</button>
        </div>
      )}
        </div>
      </div>
    </>
  );
};

export default Contact;
