import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./TipsTricks.css";
import { useTranslation } from "react-i18next";
import SaveIcon from '../../images/arrow.png';
import PrintIcon from '../../images/printer.png';
import GoBackIcon from '../../images/left-arrow-in-circular-button-black-symbol.png';
import ChecklistIcon from '../../images/pdf.png';
import TipsIcon from '../../images/pdf.png';

import BusinessChecklist_EN from '../../pdfs/MovingChecklist/English/BusinessChecklist.pdf';
import BusinessChecklist_DE from '../../pdfs/MovingChecklist/German/BusinessChecklist.pdf';
import BusinessChecklist_FR from '../../pdfs/MovingChecklist/French/BusinessChecklist.pdf';
import BusinessChecklist_IT from '../../pdfs/MovingChecklist/Italian/BusinessChecklist.pdf';

import PrivateChecklist_EN from '../../pdfs/MovingChecklist/English/PrivateChecklist.pdf';
import PrivateChecklist_DE from '../../pdfs/MovingChecklist/German/PrivateChecklist.pdf';
import PrivateChecklist_FR from '../../pdfs/MovingChecklist/French/PrivateChecklist.pdf';
import PrivateChecklist_IT from '../../pdfs/MovingChecklist/Italian/PrivateChecklist.pdf';


import MovingTips_EN from '../../pdfs/MovingTips/English/MovingTips.pdf';
import MovingTips_DE from '../../pdfs/MovingTips/German/MovingTips.pdf';
import MovingTips_FR from '../../pdfs/MovingTips/French/MovingTips.pdf';
import MovingTips_IT from '../../pdfs/MovingTips/Italian/MovingTips.pdf';

import PackagingTips_EN from '../../pdfs/MovingTips/English/PackagingTips.pdf';
import PackagingTips_DE from '../../pdfs/MovingTips/German/PackagingTips.pdf';
import PackagingTips_FR from '../../pdfs/MovingTips/French/PackagingTips.pdf';
import PackagingTips_IT from '../../pdfs/MovingTips/Italian/PackagingTips.pdf';


// Set the worker source for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = () => {
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(null);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (selectedPdfIndex !== null) {
        event.preventDefault();
      }
    };
    document.body.addEventListener("scroll", handleScroll, { passive: false });
    
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPdfIndex]);

  const langToPdfMap = {
    en: {
      checklists: [BusinessChecklist_EN, PrivateChecklist_EN],
      tips: [MovingTips_EN, PackagingTips_EN]
    },
    de: {
      checklists: [BusinessChecklist_DE, PrivateChecklist_DE],
      tips: [MovingTips_DE, PackagingTips_DE]
    },
    fr: {
      checklists: [BusinessChecklist_FR, PrivateChecklist_FR],
      tips: [MovingTips_FR, PackagingTips_FR]
    },
    it: {
      checklists: [BusinessChecklist_IT, PrivateChecklist_IT],
      tips: [MovingTips_IT, PackagingTips_IT]
    }
  };

  const langToPdfFileNameMap = {
    en: {
      checklists: ["Business Relocation Checklist", "Private Moving Checklist"],
      tips: ["Moving Tips", "Packaging Tips"]
    },
    de: {
      checklists: ["Checkliste Privatumzuge", "Checkliste Geschaftsumzuge"],
      tips: ["Umzugstipps", "Verpackungstipps"]
    },
    fr: {
      checklists: ["Checklist De Demenagement Dentreprise", "Checklist DeDemenagement Prive"],
      tips: ["Conseils de déménagement", "Conseils d'emballage"]
    },
    it: {
      checklists: ["Lista Di Controllo Per Il Trasferimento Di Unazienda"],
      tips: ["Suggerimenti per il trasloco", "Suggerimenti per l'imballaggio"]
    }
  };

  const handleThumbnailClick = (index, category) => {
    setSelectedPdfIndex(index);
    document.body.style.overflow='hidden'
  };

  const handleCloseFocus = () => {
    setSelectedPdfIndex(null);
    document.body.style.overflow=''
  };

  const handleSave = () => {
    const link = document.createElement('a');
    link.href = langToPdfMap[currentlang][selectedPdfIndex];
    link.download = langToPdfFileNameMap[currentlang][selectedPdfIndex];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenInNewTab = () => {
    const newWindow = window.open();
    newWindow.document.write(`
      <iframe src="${langToPdfMap[currentlang][selectedPdfIndex]}" width="100%" height="100%"></iframe>
    `);
  };

  const currentlang = window.localStorage.i18nextLng;
  const { t } = useTranslation();

  return (
    <>
      <div className="parent-checklist-moving"> 
        <div className="checklist-root">
          <div className="h22">
            <h2>{t('MovingChecklist')}</h2>
          </div>
          <div className="pdf-thumbnails">
            {langToPdfFileNameMap[currentlang].checklists.map((fileName, index) => (
              <div key={index} className="pdf-thumbnail-container-moving" onClick={() => handleThumbnailClick(index, 'checklists')}>
                <img className='thumbnail' src={ChecklistIcon} alt={`PDF ${index + 1}`} />
                <p>{fileName}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="checklist-root">
          <div className="h22">
            <h2>{t('MovingTips')}</h2>
          </div>
          <div className="pdf-thumbnails">
            {langToPdfFileNameMap[currentlang].tips.map((fileName, index) => (
              <div key={index} className="pdf-thumbnail-container-moving" onClick={() => handleThumbnailClick(index + langToPdfMap[currentlang].checklists.length, 'tips')}>
                <img className='thumbnail' src={TipsIcon} alt={`PDF ${index + 1}`} />
                <p>{fileName}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {selectedPdfIndex !== null && (
        <>
          <div className="pdf-overlay" onClick={handleCloseFocus}>
            <div className="pdf-buttons">
              <div>
                <img className="icons" src={GoBackIcon} alt="Go Back" onClick={handleCloseFocus} />
              </div>
              <div>
                <img className="icons" src={SaveIcon} alt="Save" onClick={handleSave} />
                <img className="icons" src={PrintIcon} alt="Open in new tab" onClick={handleOpenInNewTab} />
              </div>
            </div>
            <div className="pdf-focus">
              <Document file={langToPdfMap[currentlang].checklists[selectedPdfIndex] || langToPdfMap[currentlang].tips[selectedPdfIndex]} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PDFViewer;
