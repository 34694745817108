import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { getAuthAxios, colorsArray, sessionCheck } from "../../../utils/utils";
import "./index.css";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import OfferDialogBox from "../../common/OfferDialogBox";

const RequestDetail = () => {
  const params = new URLSearchParams(window.location.search);
  const companyDetails = useSelector((state) => state.companyReducer);
  const name = params.get("company");
  const requestsData = useSelector((state) => state.companyReducer);
  const detail = useSelector((state) => state.setRequest.selectedRequest);
  const requestId = detail.id;
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [creditCardInfo, setCreditCardInfo] = useState(null);
  const [accepted, setAccepted] = useState(
    requestsData.email === detail.acceptedBy
  );

  
  const [images, setImages] = useState([]);
  const PayPalServerUrl = "http://localhost:3001";

  useEffect(() => {
    authAxios
      .get(`/request/${requestId}/images`)
      .then((response) => {
        
        if (response.status === 200) {
          setImages(response.data.images);
          console.log(response.data.images);
        } else {
          alert(JSON.stringify(response));
        }
      })
      .catch((err) => {
        if (sessionCheck) {
          navigate("/Login");
        }
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

 


  const buyWithCredit = () => {
    const deductionRequest = {
        userId: companyDetails.id,
        deductionAmount: detail.cost
    };

    authAxios
        .get(`https://api.easy-compare.chapi/getCreditAmount/${companyDetails.id}`)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.amount >= detail.cost) {
                    // User has sufficient balance, send a deduction request
                    authAxios
                        .post("https://api.easy-compare.chapi/deductCredit", deductionRequest)
                        .then((deductionResponse) => {
                            if (deductionResponse.status === 200) {
                                // Deduction successful
                                alert("Payment Successful");
                                setAccepted(true);
                                setOpen(false);


                                const transactionData = {
                                  companyId: companyDetails.id,
                                  dateTime: new Date().toLocaleString(),
                                  transactionType: "buycredit",
                                }
                        
                        
                                authAxios.post(`https://api.easy-compare.chtransactions/create`, transactionData, {
                                headers: { "Content-Type": "application/json" },
                              });
                            } else {
                                // Handle deduction failure
                                alert("Error deducting credit");
                            }
                        })
                        .catch((deductionError) => {
                          console.log("Error happened at ID *61239723");
                        });
                } else {
                    alert("Insufficient Balance!");
                }
            } else {
                alert("Error fetching amount info");
            }
        })
        .catch((err) => {
          console.log("Error happened at ID *12398734");
        });
};



const buyWithCard = () => {
  authAxios
    .get(`https://api.easy-compare.chapi/creditCardInfo/${companyDetails.id}`)
    .then((response) => {
      if (response.status === 200) {
        setCreditCardInfo(response.data);

        // Proceed with the payment using the fetched credit card info
        const paymentData = {
          cardNumber: response.data.fullCardNumber,
          cardHolder: response.data.cardHolderName,
          expiry: response.data.expiryDate,
          cvv: response.data.cvv,
          currency: "CHF",
          amount: detail.cost,
          cardType: response.data.cardType,
        };

        handlePayment(paymentData);
      } else {
        alert("Failed to fetch credit card info");
      }
    })
    .catch((err) => {
      console.log("Error happened at ID *623817398");
    });

  const handlePayment = async (paymentData) => {
    try {
      const response = await authAxios.post(
        `${PayPalServerUrl}/initiate-payment`,
        paymentData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const order = response.data;
      console.log(order);

      if (order.status === "CREATED") {
        setAccepted(true);
        setOpen(false);


        const transactionData = {
          companyId: companyDetails.id,
          dateTime: new Date().toLocaleString(),
          transactionType: "buycard",
        }


        authAxios.post(`https://api.easy-compare.chtransactions/create`, transactionData, {
        headers: { "Content-Type": "application/json" },
      });

        alert("The payment was successfull");
      } else {
        console.log("Payment initiation error. Status:", order.status);
        // You can handle other error cases here if needed
      }
    } catch (error) {
      console.log("Error happened at ID *3127398");
    }
  };
}


  

  return (
    <>
      <div className="request-details-card">
        <Button variant="contained" size="small">
          <Link to={"/account/requests/"}>« {t("b-t-o")}</Link>
        </Button>

        <Typography
          variant="h5"
          component="div"
          sx={{ pb: "16px", color: "white" }}
        >
          {detail.category.type} {t("request")} {requestId} for {name}
        </Typography>
        <Card sx={{ minWidth: 275, mb: "1rem" }}>
          <div className="card-header">
            <Typography variant="h6" component="div">
              {t("con")}
            </Typography>
          </div>
          <CardContent>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("name")}
              </Grid>
              <Grid item xs={9}>
                {accepted ? detail.user.lastname : "###############"}{" "}
                {accepted ? detail.user.firstname : "###############"}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("telephone")}
              </Grid>
              <Grid item xs={9}>
                {accepted ? detail.user.contactNo : "###############"}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Email
              </Grid>
              <Grid item xs={9}>
                {accepted ? detail.user.email : "###############"}
              </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
              {accepted ? (
                <Button
                  color={"success"}
                  variant="contained"
                  sx={{ alignSelf: "center" }}
                >
                  {t("purchased")}
                </Button>
              ) : (
                <Button
                  onClick={handleClickOpen}
                  disabled={accepted}
                  color={accepted ? "success" : "primary"}
                  variant="contained"
                  sx={{ alignSelf: "center" }}
                >
                  {accepted ? "Purchased" : "To the purchase condition"}
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, mb: "1rem" }}>
          <div className="card-header">
            <Typography variant="h6" component="div">
              {t("details-request")}:
            </Typography>
          </div>
          <CardContent>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("category")}
              </Grid>
              <Grid item xs={9}>
                {detail.category.type}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("con")} {t("request")}:
              </Grid>
              <Grid item xs={9}>
                {detail.offerType}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("date")}
              </Grid>
              <Grid item xs={9}>
                {detail.date}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("object")}
              </Grid>
              <Grid item xs={9}>
                {}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("no-room")}
              </Grid>
              <Grid item xs={9}>
                {detail.numberOfRooms}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("acceptance")}{" "}
              </Grid>
              <Grid item xs={9}>
                {}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("guarentee")}
              </Grid>
              <Grid item xs={9}>
                {}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("obj-area")}
              </Grid>
              <Grid item xs={9}>
                {detail.objectArea} m2
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("poll")}
              </Grid>
              <Grid item xs={9}>
                {detail.pollution}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("areas")}
              </Grid>
              <Grid item xs={9} sx={{ mb: "0.2rem" }}>
                {detail.areas.map((val, index) => {
                  return (
                    <>
                      <span
                        className=""
                        key={index}
                        style={{
                          background: colorsArray[index % 5],
                          padding: "1px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          color: "white",
                          marginLeft: "0px",
                        }}
                      >
                        {val}
                      </span>
                    </>
                  );
                })}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("extras")}
              </Grid>
              <Grid item xs={9}>
                {detail.extras.split(" ").map((val, index) => {
                  return val !== "" ? (
                    <span
                      className=""
                      key={index + "extras"}
                      style={{
                        background: colorsArray[index % 5],
                        padding: " 1px 8px",
                        borderRadius: "5px",
                        margin: "5px",
                        color: "white",
                        marginLeft: "0px",
                      }}
                    >
                      {val}
                    </span>
                  ) : (
                    <></>
                  );
                })}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("distance")}
              </Grid>
              <Grid item xs={9}>
                {detail.distance}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("focus")}
              </Grid>
              <Grid item xs={9}>
                {detail.focus}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("add-info")}
              </Grid>
              <Grid item xs={9}>
                {detail.description}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("discount")}
              </Grid>
              <Grid item xs={9}>
                {detail.discount}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                {t("address")}
              </Grid>
              <Grid item xs={9}>
                {detail.companyPlace} <br /> {detail.postalCode}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, mb: "1rem" }}>
          <div className="card-header">
            <Typography variant="h6" component="div">
              Pictures:
            </Typography>
          </div>
          <CardContent>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {images.length &&
                images.map((image, index) => (
                  <img
                    key={index}
                    src={`${image}`}
                    alt={image.name}
                    style={{ height: "300px" }}
                  />
                ))}
            </div>
          </CardContent>
        </Card>
      </div>
      <OfferDialogBox
        open={open}
        setOpen={setOpen}
        handleAccept={buyWithCredit}
        handleAcceptWithCard={buyWithCard}
      />
    </>
  );
};

export default RequestDetail;
