import React from "react";
import './index.css';
import MediaQuery from "react-responsive";

const Flex2fragments = (props) => {
   return <>


      {/* <div className={`info-card ${props.classes}`} style={{ flexDirection: props.flexReverse&&'row-reverse' }}>
         <img className="" src={props.img} alt="Carpenter Service" />
         <div className="info-card-info-container" >
            {props.children}
         </div>
      </div> */}

      <MediaQuery minWidth={1026}>
         <div className={`info-card ${props.classes}`} style={{ flexDirection: props.flexReverse&&'row-reverse' }}>
            <img className="" src={props.img} alt="Carpenter Service" />
            <div className="info-card-info-container" >
               {props.children}
            </div>
         </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
         <div className={`info-card-mobile`} style={{ flexDirection: props.flexReverse&&'row-reverse' }}>
            <img className="" src={props.img} alt="Carpenter Service" />
            <div className="info-card-info-container" >
               {props.children}
            </div>
         </div>
      </MediaQuery>

   </>
}

export default Flex2fragments;