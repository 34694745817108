import AccountHeader from "../AccountPage/AccountHeader";
import loginImage from '../../../images/home/home.png';
import { Routes, Route } from "react-router-dom";
import "../AccountPage/index.css";
import CompanyDetails from "./CompanyDetails";
import CompanyRequests from "./CompanyRequests";
import RequestDetails from "./RequestDetails";
import AdminMenu from "./AdminMenu";
import TopupRequests from "./TopupRequests";
const Admin = () => {
    return (
        <>
           <AccountHeader BalanceBtnVisibility={"none"}/>
           
           <div className="hero login-page accountPage" style={{ gap:"1rem" }}>
                <AdminMenu />
                <Routes>
                    <Route path="topuprequests" element={<TopupRequests />} />
                    <Route path="companydetails" element={<CompanyDetails />} />
                    <Route path="companydetails/companyrequests" element={<CompanyRequests />} />
                    <Route path="companydetails/companyrequests/requestdetails" element={<RequestDetails />} />
                </Routes>
            </div>
        </>
    );
}

export default Admin; 