import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Input,
  InputLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    display: "none",
  },
  avatar: {
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
}));

const Pictures = (props) => {
  const { t } = useTranslation();
  const [images, setImages] = useState(props.resultCleaning.images);
  const classes = useStyles();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const data = new FileReader();

    data.readAsDataURL(e.target.files[0]);
    data.onload = (e) => {
      setImages([...images, e.target.result]);
    };
  };
  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      images,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      images,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep + 1);
  };
  return (
    <>
      <div className="category-component">
        <div className="category-container">
          <span className="card-box" style={{ flexDirection: "column" }}>
            <Form.Group className="text-center picture-desc mb-1">

              <h2>{t("add-image")}</h2>
             
              {images.length ? (
                <List
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  {images.map((image, ind) => {
                    return (
                      <>
                        <ListItem key={ind}>
                          <ListItemAvatar key={"item" + ind}>
                            {/* show base64 to image  */}
                            <img
                              key={ind}
                              src={image}
                              alt={image.name}
                              className={classes.avatar}
                            />
                          </ListItemAvatar>
                          <IconButton
                            key={"icon" + ind}
                            onClick={() =>
                              setImages(images.filter((_, i) => i !== ind))
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              ) : null}
            </Form.Group>
          </span>

          
          <div className="parent-buttons">
            <span className="prevNext-btn card-box">
              <div className="childOneButton">
              <InputLabel htmlFor="images"> </InputLabel>
                <InputLabel htmlFor="images">
                  <Input
                    id="images"
                    name="images"
                    type="file"
                    multiple
                    className={classes.input}
                    onChange={handleImageChange}
                  />
                  <span
                    style={{
                      padding: "1rem 2rem",
                      color: "white",
                      background: "#D79B11",
                    }}
                  >
                    {t("browse")}
                  </span>
                  <></>
                  <></>
                </InputLabel>
              </div>
              
              <div className="childTwoButton">
                <Button variant="warning" onClick={fnClickPrev}>
                  {t("prev")}
                </Button>

                <Button variant="warning" onClick={fnClickNext}>
                  {t("next")}
                </Button>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Pictures;
