import "../../common/Hero.css";
import "./index.css";
import { emailRegex } from "../../../utils/utils";
import Navbar from "../../common/Navbar";
import Axios from "axios";
import homeImage from "../../../images/home/home.png";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useTranslation } from "react-i18next";

const UserRegistration = () => {
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();
  const [regDetails, setRegDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    contactNo: "",
    password: "",
    confirmpassword: "",
    address: {
      location: "",
      postCode: "",
      latitude: 1,
      longitude: 1,
    },
  });
  const [formErrors, setformErrors] = useState({
    firstname: "",
    lastname: "",
    contactNo: "",
    email: "",
    password: "",
    confirmpassword: "",
    postCode: "",
    address: "",
  });
  const formvalid = (formErrors) => {
    let valid = true;
    Object.values(formErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    // Object.values(rest).forEach(val =>{
    //   val === null && (valid = false);
    // });
    return valid;
  };

  const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formvalid(formErrors)) {
      Axios.post(REACT_APP_BACKEND_SERVER2 + "/register/user", regDetails).then(
        (response) => {
          if (response.data.response_code === "200") {
            alert(t("req-success"));
            navigate("/Login");
          } else {
            console.log("Smeth else happened at ID 0912832");
          }
        }
      );
    } else {
      console.error("Form Invalid - Display Error Message");
    }
  };
  const inputEvent = (e) => {
    //validations
    const { name, value } = e.target;
    setformErrors((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstname":
          stateObj[name] = value === "" ? t("enter-first") : "";
          break;
        case "lastname":
          stateObj[name] = value === "" ? t("enter-last") : "";
          break;
        case "email":
          stateObj[name] =
            emailRegex.test(value) && value.length > 0
              ? ""
              : t("invalid-email");
          break;
        case "contactNo":
          if (value === "") {
            stateObj[name] = t("enter-phone");
          } else if (value !== "") {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(value)) {
              stateObj[name] = t("enter-only-number");
            } else if (value.length !== 10) {
              stateObj[name] = t("enter-valid-phone");
            } else {
              stateObj[name] = "";
            }
          }
          break;
        case "password":
          stateObj[name] =
            value.length < 8 && value.length > 0 ? t("minimum-char") : "";
          setRegDetails((prevValues) => {
            return { ...prevValues, confirmpassword: "" };
          });
          break;
        case "confirmpassword":
          if (value === "") {
            stateObj[name] = t("confirm-pass");
          }
          stateObj[name] = regDetails.password !== value ? t("same-pass") : "";
          break;
        case "address":
          stateObj[name] = value === "" ? t("enter-address") : "";
          break;
        case "postCode":
          stateObj[name] = value === "" ? t("enter-post") : "";
          break;
        default:
          break;
      }

      return stateObj;
    });

    const inputName = e.target.name;

    if (inputName === "location" || inputName === "postCode") {
      setRegDetails((prevValues) => {
        return {
          ...prevValues,
          address: {
            ...prevValues.address,
            [e.target.name]: e.target.value,
          },
        };
      });
    } else {
      setRegDetails((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.value };
      });
    }
  };
  const renderErrorMessage = (name) => {
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );
  };

  // address geocode
  const [address, setAddress] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [zipcode, setZipcode] = useState();
  const [addressflag, setAddresflag] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = (val) => {
    setValue(val, false);
    setAddress(val);
    setAddresflag(false);
    const parameter = {
      address: val,
    };
    // console.log(val);

    getGeocode(parameter)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLat(latLng.lat);
        setLng(latLng.lng);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    getGeocode(parameter)
      .then((results) => getZipCode(results[0], false))
      .then((zipCode) => {
        setZipcode(zipCode);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    getGeocode(parameter)
      .then((results) =>
        getDetails({ placeId: results[0].place_id, fields: ["name", "rating"] })
      )
      .then((details) => {
        // console.log("Details: ", details);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    setRegDetails((prevValues) => {
      return {
        ...prevValues,
        address: {
          ...prevValues.address,
          ["location"]: address,
          ["longitude"]: lng,
          ["latitude"]: lat,
          // ,["postCode"]: zipcode
        },
      };
    });
  };
  return (
    <>
      <Navbar menuVisibility={"hidden"} signUpVisibility={"none"} />
      <div
        className="hero reg-page"
        style={{ backgroundImage: `url(${homeImage})` }}
      >
        <div style={{ visibility: "hidden" }}>Registration page</div>
        <form onSubmit={handleSubmit}>
          <div className={"reg-title"}>Registration page</div>
          <div className="reg-card">
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="firstname"
                label={t("first-name")}
                onChange={inputEvent}
                value={regDetails.firstname}
              />
              {formErrors.firstname && (
                <span className="error">{formErrors.firstname}</span>
              )}
            </div>
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="lastname"
                label={t("last-name")}
                onChange={inputEvent}
                value={regDetails.lastname}
              />
              {formErrors.lastname && (
                <span className="error">{formErrors.lastname}</span>
              )}
            </div>
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="email"
                label="EMAIL"
                onChange={inputEvent}
                value={regDetails.email}
              />
              {formErrors.email && (
                <span className="error">{formErrors.email}</span>
              )}
            </div>
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="contactNo"
                label={t("contact")}
                onChange={inputEvent}
                value={regDetails.contactNo}
              />
              {formErrors.contactNo && (
                <span className="error">{formErrors.contactNo}</span>
              )}
            </div>
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                name="password"
                label={t("password")}
                onChange={inputEvent}
                value={regDetails.password}
              />
              {formErrors.password && (
                <span className="error">{formErrors.password}</span>
              )}
            </div>
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                name="confirmpassword"
                label={t("con-pass")}
                onChange={inputEvent}
                value={regDetails.confirmpassword}
              />
              {formErrors.confirmpassword && (
                <span className="error">{formErrors.confirmpassword}</span>
              )}
            </div>
            <div className="address-div">
              <Combobox onSelect={handleSelect} aria-labelledby="demo">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="location"
                  label={t("select-address")}
                  autoFocus
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  className={
                    addressflag === true
                      ? "border border-danger w-100"
                      : "w-100"
                  }
                />
                <ComboboxInput
                  style={{ visiblity: "hidden" }}
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  className={
                    addressflag === true
                      ? "border border-danger w-100"
                      : "w-100"
                  }
                />

                <ComboboxPopover>
                  <ComboboxList>
                    {status === "OK" &&
                      data.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} />
                      ))}
                  </ComboboxList>
                </ComboboxPopover>
              </Combobox>
              {formErrors.address && (
                <span className="error">{formErrors.address}</span>
              )}
            </div>
            <div>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="postCode"
                label={t("postcode")}
                onChange={inputEvent}
                value={regDetails.postalCode}
              />
              {formErrors.postCode && (
                <span className="error">{formErrors.postCode}</span>
              )}
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("submit")}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserRegistration;
