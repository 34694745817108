import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../common/Hero.css';
import '../LoginPage/Login.css';
import loginImage from '../../images/home/home.png';
import { useNavigate } from "react-router-dom";
import Navbar from "../common/Navbar";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';

export default function Login() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});

    const inputEvent = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setNewPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!newPassword) errors.password = t('Password is required');
        if (newPassword !== confirmPassword) errors.confirmPassword = t('Passwords do not match');
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to set the token from the URL parameter
    const setTokenFromURL = () => {
        // Extract token from the URL (implement this logic based on your application's routing)
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromURL = urlParams.get('token');
        if (tokenFromURL) {
            setToken(tokenFromURL);
        } else {
            console.error("Token not found in URL");
        }
    };

    // Call setTokenFromURL function when component mounts
    useEffect(() => {
        setTokenFromURL();
    }, []);

    // Function to handle password reset confirmation
    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const response = await axios.post(`https://api.easy-compare.ch/resetPassword/confirm?token=${token}`, {
                newPassword: newPassword
            });

            if (response.status === 200) {
                alert(response.data);
                navigate("/");
            } else {
                console.error('Error:', response.data);
                alert("Failed to reset password. Please try again.");
            }
        } catch (error) {
            console.error('Error:', error);
            alert("Failed to reset password. Please try again.");
        }
    };

    return (
        <>
            <Navbar menuVisibility={"hidden"} />
            <div className="hero login-page" style={{ backgroundImage: `url(${loginImage})` }}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box style={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('Password-Reset')}
                        </Typography>
                        <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 1, bg: "white" }}>
                            <TextField margin="normal" required fullWidth name="password" label={t('Password')} type="password" id="password" onChange={inputEvent} autoComplete="current-password" />
                            {formErrors.password && <span className='error'>{formErrors.password}</span>}
                            <TextField margin="normal" required fullWidth name="confirmPassword" label={t('Confirm Password')} type="password" id="confirmPassword" onChange={inputEvent} autoComplete="current-password" />
                            {formErrors.confirmPassword && <span className='error'>{formErrors.confirmPassword}</span>}
                            
                            <button className="mui-button-clone" type="submit">
                                {t('Reset-Password')}
                            </button>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    );
}
