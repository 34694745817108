import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { getAuthAxios, colorsArray, sessionCheck } from "../../../utils/utils";
import "../Requests/index.css";
import Grid from "@mui/material/Grid";
import Loader from "../../common/Loader";
import { useState, useEffect } from "react";
import OfferDialogBox from "../../common/OfferDialogBox";

const RequestDetails = () => {
  const requestsData = useSelector((state) => state.companyReducer);
  const detail = useSelector((state) => state.adminReducer.selectedRequest);
  const requestId = detail.id;
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(
    requestsData.email === detail.acceptedBy
  );
  const [images, setImages] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get(`/request/${requestId}/images`)
      .then((response) => {
        if (response.status === 200) {
          setImages(response.data.images);
          console.log(response.data.images);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (sessionCheck) {
          navigate("/Login");
        }
      });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAccept = () => {
    setIsLoading(true);
    authAxios
      .post("/admin/company/acceptRequest", {
        requestId: requestId,
        companyEmail: requestsData.email,
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.response_code === "400") {
            setIsLoading(false);
            alert("Insufficient Balance");
          } else {
            setAccepted(true);
            setOpen(false);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("catch block " + err);
        alert(err.response.data);
      });
  };
  

  return (
    <>
      <div className="request-details-card">
        <Button variant="contained" size="small">
          <Link to={"/admin/companydetails/companyrequests"}>
            « Back to overview
          </Link>
        </Button>

        <Typography
          variant="h5"
          component="div"
          sx={{ pb: "16px", color: "white" }}
        >
          {detail.category.type} request {requestId} for {requestsData.name}
        </Typography>
        <Card sx={{ minWidth: 275, mb: "1rem" }}>
          <div className="card-header">
            <Typography variant="h6" component="div">
              Contact Details:
            </Typography>
          </div>
          <CardContent>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Name
              </Grid>
              <Grid item xs={9}>
                {accepted ? detail.user.lastname : "###############"}{" "}
                {accepted ? detail.user.firstname : "###############"}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Telephone
              </Grid>
              <Grid item xs={9}>
                {accepted ? detail.user.contactNo : "###############"}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Email
              </Grid>
              <Grid item xs={9}>
                {accepted ? detail.user.email : "###############"}
              </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
              {accepted ? (
                <Button
                  color={"success"}
                  variant="contained"
                  sx={{ alignSelf: "center" }}
                >
                  Purchased
                </Button>
              ) : (
                <Button
                  onClick={handleClickOpen}
                  disabled={accepted}
                  color={accepted ? "success" : "primary"}
                  variant="contained"
                  sx={{ alignSelf: "center" }}
                >
                  {accepted ? "Purchased" : "To the purchase condition"}
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, mb: "1rem" }}>
          <div className="card-header">
            <Typography variant="h6" component="div">
              Details of the request:
            </Typography>
          </div>
          <CardContent>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Category
              </Grid>
              <Grid item xs={9}>
                {detail.category.type}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Contact request:
              </Grid>
              <Grid item xs={9}>
                {detail.offerType}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Date
              </Grid>
              <Grid item xs={9}>
                {detail.date}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Object
              </Grid>
              <Grid item xs={9}>
                {}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }} >
                Number of rooms
              </Grid>
              <Grid item xs={9}>
                {detail.numberOfRooms}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Acceptance{" "}
              </Grid>
              <Grid item xs={9}>
                {}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                guarantee
              </Grid>
              <Grid item xs={9}>
                {}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Object area
              </Grid>
              <Grid item xs={9}>
                {detail.objectArea} m2
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Pollution
              </Grid>
              <Grid item xs={9}>
                {detail.pollution}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Areas
              </Grid>
              <Grid item xs={9} sx={{ mb: "0.2rem" }}>
                {detail.areas.map((val, index) => {
                  return (
                    <>
                      <span
                        className=""
                        key={index}
                        style={{
                          background: colorsArray[index % 5],
                          padding: "1px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          color: "white",
                          marginLeft: "0px",
                        }}
                      >
                        {val}
                      </span>
                    </>
                  );
                })}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Extras
              </Grid>
              <Grid item xs={9}>
                {detail.extras.split(" ").map((val, index) => {
                  return val !== "" ? (
                    <span
                      className=""
                      key={index}
                      style={{
                        background: colorsArray[index % 5],
                        padding: "1px 8px",
                        borderRadius: "5px",
                        margin: "5px",
                        color: "white",
                        marginLeft: "0px",
                      }}
                    >
                      {val}
                    </span>
                  ) : (
                    (isLoading &&  <Loader /> )
                  );
                })}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Distance
              </Grid>
              <Grid item xs={9}>
                {detail.distance}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Focus
              </Grid>
              <Grid item xs={9}>
                {detail.focus}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Additional information
              </Grid>
              <Grid item xs={9}>
                {detail.description}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Discount
              </Grid>
              <Grid item xs={9}>
                {detail.discount}
              </Grid>

              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Address
              </Grid>
              <Grid item xs={9}>
                {detail.companyPlace} <br /> {detail.postalCode}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, mb: "1rem" }}>
          <div className="card-header">
            <Typography variant="h6" component="div">
              Pictures:
            </Typography>
          </div>
          <CardContent>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {images.length &&
                images.map((image, index) => (
                  <img
                    key={index}
                    src={`${image}`}
                    alt={image.name}
                    style={{ height: "300px" }}
                  />
                ))}
            </div>
          </CardContent>
        </Card>
      </div>
      <OfferDialogBox
        open={open}
        setOpen={setOpen}
        handleAccept={handleAccept}
      />
    </>
  );
};

export default RequestDetails;
