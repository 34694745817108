import AvatarUpload from "./AvatarUpload";
import { Link, Routes, Route } from "react-router-dom"
import { useSelector } from "react-redux";
import { colorsArray } from "../../../utils/utils";
import './index.css';
import { TbMail } from "react-icons/tb";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { MdConfirmationNumber } from "react-icons/md";
import { MdOutlineReviews } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import EditProfile from "./EditProfile";
import ProfileGallery from "./ProfileGallery";
import ProfileMenu from "./ProfileMenu";
import ProfileReviews from "./ProfileReviews";
import Notification from "./Notification";
import Speciality from "./Speciality";
import ProfileChangePassword from "./ProfileChangePassword";
import { Card } from "@mui/material";


const ProfileDetails = () => {
   const requestsData = useSelector(state => state.companyReducer);
   return <>
      <Card>
         
            <div className="Profile-container">
               <AvatarUpload imageProfile={requestsData.profile}/>
               {1 ?
                  (
                     <>
                        <div className="profile-section">
                           <div className="profile-details">
                              <div className="company-detailss">
                                 <div className="company-name mb-1"> {requestsData.companyName}
                                 </div>
                                 <div className="company-address mb-1">
                                    <HiOutlineLocationMarker /> {requestsData.companyPlace}, {requestsData.postalCode}
                                 </div>
                                 <div className="company-contactNumber mb-1">
                                    <BsTelephone /> {requestsData.telefonNumber}
                                 </div>
                                 <div className="company-email mb-1">
                                    <TbMail /> {requestsData.companyEmail}
                                 </div>
                                 <div className="company-website mb-1">
                                    <CgWebsite /> {requestsData.website}
                                 </div>
                                 <div className="company-commercialRegister mb-1">
                                    <MdConfirmationNumber /> {requestsData.companyIdNumber}
                                 </div>
                                 <div className="company-reviews mb-1">
                                    <MdOutlineReviews /> {requestsData.reviews[0]}
                                 </div>
                              </div>
                              <div className="company-services">
                                 <div className="Public-profile">
                                    <a href=""><CgProfile /> Show Public Profile</a>
                                 </div>
                                 <div className="cmp-services ">
                                    <span className=""> Expertise </span>
                                    <div className="mt-1">
                                       {requestsData.expertise.map((val, index) => {
                                          return <>
                                             <span className="services" key={index} style={{ background: colorsArray[index % 5] }} >
                                                {val}
                                             </span>
                                          </>
                                       }
                                       )}
                                    </div>
                                 </div>

                                 <div className="cmp-categories ">
                                    <span className=""> Categories </span>
                                    <div className="mt-1">
                                       {requestsData.categories.map((val, index) => {
                                          return <>
                                             <span className="category" key={index} style={{ background: colorsArray[index % 5] }} >
                                                {val}
                                             </span>
                                          </>
                                       }
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        {/* <div className="map-area">

                        </div> */}
                     </>


                     

                     
                  )
                  : "No data"
               }
            </div>
         
      </Card>
      <ProfileMenu />
      <Routes>
         <Route path="/" element={<EditProfile />} />
         <Route path="profilegallery" element={<ProfileGallery />} />
         <Route path="profilereviews" element={<ProfileReviews />} />
         <Route path="profilespeciality" element={<Speciality />} />
         <Route path="profilenotification" element={<Notification />} />
         <Route path="profilechangepassword" element={<ProfileChangePassword />} />
      </Routes>
   </>
}



export default ProfileDetails;

