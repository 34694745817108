
import UserAccountMenu from "./UserAccountMenu";
import AccountHeader from "../../Account/AccountPage/AccountHeader";
import loginImage from '../../../images/home/home.png';
import { Routes, Route } from "react-router-dom";
import Requests from "./Requests";
import UserProfile from './Profile';
import ServiceRequest from "../ServiceRequest";
import "./index.css";
const UserAccount = () => {
    return (
        <>
           <AccountHeader BalanceBtnVisibility={"none"}/>
           <div className="hero login-page accountPage" style={{ gap:"1rem" }}>
                <UserAccountMenu />
                <Routes>
                    <Route path="requests" element={<Requests />} />
                    <Route path="userprofile" element={<UserProfile />} />
                    <Route path="newrequest" element={<ServiceRequest />} />
                </Routes>
            </div>
        </> 
    );
}

export default UserAccount;