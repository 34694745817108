import React from 'react';
import './AdvCardComponent.css';
import tickmarkImg from '../images/tickmarkImg.svg'

const AdvCardComponent = (props) => {
   return <>
      <div className="AdvCard-service-model">
         <div className="AdvCard-service-model-head">
            <img className="tickmarkImg" src={tickmarkImg} alt="tickmarkImg" />
         </div>
         <div className="AdvCardContent">
            {props.content}
         </div>
      </div>
   </>
}


export default AdvCardComponent;