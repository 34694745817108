import React from "react";
import './common.css';

export default function Loader() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      <span>Loading... Please Wait...</span>
    </div>
  );
}