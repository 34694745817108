import React, { useState } from "react";
import axios from "axios";
import "./PayPalIntegrationAdvanced.css";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";

function detectCardType(cardNumber) {
  // Remove all spaces from the card number
  const cardNumberWithoutSpaces = cardNumber.replace(/\s/g, "");

  // Define regular expressions for common card types
  const cardTypes = [
    { type: "visa", pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },
    { type: "mastercard", pattern: /^5[1-5][0-9]{14}$/ },
    { type: "amex", pattern: /^3[47][0-9]{13}$/ },
    { type: "discover", pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/ },
    // Add more card types and patterns as needed
  ];

  // Use regular expressions to identify the card type
  for (const cardType of cardTypes) {
    if (cardType.pattern.test(cardNumberWithoutSpaces)) {
      return cardType.type;
    }
  }

  return "unknown";
}

function formatCreditCardNumber(e) {
  const input = e.target;
  const numericValue = input.value.replace(/\D/g, "");
  const formattedValue = numericValue.replace(/(\d{4})(?=\d)/g, "$1 ");
  input.value = formattedValue;
}

function formatExpiryDate(e) {
  const input = e.target;
  let value = input.value.replace(/\D/g, "");

  if (value.length > 2) {
    value = value.slice(0, 2) + " / " + value.slice(2);
  }

  input.value = value;
}

function PayPalAdvDeposit() {
  const companyDetails = useSelector((state) => state.companyReducer);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCVV] = useState("");
  const [currency, setCurrency] = useState("CHF");
  const [amount, setAmount] = useState(10.0);

  const PayPalServerUrl = "http://localhost:3001";

  const handlePayment = async () => {
    const cardType = detectCardType(cardNumber);
    const paymentData = {
      cardNumber: cardNumber,
      cardHolder: cardHolder,
      expiry: expiry,
      cvv: cvv,
      currency: currency,
      amount: amount,
      cardType: cardType,
    };
    console.log(expiry);

    try {
      const response = await axios.post(
        `${PayPalServerUrl}/initiate-payment`,
        paymentData,
        {
          headers: { "Content-Type": "application/json" },
        },
      );
      const order = response.data;
      console.log(order);

      if (order.status === "CREATED") {
        // Step 2: Send the amount to your Spring Boot server
        const amountData = {
          amount: amount, // Add the amount field
          userId: companyDetails.id,
        };

        await axios.post(
          `https://api.easy-compare.chapi/updateCreditAmount`,
          amountData,
          {
            headers: { "Content-Type": "application/json" },
          },
        );

        // Step 3: Create the transaction

        const transactionData = {
          companyId: companyDetails.id,
          dateTime: new Date().toLocaleString(),
          transactionType: "deposit",
        };

        await axios.post(
          `https://api.easy-compare.chtransactions/create`,
          transactionData,
          {
            headers: { "Content-Type": "application/json" },
          },
        );

        window.location.reload();
      } else {
        console.log("Payment initiation error. Status:", order.status);
        // You can handle other error cases here if needed
      }

      //   window.location.href = order.redirectUrl;
    } catch (error) {
      console.error("Payment initiation error:", error);
    }
  };

  return (
    <>
      <MediaQuery minWidth={1026}>
        <div className="payment-container">
          <div className="top-row">
            <div className="twoby2-1">
              <label htmlFor="ccn">Credit Card Number:</label>
              <input
                className="inputs"
                id="ccn"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength="19"
                placeholder="xxxx xxxx xxxx xxxx"
                value={cardNumber}
                onChange={(e) => {
                  formatCreditCardNumber(e);
                  setCardNumber(e.target.value);
                }}
              />
            </div>
            <div className="twoby2-2">
              <label htmlFor="cardowner">Card Holder</label>
              <input
                id="cardowner"
                type="text"
                className="inputs"
                value={cardHolder}
                onChange={(e) => setCardHolder(e.target.value)}
              />
            </div>
          </div>

          <div className="bottom-row">
            <div className="twoby2-3">
              <label htmlFor="expiry-date">Expiry Date:</label>
              <input
                id="expiry-date"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{7}"
                maxLength="7"
                value={expiry}
                onChange={(e) => {
                  formatExpiryDate(e);
                  setExpiry(e.target.value);
                }}
                className="inputs"
                placeholder="MM / YY"
              />
            </div>
            <div className="twoby2-4">
              <label htmlFor="cvv">CVV:</label>
              <input
                className="inputs"
                id="cvv"
                type="tel"
                inputMode="numeric"
                pattern="[0-9]{2,3}"
                autoComplete="cc-csc"
                maxLength="3"
                placeholder="123"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
              />
            </div>
            {/* implement the credit sytem and check the notes in the text editor opened below */}

            <div className="twoby2-5">
              <label htmlFor="amount">Amount:</label>
              <input
                id="amount"
                type="number"
                min="0.01"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                className="inputs"
              />
            </div>
          </div>

          <label htmlFor="currency">Currency:</label>
          <select
            id="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="inputs"
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="CHF">CHF</option>
          </select>

          <button className="buybtn" onClick={handlePayment}>
            Deposit Cash
          </button>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
        <div className="payment-container-mobile">
            <div className="twoby2-1">
              <label htmlFor="ccn">Credit Card Number:</label>
              <input
                className="inputs"
                id="ccn"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength="19"
                placeholder="xxxx xxxx xxxx xxxx"
                value={cardNumber}
                onChange={(e) => {
                  formatCreditCardNumber(e);
                  setCardNumber(e.target.value);
                }}
              />
            </div>
            <div className="twoby2-2">
              <label htmlFor="cardowner">Card Holder</label>
              <input
                id="cardowner"
                type="text"
                className="inputs"
                value={cardHolder}
                onChange={(e) => setCardHolder(e.target.value)}
              />
            </div>

            <div className="twoby2-3">
              <label htmlFor="expiry-date">Expiry Date:</label>
              <input
                id="expiry-date"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{7}"
                maxLength="7"
                value={expiry}
                onChange={(e) => {
                  formatExpiryDate(e);
                  setExpiry(e.target.value);
                }}
                className="inputs"
                placeholder="MM / YY"
              />
            </div>
            <div className="twoby2-4">
              <label htmlFor="cvv">CVV:</label>
              <input
                className="inputs"
                id="cvv"
                type="tel"
                inputMode="numeric"
                pattern="[0-9]{2,3}"
                autoComplete="cc-csc"
                maxLength="3"
                placeholder="123"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
              />
            </div>
            {/* implement the credit sytem and check the notes in the text editor opened below */}

            <div className="twoby2-5">
              <label htmlFor="amount">Amount:</label>
              <input
                id="amount"
                type="number"
                min="0.01"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                className="inputs"
              />
            </div>

          <label htmlFor="currency">Currency:</label>
          <select
            id="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="inputs"
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="CHF">CHF</option>
          </select>

          <button className="buybtn" onClick={handlePayment}>
            Deposit Cash
          </button>
        </div>
      </MediaQuery>
    </>
  );
}

export default PayPalAdvDeposit;
