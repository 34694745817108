import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./TipsTricks.css";
import { useTranslation } from "react-i18next";
import SaveIcon from "../../images/arrow.png";
import PrintIcon from "../../images/printer.png";
import GoBackIcon from "../../images/left-arrow-in-circular-button-black-symbol.png";
import ChecklistIcon from "../../images/pdf.png";
import TipsIcon from "../../images/pdf.png";

import InteriorDesignChecklist_EN from "../../pdfs/ConstructionAndRenovationChecklist/InteriorDesign/English/InteriorDesignChecklist.pdf";
import InteriorDesignChecklist_DE from "../../pdfs/ConstructionAndRenovationChecklist/InteriorDesign/German/InteriorDesignChecklist.pdf";
import InteriorDesignChecklist_FR from "../../pdfs/ConstructionAndRenovationChecklist/InteriorDesign/French/InteriorDesignChecklist.pdf";
import InteriorDesignChecklist_IT from "../../pdfs/ConstructionAndRenovationChecklist/InteriorDesign/Italian/InteriorDesignChecklist.pdf";

import KitchenBuildingChecklist_EN from "../../pdfs/ConstructionAndRenovationChecklist/Kitchen/English/KitchenChecklist.pdf";
import KitchenBuildingChecklist_DE from "../../pdfs/ConstructionAndRenovationChecklist/Kitchen/German/KitchenChecklist.pdf";
import KitchenBuildingChecklist_FR from "../../pdfs/ConstructionAndRenovationChecklist/Kitchen/French/KitchenChecklist.pdf";
import KitchenBuildingChecklist_IT from "../../pdfs/ConstructionAndRenovationChecklist/Kitchen/Italian/KitchenChecklist.pdf";

import BathroomBuilding_EN from "../../pdfs/ConstructionAndRenovationChecklist/Bathroom/English/BathroomChecklist.pdf";
import BathroomBuilding_DE from "../../pdfs/ConstructionAndRenovationChecklist/Bathroom/German/BathroomChecklist.pdf";
import BathroomBuilding_FR from "../../pdfs/ConstructionAndRenovationChecklist/Bathroom/French/BathroomChecklist.pdf";
import BathroomBuilding_IT from "../../pdfs/ConstructionAndRenovationChecklist/Bathroom/Italian/BathroomChecklist.pdf";

import WindowsAndDoors_EN from "../../pdfs/ConstructionAndRenovationChecklist/WindowsAndDoors/English/WindowsAndDoorsChecklist.pdf";
import WindowsAndDoors_DE from "../../pdfs/ConstructionAndRenovationChecklist/WindowsAndDoors/German/WindowsAndDoorsChecklist.pdf";
import WindowsAndDoors_FR from "../../pdfs/ConstructionAndRenovationChecklist/WindowsAndDoors/French/WindowsAndDoorsChecklist.pdf";
import WindowsAndDoors_IT from "../../pdfs/ConstructionAndRenovationChecklist/WindowsAndDoors/Italian/WindowsAndDoorsChecklist.pdf";

import FloorCoverings_EN from "../../pdfs/ConstructionAndRenovationChecklist/FloorCoverings/English/FlooringChecklist.pdf";
import FloorCoverings_DE from "../../pdfs/ConstructionAndRenovationChecklist/FloorCoverings/German/FlooringChecklist.pdf";
import FloorCoverings_FR from "../../pdfs/ConstructionAndRenovationChecklist/FloorCoverings/French/FlooringChecklist.pdf";
import FloorCoverings_IT from "../../pdfs/ConstructionAndRenovationChecklist/FloorCoverings/Italian/FlooringChecklist.pdf";

import FurnitureDesign_EN from "../../pdfs/ConstructionAndRenovationChecklist/FurnitureDesign/English/FurnitureDesignChecklist.pdf";
import FurnitureDesign_DE from "../../pdfs/ConstructionAndRenovationChecklist/FurnitureDesign/German/FurnitureDesignChecklist.pdf";
import FurnitureDesign_FR from "../../pdfs/ConstructionAndRenovationChecklist/FurnitureDesign/French/FurnitureDesignChecklist.pdf";
import FurnitureDesign_IT from "../../pdfs/ConstructionAndRenovationChecklist/FurnitureDesign/Italian/FurnitureDesignChecklist.pdf";

import InteriorDesignTips_EN from "../../pdfs/ConstructionAndRenovationTips/InteriorDesign/English/InteriorDesign.pdf";
import InteriorDesignTips_DE from "../../pdfs/ConstructionAndRenovationTips/InteriorDesign/German/InteriorDesign.pdf";
import InteriorDesignTips_FR from "../../pdfs/ConstructionAndRenovationTips/InteriorDesign/French/InteriorDesign.pdf";
import InteriorDesignTips_IT from "../../pdfs/ConstructionAndRenovationTips/InteriorDesign/Italian/InteriorDesign.pdf";

import KitchenBuildingTips_EN from "../../pdfs/ConstructionAndRenovationTips/Kitchen/English/KitchenTips.pdf";
import KitchenBuildingTips_DE from "../../pdfs/ConstructionAndRenovationTips/Kitchen/German/KitchenTips.pdf";
import KitchenBuildingTips_FR from "../../pdfs/ConstructionAndRenovationTips/Kitchen/French/KitchenTips.pdf";
import KitchenBuildingTips_IT from "../../pdfs/ConstructionAndRenovationTips/Kitchen/Italian/KitchenTips.pdf";

import BathroomBuildingTips_EN from "../../pdfs/ConstructionAndRenovationTips/Bathroom/English/BathroomTips.pdf";
import BathroomBuildingTips_DE from "../../pdfs/ConstructionAndRenovationTips/Bathroom/German/BathroomTips.pdf";
import BathroomBuildingTips_FR from "../../pdfs/ConstructionAndRenovationTips/Bathroom/French/BathroomTips.pdf";
import BathroomBuildingTips_IT from "../../pdfs/ConstructionAndRenovationTips/Bathroom/Italian/BathroomTips.pdf";

import WindowsAndDoorsTips_EN from "../../pdfs/ConstructionAndRenovationTips/WindowsAndDoors/English/WindowsAndDoor.pdf";
import WindowsAndDoorsTips_DE from "../../pdfs/ConstructionAndRenovationTips/WindowsAndDoors/German/WindowsAndDoor.pdf";
import WindowsAndDoorsTips_FR from "../../pdfs/ConstructionAndRenovationTips/WindowsAndDoors/French/WindowsAndDoor.pdf";
import WindowsAndDoorsTips_IT from "../../pdfs/ConstructionAndRenovationTips/WindowsAndDoors/Italian/WindowsAndDoor.pdf";

import FloorCoveringsTips_EN from "../../pdfs/ConstructionAndRenovationTips/FloorCoverings/English/FloorCoverings.pdf";
import FloorCoveringsTips_DE from "../../pdfs/ConstructionAndRenovationTips/FloorCoverings/German/FloorCoverings.pdf";
import FloorCoveringsTips_FR from "../../pdfs/ConstructionAndRenovationTips/FloorCoverings/French/FloorCoverings.pdf";
import FloorCoveringsTips_IT from "../../pdfs/ConstructionAndRenovationTips/FloorCoverings/Italian/FloorCoverings.pdf";

import FurnitureDesignTips_EN from "../../pdfs/ConstructionAndRenovationTips/FurnitureDesign/English/FurnitureDesign.pdf";
import FurnitureDesignTips_DE from "../../pdfs/ConstructionAndRenovationTips/FurnitureDesign/German/FurnitureDesign.pdf";
import FurnitureDesignTips_FR from "../../pdfs/ConstructionAndRenovationTips/FurnitureDesign/French/FurnitureDesign.pdf";
import FurnitureDesignTips_IT from "../../pdfs/ConstructionAndRenovationTips/FurnitureDesign/Italian/FurnitureDesign.pdf";

// Set the worker source for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = () => {
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = (event) => {
      if (selectedPdfIndex !== null) {
        event.preventDefault();
      }
    };
    document.body.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPdfIndex]);

  const langToPdfMap = {
    en: [
      InteriorDesignChecklist_EN,
      KitchenBuildingChecklist_EN,
      BathroomBuilding_EN,
      WindowsAndDoors_EN,
      FloorCoverings_EN,
      FurnitureDesign_EN,
      InteriorDesignTips_EN,
      KitchenBuildingTips_EN,
      BathroomBuildingTips_EN,
      WindowsAndDoorsTips_EN,
      FloorCoveringsTips_EN,
      FurnitureDesignTips_EN,
    ],
    de: [
      InteriorDesignChecklist_DE,
      KitchenBuildingChecklist_DE,
      BathroomBuilding_DE,
      WindowsAndDoors_DE,
      FloorCoverings_DE,
      FurnitureDesign_DE,
      InteriorDesignTips_DE,
      KitchenBuildingTips_DE,
      BathroomBuildingTips_DE,
      WindowsAndDoorsTips_DE,
      FloorCoveringsTips_DE,
      FurnitureDesignTips_DE,
    ],
    fr: [
      InteriorDesignChecklist_FR,
      KitchenBuildingChecklist_FR,
      BathroomBuilding_FR,
      WindowsAndDoors_FR,
      FloorCoverings_FR,
      FurnitureDesign_FR,
      InteriorDesignTips_FR,
      KitchenBuildingTips_FR,
      BathroomBuildingTips_FR,
      WindowsAndDoorsTips_FR,
      FloorCoveringsTips_FR,
      FurnitureDesignTips_FR,
    ],
    it: [
      InteriorDesignChecklist_IT,
      KitchenBuildingChecklist_IT,
      BathroomBuilding_IT,
      WindowsAndDoors_IT,
      FloorCoverings_IT,
      FurnitureDesign_IT,
      InteriorDesignTips_IT,
      KitchenBuildingTips_IT,
      BathroomBuildingTips_IT,
      WindowsAndDoorsTips_IT,
      FloorCoveringsTips_IT,
      FurnitureDesignTips_IT,
    ],
  };

  const langToPdfFileNameMap = {
    en: [
      "Interior Design Checklist",
      "Kitchen Building Checklist",
      "Bathroom Building Checklist",
      "Windows & Doors Checklist",
      "Floor Coverings Checklist",
      "Furniture Design Checklist",
      "Interior Design Tips",
      "Kitchen Building Tips",
      "Bathroom Building Tips",
      "Windows & Doors Tips",
      "Floor Coverings Tips",
      "Furniture Design Tips",
    ],
    de: [
      "Inneneinrichtung Checkliste",
      "Küchenbau Checkliste",
      "Badezimmerbau Checkliste",
      "Fenster & Türen Checkliste",
      "Bodenbeläge Checkliste",
      "Möbeldesign Checkliste",
      "Inneneinrichtung Tipps",
      "Küchenbau Tipps",
      "Badezimmerbau Tipps",
      "Fenster & Türen Tipps",
      "Bodenbeläge Tipps",
      "Möbeldesign Tipps",
    ],
    fr: [
      "Checklist de Design d'Intérieur",
      "Checklist de Construction de Cuisine",
      "Checklist de Construction de Salle de Bain",
      "Checklist des Fenêtres & Portes",
      "Checklist des Revêtements de Sol",
      "Checklist de Conception de Mobilier",
      "Conseils pour le Design d'Intérieur",
      "Conseils pour la Construction de Cuisine",
      "Conseils pour la Construction de Salle de Bain",
      "Conseils pour les Fenêtres & Portes",
      "Conseils pour les Revêtements de Sol",
      "Conseils pour la Conception de Mobilier",
    ],
    it: [
      "Checklist di Design Interno",
      "Checklist di Costruzione della Cucina",
      "Checklist di Costruzione del Bagno",
      "Checklist Finestre & Porte",
      "Checklist Rivestimenti Pavimento",
      "Checklist Design Mobili",
      "Consigli per Design Interno",
      "Consigli per Costruzione della Cucina",
      "Consigli per Costruzione del Bagno",
      "Consigli per Finestre & Porte",
      "Consigli per Rivestimenti Pavimento",
      "Consigli per Design Mobili",
    ],
  };

  const handleThumbnailClick = (index) => {
    setSelectedPdfIndex(index);
    document.body.style.overflow = "hidden";
  };

  const handleCloseFocus = () => {
    setSelectedPdfIndex(null);
    document.body.style.overflow = "";
  };

  const handleSave = () => {
    const link = document.createElement("a");
    link.href = langToPdfMap[currentlang][selectedPdfIndex];
    link.download = langToPdfFileNameMap[currentlang][selectedPdfIndex];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenInNewTab = () => {
    const newWindow = window.open();
    newWindow.document.write(`
      <iframe src="${langToPdfMap[currentlang][selectedPdfIndex]}" width="100%" height="100%"></iframe>
    `);
  };

  const currentlang = window.localStorage.i18nextLng;

  return (
    <>
      <div className="parent-checklist">
        {/* Checklists */}
        <div className="checklist-root">
          <div className="h22">
            <h2>{t("ConstructChecklist")}</h2>
          </div>
          <div className="pdf-thumbnails-carpenter">
            {langToPdfFileNameMap[currentlang]
              .slice(0, 6) // Select the first 6 checklist items
              .map((fileName, index) => (
                <div
                  key={index}
                  className="pdf-thumbnail-container"
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    className="thumbnail"
                    src={ChecklistIcon}
                    alt={`PDF ${index + 1}`}
                  />
                  <p>{fileName}</p>
                </div>
              ))}
          </div>
        </div>
  
        {/* Tips */}
        <div className="checklist-root">
          <div className="h22">
            <h2>{t("ConstructTips")}</h2>
          </div>
          <div className="pdf-thumbnails-carpenter">
            {langToPdfFileNameMap[currentlang]
              .slice(6) // Select the tips items
              .map((fileName, index) => (
                <div
                  key={index}
                  className="pdf-thumbnail-container"
                  onClick={() =>
                    handleThumbnailClick(index + langToPdfMap[currentlang].slice(0, 6).length)
                  }
                >
                  <img
                    className="thumbnail"
                    src={TipsIcon}
                    alt={`PDF ${index + 1}`}
                  />{" "}
                  {/* Use the correct index for tips */}
                  <p>{fileName}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
  
      {selectedPdfIndex !== null && (
        <>
          <div className="pdf-overlay" onClick={handleCloseFocus}>
            <div className="pdf-buttons">
              <div>
                <img
                  className="icons"
                  src={GoBackIcon}
                  alt="Go Back"
                  onClick={handleCloseFocus}
                />
              </div>
              <div>
                <img
                  className="icons"
                  src={SaveIcon}
                  alt="Save"
                  onClick={handleSave}
                />
                <img
                  className="icons"
                  src={PrintIcon}
                  alt="Open in new tab"
                  onClick={handleOpenInNewTab}
                />
              </div>
            </div>
            <div className="pdf-focus">
              <Document
                file={langToPdfMap[currentlang][selectedPdfIndex]}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </div>
        </>
      )}
    </>
  );
  
};

export default PDFViewer;
