import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./TipsTricks.css";
import SaveIcon from '../../images/arrow.png';
import PrintIcon from '../../images/printer.png';
import GoBackIcon from '../../images/left-arrow-in-circular-button-black-symbol.png';
import ChecklistIcon from '../../images/pdf.png'; // Add this import
import TipsIcon from '../../images/pdf.png';

import CleaningCheckList_en from "../../pdfs/cleaning/CleaningChecklist.pdf";
import CleaningCheclist_it from "../../pdfs/cleaning/ListaDiControlloPerLaPulizia.pdf";
import CleaningCheckList_fr from "../../pdfs/cleaning/ListeDeControleDeNettoyage.pdf";
import CleaningCheckList_de from "../../pdfs/cleaning/ReinigungCheckliste.pdf";

import CleaningTips_en from '../../pdfs/CleaningTips/English/CleaningTips.pdf';
import CleaningTips_de from '../../pdfs/CleaningTips/German/CleaningTips.pdf';
import CleaningTips_fr from '../../pdfs/CleaningTips/French/CleaningTips.pdf';
import CleaningTips_it from '../../pdfs/CleaningTips/Italian/CleaningTips.pdf';

// Set the worker source for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdfThumbnails = [
  require("../../images/checklist.png"),
  require("../../images/checklist.png"),
  require("../../images/checklist.png"),
  require("../../images/checklist.png"),
];

const PDFViewer = () => {
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(null);
  const [selectedTipsIndex, setSelectedTipsIndex] = useState(null); // New state for tips index
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (selectedPdfIndex !== null || selectedTipsIndex !== null) {
        event.preventDefault();
      }
    };
    document.body.addEventListener("scroll", handleScroll, { passive: false });
    
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPdfIndex, selectedTipsIndex]);

  const langToPdfMap = {
    en: [CleaningCheckList_en, CleaningTips_en], // Include tips PDF for each language
    de: [CleaningCheclist_it, CleaningTips_de],
    fr: [CleaningCheckList_fr, CleaningTips_fr],
    it: [CleaningCheckList_de, CleaningTips_it],
  };

  const langToPdfFileNameMap = {
    en: ["Cleaning Checklist", "Cleaning Tips"], // Include tips file names
    de: ["Reinigung Checkliste", "Reinigungstipps"],
    fr: ["Liste De Controle De Nettoyage", "Conseils de Nettoyage"],
    it: ["Lisa Di Controllo Per La Pulizia", "Suggerimenti per la Pulizia"],
  };

  const handleThumbnailClick = (index) => {
    setSelectedPdfIndex(index);
    document.body.style.overflow='hidden'
  };

  const handleTipsThumbnailClick = (index) => {
    setSelectedTipsIndex(index);
    document.body.style.overflow='hidden'
  };

  const handleCloseFocus = () => {
    setSelectedPdfIndex(null);
    setSelectedTipsIndex(null); // Reset tips index
    document.body.style.overflow=''
  };

  const handleSave = () => {
    const link = document.createElement('a');
    link.href = langToPdfMap[currentlang][selectedPdfIndex];
    link.download = langToPdfFileNameMap[currentlang][selectedPdfIndex];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenInNewTab = () => {
    const newWindow = window.open();
    newWindow.document.write(`
      <iframe src="${langToPdfMap[currentlang][selectedPdfIndex]}" width="100%" height="100%"></iframe>
    `);
  };

  const currentlang = window.localStorage.i18nextLng;

  const { t } = useTranslation();

  return (
    <>
      <div className="parent-checklist-moving"> 
        <div className="checklist-root">
          <div className="h22">
            <h2>
              {t("CleaningChecklist")}
            </h2>
          </div>
          <div className="pdf-thumbnails">
            {langToPdfFileNameMap[currentlang].slice(0, 1).map((fileName, index) => (
              <div key={index} className="pdf-thumbnail-container-moving" onClick={() => handleThumbnailClick(index)}>
                <img className='thumbnail' src={ChecklistIcon} alt={`PDF ${index + 1}`} />
                <p>{fileName}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="checklist-root">
          <div className="h22">
            <h2>
              {t("CleaningTips")}
            </h2>
          </div>
          <div className="pdf-thumbnails">
            {langToPdfFileNameMap[currentlang].slice(1, 2).map((fileName, index) => (
              <div key={index} className="pdf-thumbnail-container-moving" onClick={() => handleTipsThumbnailClick(index)}>
                <img className='thumbnail' src={TipsIcon} alt={`PDF ${index + 1}`} />
                <p>{fileName}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {(selectedPdfIndex !== null || selectedTipsIndex !== null) && (
        <>
          <div className="pdf-overlay" onClick={handleCloseFocus}>
            <div className="pdf-buttons">
              <div>
                <img className="icons" src={GoBackIcon} alt="Go Back" onClick={handleCloseFocus} />
              </div>
              <div>
                <img className="icons" src={SaveIcon} alt="Save" onClick={handleSave} />
                <img className="icons" src={PrintIcon} alt="Open in new tab" onClick={handleOpenInNewTab} />
              </div>
            </div>
            <div className="pdf-focus">
              <Document file={langToPdfMap[currentlang][selectedPdfIndex !== null ? selectedPdfIndex : selectedTipsIndex]} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PDFViewer;
