import React from 'react';
import './ThreeStepCardComponent.css';
import { useEffect } from 'react';
import { useState } from 'react';
import MediaQuery from 'react-responsive';
//import "@fontsource/rubik";

const ThreeStepCardComponent = (props) => {  

  return (
    <>

      <MediaQuery minWidth={1026}>
          <div className="FormIllustrationDiv">
            <img className="FormSvg" src={props.svg} alt="FormIllustration logo" />
            <div className="sub-heading-2x">{props.title}</div>
            <div className="FormContent">{props.content}</div>
          </div>
      </MediaQuery>


      <MediaQuery maxWidth={1024}>
        <div className="mobileUsage">
          <img className="FormSvg-Mobile" src={props.svg} alt="FormIllustration logo" />
          <div className="sub-heading-2x-Mobile">{props.title}</div>
          <div className="FormContent-Mobile">{props.content}</div>
        </div>
      </MediaQuery>
      
    </>
  );
}

export default ThreeStepCardComponent;
