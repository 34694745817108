import React from 'react';

const OfferModel = (props) => {
   return <>
      <div className="offers-model">
         <div className="offer-name">
            <img className="icon-svg" src={props.OfferIcon} alt="Apartment Cleaning" />
            <span style={{ marginLeft: '1rem' }} className="sub-heading-2x font-blue">{props.OfferName}</span>
         </div>
         <div className="offer-description ">
        {props.description}
         </div>
      </div>
   </>
}

export default OfferModel;