import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import ResetPassword from './ResetPassword'
import Axios from "axios";

const NewPasswordRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    const validateToken = async () => {
      try {
        const response = await Axios.post(
          `https://api.easy-compare.ch/resetPassword/validate-token?token=${token}`,
          {
            token,
          }
        );
        if (response.data === "Token is valid") {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setIsValidToken(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      validateToken();
    } else {
      setIsValidToken(false);
      setIsLoading(false);
    }
  }, [location.search, navigate]);

  if (isLoading) {
    // You might want to show a loading spinner while the validation is in progress
    return <div>Loading...</div>;
  }

  if (!isValidToken) {
    // Redirect to the NotFound page if token is invalid or not provided
    return <Navigate to="/not-found" />;
  }

  return <ResetPassword />;
};

export default NewPasswordRoute;
