import React, { useEffect, useState } from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useTranslation } from "react-i18next";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { currentLang } from "../../common/Navbar";

const Order = (props) => {
  const params = new URLSearchParams(window.location.search);
  const requestType = params.get("requestType");
  const [cost, setCost] = useState(0.0);

  const { t } = useTranslation();
  const [subCategory, setSubCategory] = useState(
    props.resultCleaning.subCategory,
  );
  const [MoveOutCategory, SetMoveOutCategory] = useState(
    props.resultCleaning.MoveOutCategory,
  );
  // change this.
  const [categorydate, setCategorydate] = useState(
    props.resultCleaning.categorydate,
  );
  const [categorytimeflex, setCategoryflex] = useState(
    props.resultCleaning.categorytimeflex,
  );
  const [MovingOutTimeFlex, setMovingOutTimeFlex] = useState(
    props.resultCleaning.MovingOutTimeFlex,
  );
  const [flagdate, setFlagdate] = useState(false);
  const [flagCategory, setFlagCategory] = useState(false);
  const [mimportant, setMimportant] = useState(props.resultCleaning.mimportant);
  const [TermsCondition, setTermsCondition] = useState(
    props.resultCleaning.TermsCondition,
  );
  const [formErrors, setformErrors] = useState("");


  const Subcatgories = {
    Moving: {
      "Private Move": [
        "Apartment",
        "room in a shared flat",
        "house",
        "business",
      ],
      "Business Move": ["Apartment", "house", "business"],
      "International Move": ["Apartment", "house", "business"],
      "Clearance and disposal": [
        "Apartment",
        "room in a shared flat",
        "house",
        "business",
      ],
    },

    // add the double quotes
    Cleaning: {
      Apartment: [
        "removal cleaning",
        "standing order cleaning",
        "basic cleaning",
        "window cleaning",
        "terrace cleaning",
      ],
      House: [
        "removal cleaning",
        "standing order cleaning",
        "basic cleaning",
        "window cleaning",
        "terrace cleaning",
        "facade cleaning",
        "construction cleaning",
      ],
      Office: [
        "removal cleaning",
        "standing order cleaning",
        "basic cleaning",
        "window cleaning",
        "terrace cleaning",
      ],
      Business: [
        "removal cleaning",
        "standing order cleaning",
        "basic cleaning",
        "window cleaning",
        "terrace cleaning",
      ],
    },
    Painting: {
      Inside: [
        t("Renew walls"),
        t("ceilings"),
        "doors",
        "door frames",
        "window frames",
        "radiators",
        "staircase",
        "built-in closet",
        "wallpaper",
      ],
      Outside: [
        "facade painting",
        "facade cleaning",
        "wood painting",
        "painting doors",
        "painting door frames",
        "painting shutters",
      ],
      Business: [
        "Renew walls",
        "ceilings",
        "doors",
        "door frames",
        "window frames",
        "radiators",
        "staircase",
        "built-in closet",
        "wallpaper",
      ],
    },
    Carpenter: {
      "Interior design": [
        "closet",
        "bookshelf",
        "sideboard",
        "wardrobe",
        "table",
        "chairs",
        "partition wall",
        "office furniture",
        "bed",
        "bunk bed",
        "children's playroom",
        "other",
      ],
      "Bathroom & Kitchens": [
        "bathroom furniture",
        "vanity",
        "shower cubicle",
        "linen closet",
        "built-in closet",
        "fitted kitchen",
        "custom-made kitchen",
        "new worktop",
        "new built-in appliances",
        "new hinges",
        "other",
      ],
      "Windows & Doors": [
        "wooden windows",
        "aluminum windows",
        "PVC windows",
        "round windows",
        "semi-round windows",
        "arched windows",
        "segmental arched windows",
        "pitched roof windows",
        "trapezoidal windows",
        "triangular windows",
        "oval windows",
        "front door",
        "standard door",
        "fire protection door",
        "soundproof door",
        "burglar-resistant door",
        "security door",
        "hinged/revolving door",
        "sliding door",
        "folding door",
        "swing door",
        "other",
      ],
      "Repair & Restoration": [
        "Interior",
        "exterior",
        "bathroom",
        "kitchen",
        "stairs",
        "roof",
        "furniture restoration",
        "other",
      ],
    },
  };

  const SubcatgoriesDe = {
    Moving: {
      "Private Move": ["Wohnung", "Zimmer in einer WG", "Haus", "Unternehmen"],
      "Business Move": ["Wohnung", "Haus", "Unternehmen"],
      "International Move": ["Wohnung", "Haus", "Unternehmen"],
      "Clearance and disposal": [
        "Wohnung",
        "Zimmer in einer WG",
        "Haus",
        "Unternehmen",
      ],
    },

    Cleaning: {
      Apartment: [
        "Umzugsreinigung",
        "regelmäßige Reinigung",
        "Grundreinigung",
        "Fensterreinigung",
        "Terrassenreinigung",
      ],
      House: [
        "Umzugsreinigung",
        "regelmäßige Reinigung",
        "Grundreinigung",
        "Fensterreinigung",
        "Terrassenreinigung",
        "Fassadenreinigung",
        "Baureinigung",
      ],
      Office: [
        "Umzugsreinigung",
        "regelmäßige Reinigung",
        "Grundreinigung",
        "Fensterreinigung",
        "Terrassenreinigung",
      ],
      Business: [
        "Umzugsreinigung",
        "regelmäßige Reinigung",
        "Grundreinigung",
        "Fensterreinigung",
        "Terrassenreinigung",
      ],
    },
    Painting: {
      Inside: [
        "Wände erneuern",
        "Decken",
        "Türen",
        "Türrahmen",
        "Fensterrahmen",
        "Heizkörper",
        "Treppenhaus",
        "Einbauschrank",
        "Tapete",
      ],
      Outside: [
        "Fassadenanstrich",
        "Fassadenreinigung",
        "Holzanstrich",
        "Türen streichen",
        "Türrahmen streichen",
        "Fensterrahmen streichen",
        "Laden streichen",
      ],
      Business: [
        "Wände erneuern",
        "Decken",
        "Türen",
        "Türrahmen",
        "Fensterrahmen",
        "Heizkörper",
        "Treppenhaus",
        "Einbauschrank",
        "Tapete",
      ],
    },
    Carpenter: {
      "Interior design": [
        "Kleiderschrank",
        "Bücherregal",
        "Sideboard",
        "Kleiderschrank",
        "Tisch",
        "Stühle",
        "Trennwand",
        "Büromöbel",
        "Bett",
        "Etagenbett",
        "Kinderspielzimmer",
        "Andere",
      ],
      "Bathroom & Kitchens": [
        "Badezimmermöbel",
        "Waschtisch",
        "Duschkabine",
        "Wäscheschrank",
        "Einbauschrank",
        "Einbauküche",
        "Maßgefertigte Küche",
        "Neue Arbeitsplatte",
        "Neue Einbaugeräte",
        "Neue Scharniere",
        "Andere",
      ],
      "Windows & Doors": [
        "Holzfenster",
        "Aluminiumfenster",
        "PVC-Fenster",
        "Runde Fenster",
        "Halbrunde Fenster",
        "Bogenfenster",
        "Segmentbogenfenster",
        "Dachfenster",
        "Trapezförmige Fenster",
        "Dreiecksfenster",
        "Ovale Fenster",
        "Eingangstür",
        "Standardtür",
        "Brandschutztür",
        "Schallschutztür",
        "Einbruchsichere Tür",
        "Sicherheitstür",
        "Dreh-/Schwingtür",
        "Schiebetür",
        "Falttür",
        "Flügeltür",
        "Andere",
      ],
      "Repair & Restoration": [
        "Innenbereich",
        "Außenbereich",
        "Badezimmer",
        "Küche",
        "Treppen",
        "Dach",
        "Möbelrestaurierung",
        "Andere",
      ],
    },
  };

  const SubcatgoriesFr = {
    Moving: {
      "Private Move": [
        "Appartement",
        "Chambre en colocation",
        "Maison",
        "Entreprise",
      ],
      "Business Move": ["Appartement", "Maison", "Entreprise"],
      "International Move": ["Appartement", "Maison", "Entreprise"],
      "Clearance and disposal": [
        "Appartement",
        "Chambre en colocation",
        "Maison",
        "Entreprise",
      ],
    },

    Cleaning: {
      Apartment: [
        "Nettoyage de déménagement",
        "Nettoyage régulier",
        "Nettoyage de base",
        "Nettoyage des fenêtres",
        "Nettoyage de la terrasse",
      ],
      House: [
        "Nettoyage de déménagement",
        "Nettoyage régulier",
        "Nettoyage de base",
        "Nettoyage des fenêtres",
        "Nettoyage de la terrasse",
        "Nettoyage de la façade",
        "Nettoyage de la construction",
      ],
      Office: [
        "Nettoyage de déménagement",
        "Nettoyage régulier",
        "Nettoyage de base",
        "Nettoyage des fenêtres",
        "Nettoyage de la terrasse",
      ],
      Business: [
        "Nettoyage de déménagement",
        "Nettoyage régulier",
        "Nettoyage de base",
        "Nettoyage des fenêtres",
        "Nettoyage de la terrasse",
      ],
    },
    Painting: {
      Inside: [
        "Rénovation des murs",
        "Plafonds",
        "Portes",
        "Cadres de porte",
        "Cadres de fenêtre",
        "Radiateurs",
        "Escalier",
        "Placard encastré",
        "Papier peint",
      ],
      Outside: [
        "Peinture de façade",
        "Nettoyage de façade",
        "Peinture du bois",
        "Peinture des portes",
        "Peinture des cadres de porte",
        "Peinture des volets",
      ],
      Business: [
        "Rénovation des murs",
        "Plafonds",
        "Portes",
        "Cadres de porte",
        "Cadres de fenêtre",
        "Radiateurs",
        "Escalier",
        "Placard encastré",
        "Papier peint",
      ],
    },
    Carpenter: {
      "Interior design": [
        "Armoire",
        "Bibliothèque",
        "Buffet",
        "Garde-robe",
        "Table",
        "Chaises",
        "Cloison",
        "Mobilier de bureau",
        "Lit",
        "Lit superposé",
        "Salle de jeu pour enfants",
        "Autre",
      ],
      "Bathroom & Kitchens": [
        "Meubles de salle de bains",
        "Lavabo",
        "Cabine de douche",
        "Armoire à linge",
        "Placard encastré",
        "Cuisine équipée",
        "Cuisine sur mesure",
        "Nouveau plan de travail",
        "Nouveaux appareils encastrés",
        "Nouvelles charnières",
        "Autre",
      ],
      "Windows & Doors": [
        "Fenêtres en bois",
        "Fenêtres en aluminium",
        "Fenêtres en PVC",
        "Fenêtres rondes",
        "Fenêtres semi-rondes",
        "Fenêtres cintrées",
        "Fenêtres cintrées segmentaires",
        "Fenêtres de toit",
        "Fenêtres trapézoïdales",
        "Fenêtres triangulaires",
        "Fenêtres ovales",
        "Porte d'entrée",
        "Porte standard",
        "Porte coupe-feu",
        "Porte insonorisée",
        "Porte anti-effraction",
        "Porte de sécurité",
        "Porte battante/tournante",
        "Porte coulissante",
        "Porte pliante",
        "Porte pivotante",
        "Autre",
      ],
      "Repair & Restoration": [
        "Intérieur",
        "Extérieur",
        "Salle de bains",
        "Cuisine",
        "Escaliers",
        "Toit",
        "Restauration de meubles",
        "Autre",
      ],
    },
  };

  const SubcatgoriesIT = {
    Moving: {
      "Private Move": [
        "Appartamento",
        "Stanza in un appartamento condiviso",
        "Casa",
        "Attività commerciale",
      ],
      "Business Move": ["Appartamento", "Casa", "Attività commerciale"],
      "International Move": ["Appartamento", "Casa", "Attività commerciale"],
      "Clearance and disposal": [
        "Appartamento",
        "Stanza in un appartamento condiviso",
        "Casa",
        "Attività commerciale",
      ],
    },

    Cleaning: {
      Apartment: [
        "Pulizia per il trasloco",
        "Pulizia periodica",
        "Pulizia di base",
        "Pulizia delle finestre",
        "Pulizia del terrazzo",
      ],
      House: [
        "Pulizia per il trasloco",
        "Pulizia periodica",
        "Pulizia di base",
        "Pulizia delle finestre",
        "Pulizia del terrazzo",
        "Pulizia della facciata",
        "Pulizia della costruzione",
      ],
      Office: [
        "Pulizia per il trasloco",
        "Pulizia periodica",
        "Pulizia di base",
        "Pulizia delle finestre",
        "Pulizia del terrazzo",
      ],
      Business: [
        "Pulizia per il trasloco",
        "Pulizia periodica",
        "Pulizia di base",
        "Pulizia delle finestre",
        "Pulizia del terrazzo",
      ],
    },
    Painting: {
      Inside: [
        "Rinnovo pareti",
        "Soffitti",
        "Porte",
        "Cornici delle porte",
        "Cornici delle finestre",
        "Radiatori",
        "Scala",
        "Armadio a muro",
        "Carta da parati",
      ],
      Outside: [
        "Pittura della facciata",
        "Pulizia della facciata",
        "Pittura del legno",
        "Pittura delle porte",
        "Pittura delle cornici delle porte",
        "Pittura delle persiane",
      ],
      Business: [
        "Rinnovo pareti",
        "Soffitti",
        "Porte",
        "Cornici delle porte",
        "Cornici delle finestre",
        "Radiatori",
        "Scala",
        "Armadio a muro",
        "Carta da parati",
      ],
    },
    Carpenter: {
      "Interior design": [
        "Armadio",
        "Libreria",
        "Tavolino",
        "Guardaroba",
        "Tavolo",
        "Sedie",
        "Parete divisoria",
        "Mobili da ufficio",
        "Letto",
        "Letto a castello",
        "Stanza giochi per bambini",
        "Altro",
      ],
      "Bathroom & Kitchens": [
        "Mobili da bagno",
        "Lavandino",
        "Cabina doccia",
        "Armadio per biancheria",
        "Armadio a muro",
        "Cucina attrezzata",
        "Cucina su misura",
        "Piano di lavoro nuovo",
        "Elettrodomestici da incasso nuovi",
        "Cerniere nuove",
        "Altro",
      ],
      "Windows & Doors": [
        "Finestre in legno",
        "Finestre in alluminio",
        "Finestre in PVC",
        "Finestre rotonde",
        "Finestre semicircolari",
        "Finestre ad arco",
        "Finestre ad arco segmentate",
        "Finestre per tetti spioventi",
        "Finestre trapezoidali",
        "Finestre triangolari",
        "Finestre ovali",
        "Porta d'ingresso",
        "Porta standard",
        "Porta antincendio",
        "Porta insonorizzata",
        "Porta antieffrazione",
        "Porta di sicurezza",
        "Porta a battente/rotante",
        "Porta scorrevole",
        "Porta pieghevole",
        "Porta a battente",
        "Altro",
      ],
      "Repair & Restoration": [
        "Interno",
        "Esterno",
        "Bagno",
        "Cucina",
        "Scale",
        "Tetto",
        "Restauro mobili",
        "Altro",
      ],
    },
  };

  const fnSubSelectChange = (e) => {
    let value = e.target.value;
    setSubCategory(value);
  };
  const fnSetMoveOutCategory = (e) => {
    let value = e.target.value;
    SetMoveOutCategory(value);
  };
  const fnMImportant = (e) => {
    setMimportant(e.target.value);
  };
  const fnTermsCondition = (e) => {
    setTermsCondition(e.target.value);
  };

  const [categoryDateFirst, setCategoryDateFirst] = useState(null);
  const [categoryDateSecond, setCategoryDateSecond] = useState(null);
  const [categoryDateThird, setCategoryDateThird] = useState(null);
  const [flagDateFirst, setFlagDateFirst] = useState(false);
  const [flagDateSecond, setFlagDateSecond] = useState(false);
  const [flagDateThird, setFlagDateThird] = useState(false);

  const handleDateChangeFirst = (date) => {
    setCategoryDateFirst(date);
    setFlagDateFirst(false);
  };

  const handleDateChangeSecond = (date) => {
    setCategoryDateSecond(date);
    setFlagDateSecond(false);
  };

  const handleDateChangeThird = (date) => {
    setCategoryDateThird(date);
    setFlagDateThird(false);
  };
  
  const fnCategoryDate = (e) => {
    setCategorydate(e);
    setFlagdate(false);
  };
  const fnCategoryTimeFlex = (e) => {
    setCategoryflex(e.target.value);
  };
  const fnMovingOutTimeFlex = (e) => {
    setMovingOutTimeFlex(e.target.value);
  };
  const inputEvent = (e) => {
    let name = "",
      value = "";
    if (e.target === undefined) {
      name = "date";
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    //validations

    setformErrors(e.target?.value === "" ? "Please select Category" : "");
  };

  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      subCategory: subCategory,
      categorydateFirst: categoryDateFirst,
      categorydateSecond: categoryDateSecond,
      categorydateThird: categoryDateThird,
      categorytimeflex: categorytimeflex,
      mimportant: mimportant,
      TermsCondition: TermsCondition,
      MoveOutCategory: MoveOutCategory,
      MovingOutTimeFlex: MovingOutTimeFlex,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (
      (subCategory &&
        (requestType !== "Moving" ||
          props.resultCleaning?.requestType === "Clearance and disposal" ||
          requestType === "Painting" ||
          requestType === "Carpenter")) ||
      (subCategory &&
        MoveOutCategory &&
        props.resultCleaning?.requestType !== "Clearance and disposal") ||
      requestType === "Painting" ||
      requestType === "Carpenter"
    ) {
      const newState = {
        subCategory: subCategory,
        categorydateFirst: categoryDateFirst,
        categorydateSecond: categoryDateSecond,
        categorydateThird: categoryDateThird,
        categorytimeflex: categorytimeflex,
        mimportant: mimportant,
        TermsCondition: TermsCondition,
        MoveOutCategory: MoveOutCategory,
        MovingOutTimeFlex: MovingOutTimeFlex,
        cost: cost,
        ...props.resultCleaning, // Spread the existing props.resultCleaning properties
      };
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    } else {
      console.error("Form Invalid - Display Error Message");
      setformErrors(t("Select-Category"));
    }

    
  };

  const [termsAccepted, setTermsAccepted] = useState(true); // State to track if terms are accepted

  const handleCheckboxChange = (event) => {
    setTermsAccepted(event.target.checked); // Update state based on checkbox value
  };

  return (
    <div className="category-component">
      <div className="category-container">
        <span className="card-box" style={{ flexDirection: "column" }}>
          {requestType !== "Moving" && (
            <div
              className="order-desc"
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disablePast
                  className={flagDateFirst && "border border-danger"}
                  label={t("Date")}
                  required
                  inputFormat="MM/DD/YYYY"
                  value={categoryDateFirst}
                  onChange={(e) => {
                    handleDateChangeFirst(e);
                    inputEvent(e);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="date" />
                  )}
                />
                {formErrors.date && (
                  <span className="error">{formErrors.date}</span>
                )}
              </LocalizationProvider>
            </div>
          )}

          <div className="order-desc" style={{ paddingLeft: "5px" }}>
            {requestType === "Moving" &&
              props.resultCleaning.requestType !== "Clearance and disposal" && (
                <div
                  className="parent"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    className="order-desc"
                    style={{ paddingLeft: "5px", width: "50%" }}
                  >
                    <h2
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px"
                    }}
                    >{t("iMoveOut")}
                    </h2>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("moving-out")}
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        disablePast
                        className={flagDateSecond && "border border-danger"}
                        label={t("Date")}
                        required
                        inputFormat="MM/DD/YYYY"
                        value={categoryDateSecond}
                        onChange={(e) => {
                          handleDateChangeSecond(e);
                          inputEvent(e);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="date" />
                        )}
                      />
                      {formErrors.date && (
                        <span className="error">{formErrors.date}</span>
                      )}
                    </LocalizationProvider>

                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px"
                      }}
                    >
                      {t("Time Flexibiltiy for Moving Out")}
                    </label>
                    <ButtonGroup
                      onClick={(event) => {
                        fnMovingOutTimeFlex(event);
                        inputEvent(event);
                      }}
                      className="timeFlex"
                      name="timegroup"
                      required
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBottom: "20px"
                      }}
                    >
                      <Button
                        name="time"
                        className={
                          MovingOutTimeFlex === "1 day"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                        value="1 day"
                      >
                        {t("1-day")}
                      </Button>
                      <Button
                        name="time"
                        className={
                          MovingOutTimeFlex === "1 week"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                        value="1 week"
                      >
                        {t("1-week")}
                      </Button>
                      <Button
                        name="time"
                        className={
                          MovingOutTimeFlex === "1 month"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                        value="1 month"
                      >
                        {t("1-month")}
                      </Button>
                      <Button
                        name="time"
                        className={
                          MovingOutTimeFlex === "No"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                        value="No"
                      >
                        {t("no")}
                      </Button>
                    </ButtonGroup>
                    {formErrors.MovingOutTimeFlex && (
                      <span className="error">
                        {formErrors.MovingOutTimeFlex}
                      </span>
                    )}
                  </div>
                </div>
              )}

            {requestType === "Moving" &&
              props.resultCleaning.requestType !== "Clearance and disposal" && (
                <FormControl
                  style={{ marginLeft: "205px", ml: 0, width: "50%" }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    {t("Moving out category")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    defaultValue={""}
                    className={flagCategory && "border border-danger"}
                    id="demo-simple-select-helper"
                    name="MoveOutCategory"
                    required
                    value={MoveOutCategory}
                    label={t("Moving out category")}
                    onChange={(event) => {
                      fnSetMoveOutCategory(event);
                      inputEvent(event);
                    }}
                  >
                    <MenuItem selected value="">
                      {t("Select-Category")}
                    </MenuItem>

                    {currentLang === "en" &&
                      Subcatgories[requestType][
                        props.resultCleaning.requestType
                      ].map((list, index) => (
                        <MenuItem key={index + 1} value={list}>
                          {list}
                        </MenuItem>
                      ))}

                    {currentLang === "de" &&
                      SubcatgoriesDe[requestType][
                        props.resultCleaning.requestType
                      ].map((list, index) => (
                        <MenuItem key={index + 1} value={list}>
                          {list}
                        </MenuItem>
                      ))}

                    {currentLang === "fr" &&
                      SubcatgoriesFr[requestType][
                        props.resultCleaning.requestType
                      ].map((list, index) => (
                        <MenuItem key={index + 1} value={list}>
                          {list}
                        </MenuItem>
                      ))}

                    {currentLang === "it" &&
                      SubcatgoriesIT[requestType][
                        props.resultCleaning.requestType
                      ].map((list, index) => (
                        <MenuItem key={index + 1} value={list}>
                          {list}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors && !MoveOutCategory && (
                    <span className="error">{formErrors}</span>
                  )}
                </FormControl>
              )}

            <div
              className="superior-parent"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div
                className="parent2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginBottom: "20px",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                {requestType === "Moving" &&
                  props.resultCleaning.requestType !==
                    "Clearance and disposal" && (
                    <div
                      className="order-desc"
                      style={{ paddingLeft: "5px", width: "100%" }}
                    >
                      <hr style={{
                        margin: "30px 0 30px 0"
                      }} />
                      <h2
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px"
                    }}
                    >{t("iMoveIn")}
                    </h2>
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {t("move-in")}
                      </label>
                      <LocalizationProvider
                        style={{ width: "50%" }}
                        dateAdapter={AdapterDayjs}
                      >
                        <DesktopDatePicker
                          disablePast
                          className={flagDateThird && "border border-danger"}
                          label={t("Date")}
                          required
                          inputFormat="MM/DD/YYYY"
                          value={categoryDateThird}
                          style={{ width: "50%" }}
                          onChange={(e) => {
                            handleDateChangeThird(e);
                            inputEvent(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} name="date" />
                          )}
                        />
                        {formErrors.date && (
                          <span className="error">{formErrors.date}</span>
                        )}
                      </LocalizationProvider>
                    </div>
                  )}
                <label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px"
                  }}
                >
                  {requestType === "Moving"
                    ? t("Time Flexibiltiy for Moving In")
                    : t("time-flex")}
                </label>
                <ButtonGroup
                  onClick={(event) => {
                    fnCategoryTimeFlex(event);
                    inputEvent(event);
                  }}
                  className="timeFlex"
                  name="timegroup"
                  required
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {/* <p>here too</p> */}
                  <Button
                    name="time"
                    className={
                      categorytimeflex === "1 day"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                    value="1 day"
                  >
                    {t("1-day")}
                  </Button>
                  <Button
                    name="time"
                    className={
                      categorytimeflex === "1 week"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                    value="1 week"
                  >
                    {t("1-week")}
                  </Button>
                  <Button
                    name="time"
                    className={
                      categorytimeflex === "1 month"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                    value="1 month"
                  >
                    {t("1-month")}
                  </Button>
                  <Button
                    name="time"
                    className={
                      categorytimeflex === "None"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                    value="None"
                  >
                    {t("None")}
                  </Button>
                </ButtonGroup>
              </div>
            </div>

            {formErrors.time && (
              <span className="error">{formErrors.time}</span>
            )}
          </div>
          {requestType !== "Moving" &&
            requestType !== "Cleaning" &&
            requestType !== "Painting" && (
              <div className="location-class">
                <label htmlFor="location-id">Location</label>
                <input
                  id="location-id"
                  className="location"
                  type="text"
                  placeholder={t("Location-placeholder")}
                />
              </div>
            )}

          {requestType !== "Painting" && requestType !== "Carpenter" && (
            <>
              <FormControl style={{ marginLeft: "205px", ml: 0, width: "50%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  {requestType === "Moving"
                    ? t("Moving In Category")
                    : t("category")}
                </InputLabel>

                <Select
                  labelId="demo-simple-select-helper-label"
                  defaultValue={""}
                  className={flagCategory && "border border-danger"}
                  id="demo-simple-select-helper"
                  name="subCategory"
                  required
                  value={subCategory}
                  label={t("category")}
                  onChange={(event) => {
                    fnSubSelectChange(event);
                    inputEvent(event);
                  }}
                >
                  <MenuItem selected value="">
                    {t("Select-Category")}
                  </MenuItem>

                  {currentLang === "en" &&
                    Subcatgories[requestType][
                      props.resultCleaning.requestType
                    ].map((list, index) => (
                      <MenuItem key={index + 1} value={list}>
                        {list}
                      </MenuItem>
                    ))}

                  {currentLang === "de" &&
                    SubcatgoriesDe[requestType][
                      props.resultCleaning.requestType
                    ].map((list, index) => (
                      <MenuItem key={index + 1} value={list}>
                        {list}
                      </MenuItem>
                    ))}

                  {currentLang === "fr" &&
                    SubcatgoriesFr[requestType][
                      props.resultCleaning.requestType
                    ].map((list, index) => (
                      <MenuItem key={index + 1} value={list}>
                        {list}
                      </MenuItem>
                    ))}

                  {currentLang === "it" &&
                    SubcatgoriesIT[requestType][
                      props.resultCleaning.requestType
                    ].map((list, index) => (
                      <MenuItem key={index + 1} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                </Select>
                {formErrors && !subCategory && (
                  <span className="error">{formErrors}</span>
                )}
              </FormControl>
            </>
          )}

          <div className="order-desc"></div>
          {requestType === "Moving" && (
            <div className="order-desc">
              <Form.Label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a className="decora" style={{marginRight: "10px"}} href="/TermsCondition">
                  {t("termsconditions")}
                </a>
                <p>/</p>
                <a className="decora" style={{marginLeft: "10px"}} href="#">
                  {t("privacypolicy")}
                </a>
              </Form.Label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={handleCheckboxChange}
                  />
                }
                label={termsAccepted ? t("Yes") : t("No")}
                required
                style={{ display: "flex", justifyContent: "center" }}
              />
            </div>
          )}
        </span>
        <span className="prevNext-btn card-box">
          <Button variant="warning" onClick={fnClickPrev}>
            {t("prev")}
          </Button>

          <Button variant="warning" onClick={fnClickNext}>
            {t("next")}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Order;
