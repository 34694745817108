import axios from "axios";
import Cookies from "js-cookie";
const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";
export const getAuthAxios = (navigate) => {

   const authAxios = axios.create({
      baseURL: REACT_APP_BACKEND_SERVER2,
      headers: {
         Authorization: `Bearer ${Cookies.get('jwtToken')}`,
      },
   });

   authAxios.interceptors.response.use((response) => {
      if (response.status === 401) {
         navigate("/Login");
      }
      return response;
   }, (error) => {
      if (error.response.status === 403) {
         navigate("/Login");
      }
      // console.log(error);
      return Promise.reject(error);
   });

   return authAxios;
}

export const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

export const colorsArray = ['#2DB223', '#23A2B2', '#233EB2', '#B25223', 'black'];

export const sessionCheck=()=>{
   return (Cookies.get('jwtToken')===undefined || Cookies.get('jwtToken')==='')
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
       width: 250,
     },
   },
 };
 export function getStyles(name, selectedOption1, theme) {
   return {
     fontWeight:
       selectedOption1.indexOf(name) === -1
         ? theme.typography.fontWeightRegular
         : theme.typography.fontWeightMedium,
   };
 }
export const recordsPerPage=4;