import Axios from "axios";
import { useSelector } from "react-redux";
import React, { useState } from "react"
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import {Button } from 'react-bootstrap';
import './index.css';

function getStyles(key, selectedOption1, theme) {
    return {
        fontWeight:
            selectedOption1.indexOf(key) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const Speciality = () => {
    const requestsData = useSelector(state => state.companyReducer);
    console.log("inside profile section baby", requestsData);
    const requests = requestsData.requests;

    const theme = useTheme();
    const [selectedOption1, setSelectedOption1] = useState([]);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOption1(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";

    const fnSaveSpeciality = () =>{
        Axios.post(REACT_APP_BACKEND_SERVER2 + '/company/addCategory', selectedOption1).then((res) => {
            if (res.data.status === "Success") {
               alert("Your specialties saved successfully!")
               
            } else {
               alert("Something went wrong.")
            }
         }).catch((error) => {
            console.log("Error happened at ID 10923882");
         });
    }

    return <>
        <div className="Profile-container">
            <div>
                <FormControl sx={{ m: 1, minWidth: 400 }}>
                    <InputLabel id="demo-multiple-name-label">Select Speciality(MULTIPLE CHOICE)</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedOption1}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select Speciality(MULTIPLE CHOICE)" />}
                        MenuProps={MenuProps}>
                        <MenuItem value="Moving" key="Moving" style={getStyles('Moving', selectedOption1, theme)}>Moving </MenuItem>
                        <MenuItem value="Cleaning" key="Cleaning" style={getStyles('Cleaning', selectedOption1, theme)}>Cleaning </MenuItem>
                        <MenuItem value="Painting" key="Painting" style={getStyles('Painting', selectedOption1, theme)}>Painting </MenuItem>
                        <MenuItem value="Carpenter" key="Carpenter" style={getStyles('Carpenter', selectedOption1, theme)}>Carpenter </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div>
            <Button variant="warning" onClick={fnSaveSpeciality}>Save</Button>
            </div>
        </div>
    </>
}



export default Speciality;