import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import "./index.css";

const Notification = () => {
  const requestsData = useSelector((state) => state.companyReducer);

  return (
    <>
      <Card>
        <div className="Profile-container">Inside Notification</div>
      </Card>
    </>
  );
};

export default Notification;
