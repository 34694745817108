import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import sessionStorage from "redux-persist/es/storage/session";
import './Logout.css';
import { Button } from "react-bootstrap";

const LogoutBtn = () => {

    const navigate = useNavigate();
    const handleClick = () =>{
        Cookies.remove('jwtToken');
        sessionStorage.removeItem('persist:root');
        navigate("/Login");
        return false;
    }
   return (
    <div className="">
        <Button style={{backgroundColor:"Maroon",fontSize:"inherit"}} onClick={handleClick}>Logout</Button>
    </div>
   );
} 


export default LogoutBtn;