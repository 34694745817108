import React, { useState } from 'react';
import '../CleaningPage/Pricing.css';
import './common.css';
import { useTranslation } from 'react-i18next'

const ReadMoreComponent = (props) => {
   return <>
      <div className="step-grid readmore">
         {props.Content.map((val, index) => {
            return (
               <div key={index}>
                  <div style={{ display: "flex", alignItems: "start" }}>
                     <span>{val[0]}</span>
                     <h2>{val[1]}</h2>
                  </div>

                  <ReadMore>
                     {val[2]}
                  </ReadMore>
               </div>
            )
         })}

      </div>
   </>
}

const ReadMore = ({ children }) => {
   const { t } = useTranslation();
   const [isReadMore, setIsReadMore] = useState(true);
   const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
   };

   return <p>
      {isReadMore ? children.slice(0, 150) : children}
      <a className='btn btn-secondary bg-none' onClick={toggleReadMore}>
         {isReadMore ? t("Read more") : t("Read less")}
      </a>
   </p>
}
export default ReadMoreComponent;