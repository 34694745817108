import AccountHeader from "./AccountHeader";
import AccountMenu from "./AccountMenu";
import loginImage from '../../../images/home/home.png';
import { Routes, Route } from "react-router-dom";
import Requests from "../Requests"
import RequestDetail from "../Requests/RequestDetail";
import ProfileDetails from "../ProfileDetails";
import RequestFilters from "../Requests/RequestFilters";
import "./index.css";
import Overview from "./Overview";
import Finance from "./Finance";
const AccountPage = () => {
    return (
        <>
           <AccountHeader/>
           <div className="hero login-page accountPage" style={{gap:"1rem" }}>
                <AccountMenu />
                <Routes>
                    <Route path="requests" element={<Requests />} />
                    <Route path="requests/detail" element={<RequestDetail />} />
                    <Route path="filters" element={<RequestFilters />} />
                    <Route path="profile/*" element={<ProfileDetails/>} />
                    <Route path="overview" element={<Overview />} />
                    <Route path="finance" element={<Finance />} />
                </Routes>
            </div>
        </>
    );
}

export default AccountPage;