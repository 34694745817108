import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init(
    {
      fallbackLng: "en",
      supportedLngs: ["en", "de","fr","it"],
      keySeparator: false,

      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ['cookie', 'localStorage', 'htmlTag', 'sessionStorage', 'navigator', 'path', 'subdomain'],
        caches: ['cookie', 'localStorage']
      },
      backend:{
        loadPath:'/locales/{{lng}}/translation.json',
      }
      // react:{useSuspense:false}
    });
export default i18n;
