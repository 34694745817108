import React from "react";
import { useTranslation } from 'react-i18next'

const ServiceInfo = () => {
   const { t } = useTranslation();

   return <>
      <div>
         <span className="sub-heading hidden"> {t("Cleaning-Service")}</span>
         <div className="main-heading">{t("CleaningServiceInfoHeading")}</div>
         <div className="description">
            <p>
               {t("CleaningServiceInfoDesc1")}
            </p>
            <p>
               {t("CleaningServiceInfoDesc2")}
            </p>
            <p>
               {t("CleaningServiceInfoDesc3")}
            </p>
         </div>
         
      </div>
   </>
}

export default ServiceInfo;