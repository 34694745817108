import "../common/Hero.css";
import "./Registration.css";
import { styled } from '@mui/material/styles';
import Navbar from "../common/Navbar";
import Axios from "axios";
import homeImage from "../../images/home/home.png";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../utils/utils";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Form } from "react-bootstrap";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from "use-places-autocomplete";

import "@reach/combobox/styles.css";

const Registration = () => {
  const { t } = useTranslation();
  const expertiseArr = [
    t("Moving"),
    t("Cleaning"),
    t("Painting"),
    t("Carpenter"),
  ];
  const [formErrors, setformErrors] = useState({
    name: "",
    contactNumber: "",
    email: "",
    commercialRegister: "",
    password: "",
    website: "",
    confirmpassword: "",
    postCode: "",
    address: "",
    companyExpertise: "",
  });
  const navigate = useNavigate();
  const [regDetails, setRegDetails] = useState({
    customerId: 1,
    name: "",
    contactNumber: "",
    email: "",
    commercialRegister: "",
    password: "",
    website: "",
    address: {
      location: "",
      postCode: "",
      latitude: 1,
      longitude: 1,
    },
    expertise: [],
  });

  function generateRandomNumber() {
    const min = 10000;
    const max = 99999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    setRegDetails((prevValues) => {
      return {
        ...prevValues,
        customerId: generateRandomNumber(),
      };
    });
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  

  const formvalid = (formErrors) => {
    let valid = true;
    if (companyExpertise.length <= 0) {
      valid = false;
      setformErrors((prev) => {
        return { ...prev, companyExpertise: t("select-exp") };
      });
    }
    Object.values(formErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  const [companyExpertise, setCompanyExpertise] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCompanyExpertise((pre) => {
      return typeof value === "string" ? value.split(",") : value;
    });
    setRegDetails((prevValues) => {
      return {
        ...prevValues,
        expertise: typeof value === "string" ? value.split(",") : value,
      };
    });
    setformErrors((prev) => {
      return {
        ...prev,
        companyExpertise: value.length <= 0 ? t("select-exp") : "",
      };
    });
  };

  const [companyStreet, setCompanyStreet] = useState(' ');
  const [companyPlace, setCompanyPlace] = useState(' ');

  const handleStreetChange = (event) => {
    setCompanyStreet(event.target.value);
  }
  const handlePlaceChange = (event) => {
    setCompanyPlace(event.target.value);
  }

  const handleSubmit = () => {
    // Validate form fields here
    if (password !== confirmPassword) {
        // Passwords do not match, display error message or prevent submission
        console.error("Passwords do not match");
        return; // Return early if passwords do not match
    }
    
    // Construct the registration data
    const registerData = {
        companyName: regDetails.name,
        companyOwner: regDetails.owner,
        companyEmail: regDetails.email,
        telefonNumber: regDetails.contactNumber,
        expertise: selectedExpertises,
        expertiseOptions: selectedOptions,
        password: password,
        companyIdNumber: compUUID,
        website: regDetails.website,
        companyStreet: companyStreet,
        postalCode: zipCode,
        companyPlace: companyPlace
        
    };

    // Send registration data first
    Axios.post("https://api.easy-compare.ch/register/company", registerData)
        .then((response) => {
            if (response.data.response_code === "200") {
                // Registration successful
                alert("Registration successful!");
                // Now handle file upload
                uploadCompanyDocument(selectedFile, regDetails.email);
            } else if (response.data.response_code === "400") {
                // Registration failed
                console.error(response);
                alert("Registration failed. Company already registered.");
                // Handle registration failure
            } else {
                // Unexpected response code
                console.error("Unexpected response code:", response.data.response_code);
            }
        })
        .catch((error) => {
            console.error("Error submitting form:", error);
            // Handle error
        });
};

const uploadCompanyDocument = (file, email) => {
    // Construct FormData for file upload
    const formData = new FormData();
    formData.append("companyDocument", file);
    formData.append("email", email);
    
    // Send file upload request
    console.log(formData)
    Axios.post("https://api.easy-compare.ch/upload/document", formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then((response) => {
        if (response.data.response_code === "200") {
            // File upload successful
            console.log("File upload successful!");
        } else {
            // File upload failed
            console.error(response);
            alert("File upload failed.");
            // Handle file upload failure
        }
    })
    .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
    });
};  
  


  const inputEvent = (e) => {
    //validations
    const { name, value } = e.target;
    setformErrors((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          stateObj[name] = value === "" ? "Please enter your full name." : "";
          break;
        case "email":
          stateObj[name] =
            emailRegex.test(value) && value.length > 0
              ? ""
              : t("invalid-email");
          break;
        case "contactNumber":
          if (value === "") {
            stateObj[name] = t("enter-phone");
          } else if (value !== "") {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(value)) {
              stateObj[name] = t("enter-only-number");
            } else if (value.length !== 10) {
              stateObj[name] = t("enter-valid-phone");
            } else {
              stateObj[name] = "";
            }
          }
          break;
        case "commercialRegister":
          if (value === "") {
            stateObj[name] = t("enter-uid");
          }
          break;
        case "password":
          stateObj[name] =
            value.length < 8 && value.length > 0 ? t("minimum-char") : "";
          setRegDetails((prevValues) => {
            return { ...prevValues, confirmpassword: "" };
          });
          break;
        case "confirmpassword":
          if (value === "") {
            stateObj[name] = t("confirm-pass");
          }
          stateObj[name] = regDetails.password !== value ? t("same-pass") : "";
          break;
        case "website":
          stateObj[name] = value === "" ? t("enter-website") : "";
          break;
        case "address":const handleSubmit = () => {
          // Validate form fields here
          if (password !== confirmPassword) {
            // Passwords do not match, display error message or prevent submission
            console.error("Passwords do not match");
            return; // Return early if passwords do not match
          }
        
          // Construct the FormData object for multipart form data
          const formData = new FormData();
          formData.append("registerRequest", JSON.stringify({
            companyName: regDetails.name,
            companyOwner: regDetails.owner,
            companyEmail: regDetails.email,
            telefonNumber: regDetails.contactNumber,
            expertise: selectedExpertises,
            expertiseOptions: selectedOptions,
            password: password,
            companyIdNumber: compUUID,
            website: regDetails.website,
            postalCode: regDetails.postalCode,
            companyPlace: regDetails.companyPlace
          }));
          formData.append("companyDocument", selectedFile);
        
          console.log(formData);
        
          Axios.post("https://api.easy-compare.ch/register/company", formData, {
            headers: {
              "Content-Type": "multipart/form-data" // Set content type to multipart/form-data
            }
          })
            .then((response) => {
              if (response.data.response_code === "200") {
                alert("Registration successful!");
                // Handle successful registration
              } else if (response.data.response_code === "400") {
                console.error(response)
                alert("Registration failed. Company already registered.");
                // Handle registration failure
              } else {
                console.error("Unexpected response code:", response.data.response_code);
              }
            })
            .catch((error) => {
              console.error("Error submitting form:", error);
              // Handle error
            });
        };
          stateObj[name] = value === "" ? t("enter-address") : "";
          break;
        case "postCode":
          stateObj[name] = value === "" ? t("enter-post") : "";
          break;
        case "companyExpertise":
          stateObj[name] = companyExpertise === "" ? t("enter-expert") : "";
          break;
        default:
          break;
      }

      return stateObj;
    });

    const inputName = e.target.name;

    if (inputName === "location" || inputName === "postCode") {
      setRegDetails((prevValues) => {
        return {
          ...prevValues,
          address: {
            ...prevValues.address,
            [e.target.name]: e.target.value,
          },
        };
      });
    } else {
      setRegDetails((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.value };
      });
    }
  };

  // address geocode
  const [address, setAddress] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [zipcode, setZipcode] = useState();
  const [addressflag, setAddresflag] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  const handleOldInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = (val) => {
    setValue(val, false);
    setAddress(val);
    setAddresflag(false);
    const parameter = {
      address: val,
    };

    getGeocode(parameter)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLat(latLng.lat);
        setLng(latLng.lng);
        setRegDetails((prevValues) => {
          return {
            ...prevValues,
            address: {
              ...prevValues.address,
              ["location"]: val,
              ["longitude"]: latLng.lng,
              ["latitude"]: latLng.lat,
              // ,["postCode"]: zipcode
            },
          };
        });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    getGeocode(parameter)
      .then((results) => getZipCode(results[0], false))
      .then((zipCode) => {
        setZipcode(zipCode);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    getGeocode(parameter)
      .then((results) =>
        getDetails({ placeId: results[0].place_id, fields: ["name", "rating"] })
      )
      .then((details) => {
        console.log("Details: ", details);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const [step, setStep] = useState(1);

  const nextStep = () => {
    const { name, owner, email, contactNumber } = regDetails;
    
    if (!name || !owner || !email || !contactNumber) {
      alert(t("FieldAlert"));
      return;
    }
  
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChangeStep2 = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedExpertises((prevExpertises) => [...prevExpertises, id]);
    } else {
      setSelectedExpertises((prevExpertises) =>
        prevExpertises.filter((expertise) => expertise !== id)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedOptions((prevOptions) => [...prevOptions, value]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((options) => options !== value)
      );
    }
  };

  
  

  const options = {
    Moving: [
      t("Private Move"),
      t("Business Move"),
      t("International Move"),
      t("Clearance and disposal"),
    ],

    Cleaning: [
      t("apartment"),
      t("House"),
      t("Office"),
      t("Business"),
    ],

    Painting: [
      t("Inside"),
      t("Outside"),
    ],

    Construct: [
      t("interiordesign"),
      t("kitchenbuilder"),
      t("furnituredesign"),
      t("bathroombuilder"),
      t("floorlayers"),
      t("doorsandwindows"),
    ]
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [zipCode, setZipCode] = useState('');
  const [compUUID, setCompUUID] = useState('');

  const handleNumberChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setZipCode(value);
    }
  };

  const handleNumberUUIDChange = (event) => {
    const { value } = event.target;
    if (value !== null) {
      setCompUUID(value);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "location" || name === "postCode") {
      setRegDetails((prevValues) => ({
        ...prevValues,
        address: {
          ...prevValues.address,
          [name]: value,
        },
      }));
    } else {
      setRegDetails((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  

  return (
    <>
      <Navbar menuVisibility={"hidden"} />
      <div
        className="hero reg-page"
        style={{ backgroundImage: `url(${homeImage})` }}
      >
        <div className="form-container">

        {step === 1 && (
          <div className="general-info">
            <h2>{t("regis-intro")}</h2>
            <TextField
              required
              id="name"
              label={t("CompName")}
              placeholder={t("CompName")}
              name="name"
              value={regDetails.name}
              onChange={handleInput}
            />
            <TextField
              required
              id="owner"
              label={t("CompOwner")}
              placeholder={t("CompOwner")}
              name="owner"
              value={regDetails.owner}
              onChange={handleInput}
            />
            <TextField
              id="email"
              label={t('CompEmail')}
              type="email"
              placeholder={t("CompEmail")}
              name="email"
              value={regDetails.email}
              onChange={handleInput}
            />
            <TextField
              id="contactNumber"
              label={t('CompPhone')}
              type="tel"
              name="contactNumber"
              value={regDetails.contactNumber}
              onChange={handleInput}
            />
            <div className="button-container">
              <button onClick={nextStep}>{t("Next")}</button>
            </div>
          </div>
        )}

          {step === 2 && (
            <div className="category">
              <h2>{t("step1")}</h2>
              <div className="checkbox-container-regis"> 
                <label style={{display: "flex !important", 
                justifyContent:"center !important", 
                alignItems:"center !important"
                }} 
                
                htmlFor="move">{t('Moving')}</label>
                <input
                  type="checkbox"
                  id="Moving"
                  onChange={handleCheckboxChangeStep2}
                  checked={selectedExpertises.includes('Moving')}
                />
              </div>
              
              <div className="checkbox-container-regis"> 
                <label style={{display: "flex !important", 
                justifyContent:"center !important", 
                alignItems:"center !important"
                }} 
                
                htmlFor="clean">{t('Cleaning')}</label>
                <input
                  type="checkbox"
                  id="Cleaning"
                  onChange={handleCheckboxChangeStep2}
                  checked={selectedExpertises.includes('Cleaning')}
                />
              </div>

              <div className="checkbox-container-regis"> 
                <label style={{display: "flex !important", 
                justifyContent:"center !important", 
                alignItems:"center !important"
                }} 
                
                htmlFor="painting">{t('Painting')}</label>
                <input
                  type="checkbox"
                  id="Painting"
                  onChange={handleCheckboxChangeStep2}
                  checked={selectedExpertises.includes('Painting')}
                />
              </div>

              <div className="checkbox-container-regis"> 
                <label style={{display: "flex !important", 
                justifyContent:"center !important", 
                alignItems:"center !important"
                }}
                
                htmlFor="construct">{t('construct')}</label>
                <input
                  type="checkbox"
                  id="Construct"
                  onChange={handleCheckboxChangeStep2}
                  checked={selectedExpertises.includes('Construct')}
                />
              </div>

              <div className="button-container">
                <button onClick={prevStep}>{t("Previous")}</button>
                <button onClick={nextStep}>{t("Next")}</button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="expertises">
              <h2>{t("step2")}</h2>
              
              {selectedExpertises.map((expertise) => (
                <div className="expertise-child" key={expertise}>
                  <h3>{t(expertise)}</h3>
                  <div className="options-container">
                      {options[expertise].map((option) => (
                        
                        <div key={option} className="option">
                          <label htmlFor={option}>{t(option)}</label>
                          <input
                            type="checkbox"
                            id={option}
                            value={option}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            
              <div className="button-container">
                <button onClick={prevStep}>{t("Previous")}</button>
                <button onClick={nextStep}>{t("Next")}</button>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="important-info">

<TextField
      required
      id="password"
      label={t("Password")}
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />

    <TextField
      required
      id="confirmPassword"
      label={t("Confirm Password")}
      type="password"
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      error={password !== confirmPassword}
      helperText={password !== confirmPassword ? t("Passwords do not match") : ""}
    />

              <TextField
                required
                id="comp-uid"
                label={t("CompID")}
                placeholder={t("CompID")}
                type="text"
                value={compUUID}
                onChange={handleNumberUUIDChange}
                inputProps={{ inputMode: 'numeric' }}
              />

              

              <TextField
                id="comp-website"
                onChange={handleInput}
                label={t("Website")}
                placeholder={t("Optional")}
              />

              <TextField
                id="comp-street"
                label={t("CompStreet")}
                value={companyStreet}
                onChange={handleStreetChange}
                placeholder={t("CompStreet")}
              />


              <TextField
                id="comp-zipcode"
                label={t("ZipCode")}
                placeholder={t("ZipCode")}
                type="text"
                value={zipCode}
                onChange={handleNumberChange}
                inputProps={{ inputMode: 'numeric' }}
              />

              <TextField
                id="comp-place"
                label={t("CompPlace")}
                value={companyPlace}
                placeholder={t("CompPlace")}
                onChange={handlePlaceChange}
              />

              <Form.Label>
                {t("UploadInsurance")}
              </Form.Label>

              <Button
                required
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                {selectedFile ? selectedFile.name : "Upload file"}
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>


              <div className="button-container">
                <button onClick={prevStep}>{t("Previous")}</button>
                <button onClick={handleSubmit}>{t("Finish")}</button>
              </div>

            </div>
          )}

        </div>
        
      </div>
    </>
  );
};

export default Registration;
