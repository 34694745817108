import React from "react";
import { useTranslation } from 'react-i18next'
import "./index.css";
const PackingServiceInfo = () => {
   const { t } = useTranslation();

   return <>
      <div className={"packingServiceInfo"}>
         {/* <span className="sub-heading"> {t("moving service")}</span> */}
         <div className="main-heading">{t("Packing-Heading")}</div>
         <div className="description">
            <strong>
               {t("Packing-Desc1")}
            </strong>
            <ul>
               <li>
                  {t("packing-info-1")}
               
               </li>
               <li>
               {t("packing-info-2")}
              
               </li>
               <li>
               {t("packing-info-3")}
               
               </li>
               <li>
               {t("packing-info-4")}
               
               </li>
            </ul> 
            <br></br>
            <br></br>
            <strong>
               {t("Packing-Desc2")}
            </strong>
            <ul>
               <li>
               {t("packing-info-5")}
               </li>
               <li>{t("packing-info-6")}
               
               </li>
               <li>{t("packing-info-7")}
               
               </li>
               <li>{t("packing-info-8")}
              
               </li>
               <li>{t("packing-info-9")}
               
               </li>
               <li>{t("packing-info-10")}
               
               </li>
               <li>{t("packing-info-11")}
               
               </li>
               <li>{t("packing-info-12")}
               
               </li>
               <li>{t("packing-info-13")}
               
               </li>
            </ul>
         </div>
         <div>
            {/* <a className="btn btn-primary" style={{ display: "inline" }}>{t("Get-Started-Btn")}</a> */}
         </div>
      </div>
   </>
}

export default PackingServiceInfo;