import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useTranslation } from 'react-i18next'

const ServiceInfo = () => {
   const { t } = useTranslation();
   return <>

      <div>
         <span className="sub-heading hidden"> {t("Painting-service")}</span>
         <div className="main-heading">{t("PaintSeriveInfo-Heading")}</div>
         <div className="description">
            <p>
               {t("PaintSeriveInfo-Desc1")}
            </p>
            <p>
               {t("PaintSeriveInfo-Desc2")}
            </p>

         </div>
      </div>
   </>
}

export default ServiceInfo;