import React from "react";
import "./AdvantagesComponent.css";
import Logo70 from "../images/Logo70.svg";
import Logo70de from "../images/Logo70de.svg";
import AdvCardComponent from "./AdvCardComponent";
import MediaQuery from "react-responsive";

import { useTranslation } from "react-i18next";

const AdvantagesComponent = () => {
  const { t } = useTranslation();
  let currentlang = window.localStorage.i18nextLng;

  return (
    <>
      <MediaQuery minWidth={1026}>
        <div className="container AdvComponent-container mt-10">
          <div className="AdvComponentDiv">
            <img
              className="Logo70"
              src={
                currentlang === "de"
                  ? Logo70de
                  : currentlang === "fr"
                  ? Logo70
                  : currentlang === "it"
                  ? Logo70
                  : Logo70
              }
              alt="Logo70"
            />

            <div className="AdvLabelDiv">
              <div className="main-heading">
                {t("Your-advantages")} <span className="font-blue">Easy</span>
                <span className="font-yellow">Compare</span>
                {t(".ch?")}
              </div>

              <span className="AdvContent">{t("Adv-content")}</span>
            </div>

            <div className="parent-adv-container">
              <div className="Advcard-container">
                <AdvCardComponent content={t("AdvCard-1")} />
                <AdvCardComponent content={t("AdvCard-2")} />
                <AdvCardComponent content={t("AdvCard-3")} />
              </div>
                
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
        <div className="AdvComponent-container mt-10">
          <div className="AdvComponentDiv">
            <img
              className="Logo70"
              src={
                currentlang === "de"
                  ? Logo70de
                  : currentlang === "fr"
                  ? Logo70
                  : currentlang === "it"
                  ? Logo70
                  : Logo70
              }
              alt="Logo70"
            />

            <div className="AdvLabelDiv">
              <div className="main-heading">
                {t("Your-advantages")} <span className="font-blue">Easy</span>
                <span className="font-yellow">Compare</span>
                {t(".ch?")}
              </div>

              <span className="AdvContent">{t("Adv-content")}</span>
            </div>
            <div className="parent-adv-container-mobile">
              <AdvCardComponent content={t("AdvCard-1")} />
              <AdvCardComponent content={t("AdvCard-2")} />
              
            </div>
            <AdvCardComponent content={t("AdvCard-3")} />
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default AdvantagesComponent;
