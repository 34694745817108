
import { Card } from "@mui/material";
import { useSelector } from "react-redux";

import './index.css';


const ProfileReviews = () => {
    const requestsData = useSelector(state => state.companyReducer);

    return <>
    <Card>
        <div className="Profile-container">
           INside ProfileReviews
        </div>
    </Card>
    </>
}



export default ProfileReviews;