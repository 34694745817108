import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { BsBellSlash } from "react-icons/bs";
import "./AccountHeader.css";
import EasyCompareLogo from "../../common/EasyCompareLogo";
import LogoutBtn from "../../common/Logout";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { useSelector } from "react-redux";

const AccountHeader = (props) => {
  const { t } = useTranslation();
  const companyDetails = useSelector((state) => state.companyReducer);
  return (
    <>
    <MediaQuery minWidth={1026}>
      <div className="accHeader-menu">
        <div className="nav-logo">
          <Link to="/">
            {" "}
            <EasyCompareLogo />
          </Link>
        </div>
        {/* <ul className="nav-menu" style={{ visibility: props.menuVisibility }}>
          <li>
            LnB clearance service <br></br>
            {t("cust-no")}: {companyDetails.customerId}
          </li>
        </ul> */}

        <ul
          className="head-opt"
          style={{ visibility: props.menuBtnVisibility }}
        >
          <Button className="">help</Button>
          <Button className="mx-1" variant="danger">
            <BsBellSlash />
          </Button>
          {/* <Button className="" style={{flexDirection:"column",display:props.BalanceBtnVisibility}}>
               <div>Current balance CHF</div>
               <div></div>
            </Button> */}
          <div
            className="nav-menu rounded-0 border py-2"
            style={{ visibility: props.menuVisibility }}
          >
            {" "}
            <LogoutBtn />
          </div>
        </ul>
      </div>
      </MediaQuery>


      <MediaQuery maxWidth={1024}>
      <div className="accHeader-menu-mobile">
        <div className="nav-logo">
          <Link to="/">
            {" "}
            <EasyCompareLogo />
          </Link>
        </div>
        <ul className="nav-menu" style={{ visibility: props.menuVisibility }}>
          <li>
            LnB clearance service <br></br>
            {t("cust-no")}: 1888
          </li>
        </ul>
        <ul className="nav-menu" style={{ visibility: props.menuVisibility }}>
          <li>
            hotline <br></br>044 552 05 14
          </li>
        </ul>
        <ul
          className="head-opt"
          style={{ visibility: props.menuBtnVisibility }}
        >
          <Button className="">help</Button>
          <Button className="mx-1" variant="danger">
            <BsBellSlash />
          </Button>
          {/* <Button className="" style={{flexDirection:"column",display:props.BalanceBtnVisibility}}>
               <div>Current balance CHF</div>
               <div></div>
            </Button> */}
          <div
            className="nav-menu rounded-0 border py-2"
            style={{ visibility: props.menuVisibility }}
          >
            {" "}
            <LogoutBtn />
          </div>
        </ul>
      </div>
      </MediaQuery>
    </>
  );
};

export default AccountHeader;
