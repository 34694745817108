import { NavLink } from "react-router-dom";
import "./index.css";
import { useTranslation } from "react-i18next";

const AccountMenu = () => {
  const { t } = useTranslation();
  return (
    <>
      <ul className="AccountMenu">

        <NavLink to="/account/overview" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("overview")}</div>
        </NavLink>

        <NavLink to="/account/filters" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("filter")}</div>
        </NavLink>

        <NavLink to="/account/requests" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("requests")}</div>
        </NavLink>

        <NavLink to="/account/profile" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("profile")}</div>
        </NavLink>

        <NavLink to="/account/finance" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("finance")}</div>
        </NavLink>
        
      </ul>
    </>
  );
};

export default AccountMenu;
