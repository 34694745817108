import React from "react";
import './Testimonials.css';
import pic1 from '../images/Testimonials/pic1.png';
import pic2 from '../images/Testimonials/pic2.png';
import pic3 from '../images/Testimonials/pic3.png';
import pic4 from '../images/Testimonials/pic4.png';
import pic5 from '../images/Testimonials/pic5.png';
import pic6 from '../images/Testimonials/pic6.png';
import pic7 from '../images/Testimonials/pic7.png';
import pic8 from '../images/Testimonials/pic8.png';
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from 'react-i18next'
import MediaQuery from "react-responsive";


const Testimonials = () => { 
   const { t } = useTranslation();



   return <>
   <MediaQuery minWidth={1026}>
      <div className='container testimonial-container'>
        
         <div className="main-heading mb-4"> {t('Testimonials-Clients-say')}</div>
         <div className="grid-container">
            <div className="grid-section">
               <GridCard className="verticalCard" imgSrc={pic1} testimonial={t('Testimonial1Moving')} name={"Sandro Enkelmann"}  />

               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic6} testimonial={t('Testimonial2Moving')} name={"Helene Schmidt"}  />
            </div>
            <div >
               <GridCard className="horizontalCard" imgSrc={pic2} testimonial={t('Testimonial1Cleaning')} name={"Gianni Russo"}  />
               <GridCard2 className="card-2" imgSrc={pic4} testimonial={t('Testimonial2Cleaning')} name={"Evelyn Müller"} />
               <GridCard2 className="card-2" imgSrc={pic7} testimonial={t('Testimonial1Painting')} name={"Sandro Wyss"}  />
            </div>
            <div>
               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic3} testimonial={t('Testimonial2Painting')} name={"Adrian Jensen"}  />
               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic5} testimonial={t('Testimonial1Carpenter')} name={"Gabriel Egli"}  />
               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic8} testimonial={t('Testimonial2Carpenter')} name={"Francesco De Luca"}  />
            </div>
         </div>
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
      <div className='testimonial-container-mobile'>

         <div className="main-heading mb-4"> {t('Testimonials-Clients-say')}</div>
         <div className="grid-container-mobile">
            <div className="grid-section">
               <GridCard className="verticalCard" imgSrc={pic1} testimonial={t('Testimonial1Moving')} name={"Sandro Enkelmann"} />

               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic6} testimonial={t('Testimonial2Moving')} name={"Helene Schmidt"}  />
            </div>
            <div >
               <GridCard className="horizontalCard" imgSrc={pic2} testimonial={t('Testimonial1Cleaning')} name={"Gianni Russo"}  />
               <GridCard2 className="card-2" imgSrc={pic4} testimonial={t('Testimonial2Cleaning')} name={"Evelyn Müller"}  />
               <GridCard2 className="card-2" imgSrc={pic7} testimonial={t('Testimonial1Painting')} name={"Sandro Wyss"}  />
            </div>
            <div>
               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic3} testimonial={t('Testimonial2Painting')} name={"Adrian Jensen"}  />
               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic5} testimonial={t('Testimonial1Carpenter')} name={"Gabriel Egli"}  />
               <GridCard className="horizontalCard horizontalCompact" imgSrc={pic5} testimonial={t('Testimonial2Carpenter')} name={"Francesco De Luca"} />
            </div>
         </div>
      </div>
      </MediaQuery>
   </>
}

export default Testimonials;

const GridCard = (props) => {
   return <>
      <div className={"grid-card " + props.className}>
         <img src={props.imgSrc} alt="" />
         <div className="grid-content">
            <p className="content-fs">{props.testimonial}</p>
            <div>
               <h5 className="name">{props.name}</h5>
               <span className="designation">{props.designation}</span>
               <div className="rating"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></div>
            </div>
         </div>
      </div>
   </>
}
const GridCard2 = (props) => {
   return <>
      <div className={"grid-card " + props.className}>
         <div className="">
            <img src={props.imgSrc} alt="" />
            <div style={{ display: 'inline-block', marginLeft: '1rem' }}>
               <h5 className="name">{props.name}</h5>
               <span className="designation">{props.designation}</span>
               <div className="rating"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></div>
            </div>
         </div>
         <p className="content-fs">{props.testimonial}</p>
      </div>
   </>
}