import React from "react";
import "./ProcessSteps.css";
import { useEffect } from "react";
import { useState } from "react";
import MediaQuery from "react-responsive";

import { useTranslation } from "react-i18next";

const ProcessSteps = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { t } = useTranslation();

  return (
    <>
      <MediaQuery minWidth={1026}>
        <div className="container mt-5 processDiv-container">
          <div className="processDiv">
            <span className="sub-heading hidden"> {t("Process")}</span>
            <div className="main-heading mb-6 font-blue">
              {props.label}
              {/* <span className="font-blue"> {t('3 steps')}</span> */}
            </div>
            <div className="card-container">{props.children}</div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
        <div className="mt-5 processDiv-container-mobile">
          <div className="processDiv-mobile">
            <span className="sub-heading hidden"> {t("Process")}</span>
            <div className="main-heading-mobile mb-6-mobile font-blue">
              {props.label}
              {/* <span className="font-blue"> {t('3 steps')}</span> */}
            </div>
            <div className="card-container-mobile">{props.children}</div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default ProcessSteps;
