
import { createSlice } from "@reduxjs/toolkit";

export const adminReducer1 = ( state = {} , action ) => {
   switch (action.type) {
       case "ADMINLOGIN":
           return action.payload;
       default :
           return state;
   }
}



const adminReducer=createSlice({
    name:"adminReducer",
    initialState:{
        companies:[],
        currentCompany:{},
        selectedRequest:{},
    },
    reducers:{
        setCompanies(state,action){
            // console.log(...action.payload,"adminReducer.payloadaction.payload");
            // state.companies.push("sdfad");
            // console.log(state.companies,"state.companiesstate.companies")
            return {...state,
                companies:action.payload
            };
        },
        setCurrentCompany(state,action){
            return {...state,
                currentCompany:action.payload
            };
        },
        setSelectedRequest(state,action){
            state.selectedRequest=action.payload;
        },
    }
});

export default adminReducer.reducer;
export const adminReducerAction=adminReducer.actions;
