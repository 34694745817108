import React from "react";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAuthAxios } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ApplyFilter } from "../Requests/RequestFilters";
import RequestListing from "../Moving/RequestListing";
import "../Requests/index.css";
import { getDistance } from "geolib";
import "../Moving/Moving.css";
import MediaQuery from "react-responsive";

function CleaningFilter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const authAxios = getAuthAxios(navigate);
  const companyDetails = useSelector((state) => state.companyReducer);
  const [filterTags, setFilterTags] = useState(
    useSelector((state) => state.requestFilterReducer.filters),
  );
  // ------------------------------------------------------------------
  const [priceChecked, setPriceChecked] = useState(false);
  const [qualityChecked, setQualityChecked] = useState(false);
  const [maxRadiusKm, setMaxRadiusKm] = useState(0);
  // ------------------------------------------------------------------
  const [handleAreaChangeValue, setHandleAreaChangeValue] = useState("");
  const [isHandleAreaChangeValue, setIsHandleAreaChangeValue] = useState(false);
  const [handleTypeChangeValue, setHandleTypeChangeValue] = useState("");
  const [isHandleTypeChangeValue, setIsHandleTypeChangeValue] = useState(false);
  const [handleFocusChangeValue, setHandleFocusChangeValue] = useState("");
  const [isHandleFocusChangeValue, setIsHandleFocusChangeValue] =
    useState(false);
  // ------------------------------------------------------------------
  const [area0_59Checked, setArea0_59Checked] = useState(false);
  const [area60_140Checked, setArea60_140Checked] = useState(false);
  const [area141Checked, setArea141Checked] = useState(false);
  const [area141MoreChecked, setArea141MoreChecked] = useState(false);
  // ------------------------------------------------------------------
  const [requests, setRequest] = useState([]);
  const [filteredData, setFilteredData] = useState();

  const [apartamentChecked, setApartamentChecked] = useState(false);
  const [houseChecked, setHouseChecked] = useState(false);
  const [officeChecked, setOfficeChecked] = useState(false);
  const [businessChecked, setBusinessChecked] = useState(false);
  const [isAnyAreaCheckboxChecked, setIsAnyAreaCheckboxChecked] = useState(false);
  // ------------------------------------------------------------------

  const [referencePoint, setReferencePoint] = useState({
    latitude: 41.327953,
    longitude: 19.819025,
  });

  function getUserLocation() {
    if ("geolocation" in navigator) {
      // Check if the user already granted permission
      const hasPermission =
        localStorage.getItem("geolocationPermission") === "granted";

      if (!hasPermission) {
        // Request permission and update localStorage
        navigator.geolocation.getCurrentPosition(function (position) {
          localStorage.setItem("geolocationPermission", "granted");
          setReferencePoint({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        });
      }
    } else {
      console.log("Geolocation is already enabled.");
    }
  }

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get("/company/request")
      .then((response) => {
        if (response.status === 200) {
          setRequest(response.data); // Set the entire response data
          setIsLoading(false);
        } else {
          console.log("Request is failing at ID 81237923");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error happened at ID 10923832");
      });
    getUserLocation();
  }, []);



  useEffect(() => {
    if (!isLoading && requests.length > 0) {
      console.log(requests);
      const filteredRequests = requests.filter((request) =>
        [
          "Appartament",
          "House",
          "Office",
          "Business",
        ].includes(request.requestType),
      );

      const filteredResults = filteredRequests.filter((request) => {
        return (
          // Area checkboxes
          (area0_59Checked &&
            request.objectArea >= 0 &&
            request.objectArea <= 59) ||
          (area60_140Checked &&
            request.objectArea >= 60 &&
            request.objectArea <= 140) ||
          (area141Checked && request.objectArea === 141) ||
          (area141MoreChecked && request.objectArea > 141) ||
          // Focus checkboxes
          (priceChecked && request.focus === "price") ||
          (qualityChecked && request.focus === "quality") ||
          // Moving checkboxes
          !apartamentChecked ||
          request.requestType === "Apartament" || request.requestType === "Wohnung" || request.requestType === "Appartements" || request.requestType === "Appartamento" ||
          !houseChecked ||
          request.requestType === "House" || request.requestType === "Haus" || request.requestType === "Maison" || request.requestType === "Casa" ||
          !officeChecked ||
          request.requestType === "Office" || request.requestType === "Büro" || request.requestType === "Bureau" || request.requestType === "Ufficio" ||
          !businessChecked ||
          request.requestType === "Business" || request.requestType === "Gewerbe" || request.requestType === "Entreprise" || request.requestType === "Attività commerciale" ||
          ApplyFilter(requests, filterTags)
        ); // Consider the specified radius
      });
      setFilteredData(filteredResults);
    }
  }, [
    isLoading,
    requests,
    filterTags,
    area0_59Checked,
    area60_140Checked,
    area141Checked,
    area141MoreChecked,
    priceChecked,
    qualityChecked,
    apartamentChecked,
    houseChecked,
    officeChecked,
    businessChecked,
    maxRadiusKm, // Include radiusKm as a dependency
    handleAreaChangeValue,
    handleTypeChangeValue,
    handleFocusChangeValue,
  ]);

  const handleRadiusChange = (event) => {
    const value = event.target.value;
    setMaxRadiusKm(value); // Set the input value in km
  };

  const handleApartamentCheckedChange = (event) => {
    setApartamentChecked(event.target.checked);
  };
  const handleHouseMovingChange = (event) => {
    setHouseChecked(event.target.checked);
  };
  const handleOfficeCheckedChange = (event) => {
    setOfficeChecked(event.target.checked);
  };
  const handleBusinessChange = (event) => {
    setBusinessChecked(event.target.checked);
  };

  const handleArea0_59Change = (event) => {
    setArea0_59Checked(event.target.checked);
    // Check if any area checkbox is checked and set isAnyAreaCheckboxChecked accordingly
    setIsAnyAreaCheckboxChecked(
      event.target.checked ||
        area60_140Checked ||
        area141Checked ||
        area141MoreChecked,
    );
  };

  const handleArea60_140Change = (event) => {
    setArea60_140Checked(event.target.checked);
    setIsAnyAreaCheckboxChecked(
      area0_59Checked ||
        event.target.checked ||
        area141Checked ||
        area141MoreChecked,
    );
  };

  const handleArea141Change = (event) => {
    setArea141Checked(event.target.checked);
    setIsAnyAreaCheckboxChecked(
      area0_59Checked ||
        area60_140Checked ||
        event.target.checked ||
        area141MoreChecked,
    );
  };

  const handleArea141MoreChange = (event) => {
    setArea141MoreChecked(event.target.checked);
    setIsAnyAreaCheckboxChecked(
      area0_59Checked ||
        area60_140Checked ||
        area141Checked ||
        event.target.checked,
    );
  };

  const handlePrice = (event) => {
    setPriceChecked(event.target.checked);
  };

  const handleQuality = (event) => {
    setQualityChecked(event.target.checked);
  };

  const handleAreaChange = (event) => {
    // Handle the selected value from the Area dropdown
    const selectedValue = event.target.value;
    // Implement the logic to handle the selected value as needed
    // For example, you can update state or perform any other actions
    setHandleAreaChangeValue(selectedValue);
    setIsHandleAreaChangeValue(true);
  };

  const handleTypeChange = (event) => {
    // Handle the selected value from the Type dropdown
    const selectedValue = event.target.value;
    // Implement the logic to handle the selected value as needed
    // For example, you can update state or perform any other actions
    setHandleTypeChangeValue(selectedValue);
    setIsHandleTypeChangeValue(true);
  };

  const handleFocusChange = (event) => {
    // Handle the selected value from the Focus dropdown
    const selectedValue = event.target.value;
    // Implement the logic to handle the selected value as needed
    // For example, you can update state or perform any other actions
    setHandleFocusChangeValue(selectedValue);
    setIsHandleFocusChangeValue(true);
  };

  return (
    <>
      <div className="flex-superparent">
        <MediaQuery minWidth={1026}>
        <Card>
        <div className="parent-container">
            <div className="filter-moving-group">
              <ul className="moving-filter">
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="0-59"
                      onChange={handleArea0_59Change}
                    />
                    0m2 - 59m2
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="60-140"
                      onChange={handleArea60_140Change}
                    />
                    60m2 - 140m2
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="141"
                      onChange={handleArea141Change}
                    />
                    141m2
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="142"
                      onChange={handleArea141MoreChange}
                    />
                    from 141m2 and more
                  </label>
                </li>
              </ul>
            </div>

            {/* <div className="v"></div> */}

            <div className="filter-moving-group">
              {/* TODO: Add those to translation files later */}

              <ul className="moving-filter">
                <li>
                  <label>
                    <input type="checkbox" value="0-59" onChange={handleApartamentCheckedChange} />
                    {t("Apartment")}
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" value="60-140"  onChange={handleHouseMovingChange} />
                    {t("House")}
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" value="141" onChange={handleOfficeCheckedChange} />
                    {t("Office")}
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" value="142" onChange={handleBusinessChange} />
                    {t("Business")}
                  </label>
                </li>
              </ul>
            </div>
            <div className="filter-moving-group focus-group">
              <h2> Radius in KM </h2>
              <input
                  type="number"
                  min={0}
                  onWheel={(event) => event.currentTarget.blur()}
                  value={maxRadiusKm} // Bind the input value to radiusKm
                  onChange={handleRadiusChange} // Call handleRadiusChange on input change
                />
            </div>
          </div>
        </Card>
        </MediaQuery>


        <MediaQuery maxWidth={1024}>
          {/* <Card> */}
            <div className="parent-container-mobile">
              <div className="filter-moving-group selections">
                <label>
                  Area:
                  <select onChange={handleAreaChange}>
                    <option value="0-59">0m2 - 59m2</option>
                    <option value="60-140">60m2 - 140m2</option>
                    <option value="141">141m2</option>
                    <option value="142">from 141m2 and more</option>
                  </select>
                </label>
              </div>

              {/* <div className="v"></div> */}

              <div className="filter-moving-group selections">
                <label>
                  Type:
                  <select onChange={handleTypeChange}>
                    <option value="Apartment">Apartment</option>
                    <option value="House">House</option>
                    <option value="Office">Office</option>
                    <option value="Business">Business</option>
                  </select>
                </label>
              </div>

              {/* <div className="v"></div> */}
              <div className="filter-moving-group selections">
                <label>
                  Radius in KM:
                  <input
                    type="number"
                    min={0}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={maxRadiusKm} // Bind the input value to maxRadiusKm
                    onChange={handleRadiusChange} // Call handleRadiusChange on input change
                  />
                </label>
              </div>
            </div>
          {/* </Card> */}
        </MediaQuery>

        <div className="accordion-container">
        
        {apartamentChecked && (
          <div className="accordian-request-container">
            {filteredData.map((request, index) => {
              // Create a variable to hold the component to render
              let requestListingComponent = null;
              

              if (request.requestType === "Apartament") {
                // Calculate distances for the filtered "International Move" requests and store them in an array
                const distance = getDistance(referencePoint, {
                  latitude: request.address.latitude,
                  longitude: request.address.longitude,
                });
                const distanceInKm = distance / 1000; // Convert to kilometers

                // Define area and radius filters
                const areaFilter =
                  (!isAnyAreaCheckboxChecked ||
                    (area0_59Checked &&
                      request.objectArea >= 0 &&
                      request.objectArea <= 59) ||
                    (area60_140Checked &&
                      request.objectArea >= 60 &&
                      request.objectArea <= 140) ||
                    (area141Checked && request.objectArea === 141) ||
                    (area141MoreChecked && request.objectArea > 141)) &&
                  (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);

                // Define price and quality filters
                const priceQualityFilter =
                (!qualityChecked || request.focus === "quality") &&
                  (!priceChecked || request.focus === "price");
                  

                if (areaFilter && priceQualityFilter) {
                  requestListingComponent = (
                    <RequestListing
                      key={index}
                      details={request}
                      index={index}
                      companyDetails={companyDetails}
                    />
                  );
                }
              }

              // Render the component if it's set
              if (requestListingComponent) {
                return requestListingComponent;
              } else {
                // If no component is rendered, return null
                return null;
              }
            })}
          </div>
        )}

        {isHandleTypeChangeValue && (
          <div className="accordian-request-container">
            {filteredData.map((request, index) => {
              // Create a variable to hold the component to render
              let requestListingComponent = null;

              if (
                request.requestType === handleTypeChangeValue
              ) {
                console.log(handleTypeChangeValue);
                // Calculate distances for the filtered "Private Move" requests and store them in an array
                const distance = getDistance(referencePoint, {
                  latitude: request.address.latitude,
                  longitude: request.address.longitude,
                });
                const distanceInKm = distance / 1000; // Convert to kilometers

                const areaFilterV2 = (!isHandleAreaChangeValue ||
                  (handleAreaChangeValue === "0-59" && request.objectArea >= 0 && request.objectArea <= 59) ||
                  (handleAreaChangeValue === "60-140" && request.objectArea >= 60 && request.objectArea <= 140) ||
                  (handleAreaChangeValue === "141" && request.objectArea === 141) ||
                  (handleAreaChangeValue === "142" && request.objectArea > 141)) &&
                  (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);
                

                  const priceQualityFilterV2 =
                  (!isHandleFocusChangeValue ||
                    (handleFocusChangeValue === "Quality" && request.focus === "quality") ||
                    (handleFocusChangeValue === "Price" && request.focus === "price"));


                if (areaFilterV2 && priceQualityFilterV2) {
                  requestListingComponent = (
                    <RequestListing
                      key={index}
                      details={request}
                      index={index}
                      companyDetails={companyDetails}
                    />
                  );
                }
              }

              // Render the component if it's set
              if (requestListingComponent) {
                return requestListingComponent;
              } else {
                // If no component is rendered, return null
                return null;
              }
            })}
          </div>
        )}
        {houseChecked && (
          <div className="accordian-request-container">
            {filteredData.map((request, index) => {
              // Create a variable to hold the component to render
              let requestListingComponent = null;
              

              if (request.requestType === "House") {
                // Calculate distances for the filtered "International Move" requests and store them in an array
                const distance = getDistance(referencePoint, {
                  latitude: request.address.latitude,
                  longitude: request.address.longitude,
                });
                const distanceInKm = distance / 1000; // Convert to kilometers

                // Define area and radius filters
                const areaFilter =
                  (!isAnyAreaCheckboxChecked ||
                    (area0_59Checked &&
                      request.objectArea >= 0 &&
                      request.objectArea <= 59) ||
                    (area60_140Checked &&
                      request.objectArea >= 60 &&
                      request.objectArea <= 140) ||
                    (area141Checked && request.objectArea === 141) ||
                    (area141MoreChecked && request.objectArea > 141)) &&
                  (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);

                // Define price and quality filters
                const priceQualityFilter =
                (!qualityChecked || request.focus === "quality") &&
                  (!priceChecked || request.focus === "price");
                  

                if (areaFilter && priceQualityFilter) {
                  requestListingComponent = (
                    <RequestListing
                      key={index}
                      details={request}
                      index={index}
                      companyDetails={companyDetails}
                    />
                  );
                }
              }

              // Render the component if it's set
              if (requestListingComponent) {
                return requestListingComponent;
              } else {
                // If no component is rendered, return null
                return null;
              }
            })}
          </div>
        )}
        {officeChecked && (
          <div className="accordian-request-container">
            {filteredData.map((request, index) => {
              // Create a variable to hold the component to render
              let requestListingComponent = null;
              

              if (request.requestType === "Office") {
                // Calculate distances for the filtered "International Move" requests and store them in an array
                const distance = getDistance(referencePoint, {
                  latitude: request.address.latitude,
                  longitude: request.address.longitude,
                });
                const distanceInKm = distance / 1000; // Convert to kilometers

                // Define area and radius filters
                const areaFilter =
                  (!isAnyAreaCheckboxChecked ||
                    (area0_59Checked &&
                      request.objectArea >= 0 &&
                      request.objectArea <= 59) ||
                    (area60_140Checked &&
                      request.objectArea >= 60 &&
                      request.objectArea <= 140) ||
                    (area141Checked && request.objectArea === 141) ||
                    (area141MoreChecked && request.objectArea > 141)) &&
                  (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);

                // Define price and quality filters
                const priceQualityFilter =
                (!qualityChecked || request.focus === "quality") &&
                  (!priceChecked || request.focus === "price");
                  

                if (areaFilter && priceQualityFilter) {
                  requestListingComponent = (
                    <RequestListing
                      key={index}
                      details={request}
                      index={index}
                      companyDetails={companyDetails}
                    />
                  );
                }
              }

              // Render the component if it's set
              if (requestListingComponent) {
                return requestListingComponent;
              } else {
                // If no component is rendered, return null
                return null;
              }
            })}
          </div>
        )}
        {businessChecked && (
          <div className="accordian-request-container">
            {filteredData.map((request, index) => {
              // Create a variable to hold the component to render
              let requestListingComponent = null;
              

              if (request.requestType === "Business") {
                // Calculate distances for the filtered "International Move" requests and store them in an array
                const distance = getDistance(referencePoint, {
                  latitude: request.address.latitude,
                  longitude: request.address.longitude,
                });
                const distanceInKm = distance / 1000; // Convert to kilometers

                // Define area and radius filters
                const areaFilter =
                  (!isAnyAreaCheckboxChecked ||
                    (area0_59Checked &&
                      request.objectArea >= 0 &&
                      request.objectArea <= 59) ||
                    (area60_140Checked &&
                      request.objectArea >= 60 &&
                      request.objectArea <= 140) ||
                    (area141Checked && request.objectArea === 141) ||
                    (area141MoreChecked && request.objectArea > 141)) &&
                  (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);

                // Define price and quality filters
                const priceQualityFilter =
                (!qualityChecked || request.focus === "quality") &&
                  (!priceChecked || request.focus === "price");
                  

                if (areaFilter && priceQualityFilter) {
                  requestListingComponent = (
                    <RequestListing
                      key={index}
                      details={request}
                      index={index}
                      companyDetails={companyDetails}
                    />
                  );
                }
              }

              // Render the component if it's set
              if (requestListingComponent) {
                return requestListingComponent;
              } else {
                // If no component is rendered, return null
                return null;
              }
            })}
          </div>
        )}
        </div>
      </div>
    </>
  );
}

export default CleaningFilter;
