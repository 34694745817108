import Button from "@mui/material/Button";
import RequestDrop from "./RequestDrop";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useEffect, useMemo, useState } from "react";
import { getAuthAxios, recordsPerPage } from "../../../utils/utils";
import "./index.css";
import { useDispatch } from "react-redux";
import { getRequestAction } from "../../../redux/actions/getRequestAction";
import { setRequestAction } from "../../../redux/reducers/setRequest";
import { ApplyFilter } from "./RequestFilters";
import ReactPaginate from "react-paginate";
import "../../common/common.css";
import Loader from "../../common/Loader";
import RequestDetail from "./RequestDetail";

const Requests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const [isLoading, setIsLoading] = useState(false);
  const companyDetails = useSelector((state) => state.companyReducer);
  const [filterTags, setFilterTags] = useState(
    useSelector((state) => state.requestFilterReducer.filters),
  );
  const [requests, setRequest] = useState([]);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get("/company/request")
      .then((response) => {
        if (response.status === 200) {
          setRequest(response.data); // Set the entire response data
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error happened at ID 12393892");
      });
  }, []);

  useEffect(() => {
    if (!isLoading && requests.length > 0) {
      console.log(requests);
      console.log(requests[0].objectArea);
      console.log("Data has loaded:", requests.length);
      requests.length > 0 && setFilteredData(ApplyFilter(requests, filterTags));
    }
  }, [isLoading, requests, filterTags]);

  const [startItemIndex, setStartItemIndex] = useState(0);
  const [pageCount, setPageCount] = useState();

  const currentItems = useMemo(() => {
    if (filteredData !== undefined && filteredData.length !== 0) {
      setPageCount(Math.ceil(filteredData.length / recordsPerPage));
      const endItemIndex = startItemIndex + recordsPerPage;
      return filteredData.slice(startItemIndex, endItemIndex);
    } else {
      return filteredData;
    }
  }, [filteredData, startItemIndex]);

  const handlePageClick = (event) => {
    const newStartIndex =
      (event.selected * recordsPerPage) % filteredData.length;
    setStartItemIndex(newStartIndex);
  };

  

  return (
    <>
      <div>
        {filteredData !== undefined && filteredData.length !== 0 ? (
          <div className="accordian-request-container">
            {currentItems.map((list, index) => (
              <Accordian
                key={index}
                details={list}
                index={index}
                companyDetails={companyDetails}
              />
            ))}
          </div>
        ) : (
          isLoading && <Loader />
        )}
      </div>
      <div className="custom-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>


    </>
  );
};

const Accordian = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setRequestDetails = (details) => {
    dispatch(setRequestAction.setRequest(details));
    navigate("/account/requests/detail?company=" + props.companyDetails.name);
    console.log(props.companyDetails);
  };
  return (
    <>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          className="accordion-button"
        >
          <div className="header-content">
            {/* {props.details.category.type} in {props.details.address.location} */}
            <br></br>
            {/* <h1>This is...</h1> */}
            {props.details.date.split("T")[0]}
            <br />
            <div style={{ paddingRight: "1rem" }}>
              {props.details.cost}
              <br />
              {props.details.status === "Processing" ||
              props.details.status === "Sent" ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setRequestDetails(props.details)}
                >
                  {/* <Link to={"/account/requests/detail?index=" + props.index+"&company="+props.companyDetails.name}>For Purchase</Link> */}
                  <a>For purchase</a>
                </Button>
              ) : props.details.acceptedBy === props.companyDetails.email ? (
                <Button
                  variant="contained"
                  color={"success"}
                  sx={{ backgroundColor: "green", color: "white" }}
                  size="small"
                  onClick={() => setRequestDetails(props.details)}
                >
                  {" "}
                  <a>Purchased</a>
                </Button>
              ) : (
                <Button variant="outlined" color="error" size="small">
                  Sold out
                </Button>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {" "}
          <RequestDrop list={props.details} />{" "}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Requests;
