import React, { useState } from "react";
import "./ServicesRenovated.css";
import { useTranslation } from "react-i18next";
import InteriorDesign from "../../images/images/interior_design.jpg";
import KitchenBuilder from "../../images/images/kitchenbuilder.jpg";
import FurnitureDesign from "../../images/images/furnituredesign.png";
import BathroomBuilder from "../../images/images/bathroom_builder.png";
import FloorLayers from "../../images/images/floorlayers.png";
import DoorsWindows from "../../images/images/doorsandwindows.jpg"

const ServicesRenovated = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const { t } = useTranslation();

  // Define a function to handle card click
  const handleCardClick = (index) => {
    setActiveCardIndex(index === activeCardIndex ? null : index);
  };

  return (
  <>
   {/* <div className="header-edit">
      <h1> Builder and Reconstructions <br/> Offers These Services </h1>
   </div> */}
    <div className="grid-card">
      {/* Interior Design */}
      
      <div className="card-wrapper">
        <div className="card" style={{ backgroundImage: `url(${InteriorDesign})` }} >
          <div className="overlay" onClick={() => handleCardClick(0)}>
            <h3>{t("interiordesign")}</h3>
          </div>
        </div>
        {activeCardIndex === 0 && (
          <div className="long-text">
            <p className="description-services">{t("intdesigndesc")}</p>
          </div>
        )}
      </div>

      {/* Kitchen Builder */}
      <div className="card-wrapper" onClick={() => handleCardClick(1)}>
        <div className="card" style={{ backgroundImage: `url(${KitchenBuilder})` }}>
          <div className="overlay">
            <h3>{t("kitchenbuilder")}</h3>
          </div>
        </div>
        {activeCardIndex === 1 && (
          <div className="long-text">
            <p className="description-services">{t("kitchenbuilddesc")}</p>
          </div>
        )}
      </div>

      {/* Bathroom Builder */}
      <div className="card-wrapper" onClick={() => handleCardClick(3)}>
        <div className="card" style={{ backgroundImage: `url(${BathroomBuilder})` }}>
          <div className="overlay">
            <h3>{t("bathroombuilder")}</h3>
          </div>
        </div>
        {activeCardIndex === 3 && (
          <div className="long-text">
            <p className="description-services">{t("bathroombuilddesc")}</p>
          </div>
        )}
      </div>

      {/* Doors and Windows */}
      <div className="card-wrapper" onClick={() => handleCardClick(5)}>
        <div className="card" style={{ backgroundImage: `url(${DoorsWindows})` }}>
          <div className="overlay">
            <h3>{t("doorsandwindows")}</h3>
          </div>
        </div>
        {activeCardIndex === 5 && (
          <div className="long-text">
            <p className="description-services">{t("doorswindowsdesc")}</p>
          </div>
        )}
      </div>

      {/* Floor Layers */}
      <div className="card-wrapper" onClick={() => handleCardClick(4)}>
        <div className="card" style={{ backgroundImage: `url(${FloorLayers})` }}>
          <div className="overlay">
            <h3>{t("floorlayers")}</h3>
          </div>
        </div>
        {activeCardIndex === 4 && (
          <div className="long-text">
            <p className="description-services">{t("floorlayersdesc")}</p>
          </div>
        )}
      </div>

      {/* Furniture Design */}
      <div className="card-wrapper" onClick={() => handleCardClick(2)}>
        <div className="card" style={{ backgroundImage: `url(${FurnitureDesign})` }}>
          <div className="overlay">
            <h3>{t("furnituredesign")}</h3>
          </div>
        </div>
        {activeCardIndex === 2 && (
          <div className="long-text">
            <p className="description-services">{t("furnituredesigndesc")}</p>
          </div>
        )}
      </div>
      
    </div>
    </>
  );
};

export default ServicesRenovated;
