import React from 'react';
import './ServicesOffered.css';
import MediaQuery from 'react-responsive';

const ServicesOffered = (props) => {
   return (
      <>
         <MediaQuery minWidth={1026}>
            <div className="container cleaning-container mt-10 mb-6">
               <span className="sub-heading hidden">{props.subHeading}</span>
               <div className="main-heading mb-4 cleaning-heading">{props.heading} <span className="font-blue" style={{ fontWeight: "bold" }}>Easy-compare </span>{props.headEnd}</div>

               
                  <div className="cleaningOffers-container">
                     {props.children}
                  </div>

            </div>
         </MediaQuery>


            <MediaQuery maxWidth={1024}>
            <div className="cleaning-container mt-10 mb-6">
               <span className="sub-heading hidden">{props.subHeading}</span>
               <div className="main-heading-custom mb-4 cleaning-heading">{props.heading} <span className="font-blue" style={{ fontWeight: "bold" }}>Easy-compare </span>{props.headEnd}</div>

               
               <div className="cleaningOffers-container-mobile">
                  {props.children}
               </div>

            </div>
               
            </MediaQuery>


         
      </>
   )
}
export default ServicesOffered;
