import React from "react";
import Navbar from "./common/Navbar";
import { useTranslation } from 'react-i18next';
import './TermsConditions.css';
import { Card } from "@mui/material";
// import OldPaper from '../images/old-paper-5.jpg';
import { currentLang } from "./common/Navbar";
import TermsConditions_EN from '../utils/TermsPolicy/TermConditions/TermsConditions_EN.json';
import TermsConditions_DE from '../utils/TermsPolicy/TermConditions/TermsConditions_DE.json';
import TermsConditions_FR from '../utils/TermsPolicy/TermConditions/TermsConditions_FR.json';
import TermsConditions_IT from '../utils/TermsPolicy/TermConditions/TermsConditions_IT.json';

const Terms = () => {
    const { t } = useTranslation();

    let TermsConditions;
    switch (currentLang) {
        case 'en':
            TermsConditions = TermsConditions_EN;
            break;
        case 'de':
            TermsConditions = TermsConditions_DE;
            break;
        case 'fr':
            TermsConditions = TermsConditions_FR;
            break;
        case 'it':
            TermsConditions = TermsConditions_IT;
            break;
        default:
            // Default to English if current language is not recognized
            TermsConditions = TermsConditions_EN;
            break;
    }

    return (
        <>
            <div className="hero-about-us">
                <Navbar />
            </div>

            <header>
                <h1>{t("Our-Terms")}</h1>
                {/* <p className="extras">{t("subheading1")}</p>
                <p className="extras">{t("proficiency-msg")}</p> */}
            </header>

            <Card>
                <div className="parent-terms">
                    <div className="outline" style={{backgroundColor: "white"}}>
                        <div className="intro-header">
                            <h2>{TermsConditions["Scope_of_application"]["1"]}</h2>
                        </div>
                        
                        <div className="terms-content">
                            <ol>

                                <li>
                                    <h3>{t("Scope of application")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Scope_of_application"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Scope_of_application"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Use of the platform by the client")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Use_of_the_platform_by_the_client"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Use_of_the_platform_by_the_client"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Content provided by the client")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Content_provided_by_the_client"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Content_provided_by_the_client"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Use of the platform by the contractor")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Use_of_the_platform_by_the_contractor"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Use_of_the_platform_by_the_contractor"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Costs for the contractor")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Costs_for_the_contractor"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Costs_for_the_contractor"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Credit and payment from the contractor")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Credit_and_payment_from_the_contractor"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Credit_and_payment_from_the_contractor"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Purchase of requests by the contractor")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Purchase_of_requests_by_the_contractor"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Purchase_of_requests_by_the_contractor"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Liability for the condition of the client's contact details")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Liability_for_the_condition_of_the_client_contact_details"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Liability_for_the_condition_of_the_client_contact_details"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Data protection of the client by the contractor")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Data_protection_of_the_client_by_the_contractor"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Data_protection_of_the_client_by_the_contractor"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Obligations of the Contractor towards the Client")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Obligations_of_the_Contractor_towards_the_Client"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Obligations_of_the_Contractor_towards_the_Client"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Data protection of the client by the contractor")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Use_of_the_platform_by_the_client"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Use_of_the_platform_by_the_client"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Unauthorized use of the platform")}</h3>
                                    <ol start="1">
                                        {Object.entries(TermsConditions["Unauthorized_use_of_the_platform"]).map(([key, value]) => (
                                            <li key={key}>
                                                {key !== "forbidden_actions" ? (
                                                    <p className="paragraphs">{value}</p>
                                                ) : (
                                                    <>
                                                        <p className="paragraphs">{t("Forbidden actions:")}</p>
                                                        <ul>
                                                            {Object.entries(value).map(([actionKey, actionValue]) => (
                                                                <li key={actionKey}>
                                                                    <p className="paragraphs">{actionValue}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Disclaimer of Easy-Compare")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Disclaimer_of_EasyCompare"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Disclaimer_of_EasyCompare"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("Data protection")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["Data_protection"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["Data_protection"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h3>{t("General Conditions")}</h3>
                                    <ol start="1">
                                        {Object.keys(TermsConditions["General_Conditions"]).map((key) => (
                                            <li key={key}>
                                                <p className="paragraphs">{TermsConditions["General_Conditions"][key]}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </li>

                                
                                {/* Repeat the above pattern for other sections */}
                            </ol>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default Terms;
