import { createSlice } from "@reduxjs/toolkit";

const setRequest= createSlice({
   name:"setRequest",
   initialState:{
      selectedRequest:{}
   },
   reducers:{
      setRequest(state,action){
         state.selectedRequest=action.payload;
      }
   }
})

export default setRequest.reducer;
export const setRequestAction=setRequest.actions;