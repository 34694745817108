import { useSelector } from "react-redux";
import "./index.css";
import { useDispatch } from "react-redux";
import { requestFilterAction } from "../../../redux/reducers/requestFilterReducer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Moving from "../Moving/Moving";
import CleaningFilter from "../Cleaning/Cleaning";
import PaintingFilter from "../Painting/Painting";
import CarpenterFilter from "../Carpenter/Carpenter";

const RequestFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companyDetails = useSelector((state) => state.companyReducer);

  const filterTags = useSelector((state) => state.requestFilterReducer.filters);

  const categoryHandler = (event) => {
    if (event.target.checked) {
      dispatch(
        requestFilterAction.updateFiltersCategory([
          ...filterTags.category,
          event.target.value,
        ]),
      );
    } else {
      dispatch(
        requestFilterAction.updateFiltersCategory(
          filterTags.category.filter(
            (filterTag) => filterTag !== event.target.value,
          ),
        ),
      );
    }
  };
  const focusHandler = (event) => {
    if (event.target.checked) {
      dispatch(
        requestFilterAction.updateFiltersFocus([
          ...filterTags.focus,
          event.target.value,
        ]),
      );
    } else {
      dispatch(
        requestFilterAction.updateFiltersFocus(
          filterTags.focus.filter(
            (filterTag) => filterTag !== event.target.value,
          ),
        ),
      );
    }
  };
  const requestStatusHandler = (event) => {
    if (event.target.checked) {
      dispatch(
        requestFilterAction.updateFiltersRequestStatus([
          ...filterTags.requestStatus,
          event.target.value,
        ]),
      );
    } else {
      dispatch(
        requestFilterAction.updateFiltersRequestStatus(
          filterTags.requestStatus.filter(
            (filterTag) => filterTag !== event.target.value,
          ),
        ),
      );
    }
  };

  const handelReset = (event) => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
    dispatch(requestFilterAction.resetFilters());
  };

  const [selectedCategory, setSelectedCategory] = useState("Moving");
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setSelectedCategory(category);
  };

  let selectedFilterComponent;

  switch (selectedCategory) {
    case "Moving":
      selectedFilterComponent = <Moving />;
      break;
    case "Cleaning":
      selectedFilterComponent = <CleaningFilter />;
      break;
    case "Painting":
      selectedFilterComponent = <PaintingFilter />;
      break;
    case "Construct":
      selectedFilterComponent = <CarpenterFilter />;
      break;
    default:
      selectedFilterComponent = null;
  }

  // Check if the selectedCategory is included in the company's expertises
  const isExpertiseAvailable =
    companyDetails.expertise.includes(selectedCategory);

  return (
    <>
      <div className="filter-section">
        <div className="FilterNavbar">
          {companyDetails.expertise.includes("Moving") && (
            <div
              onClick={() => handleCategoryChange("Moving")}
              className={selectedCategory === "Moving" ? "actived" : ""}
            >
             {t("Moving")}
            </div>
          )}
          {companyDetails.expertise.includes("Cleaning") && (
            <div
              onClick={() => handleCategoryChange("Cleaning")}
              className={selectedCategory === "Cleaning" ? "actived" : ""}
            >
             {t("Cleaning")}
            </div>
          )}
          {companyDetails.expertise.includes("Painting") && (
            <div
              onClick={() => handleCategoryChange("Painting")}
              className={selectedCategory === "Painting" ? "actived" : ""}
            >
              {t("Painting")}
            </div>
          )}
          {companyDetails.expertise.includes("Construct") && (
            <div
              onClick={() => handleCategoryChange("Construct")}
              className={selectedCategory === "Carpenter" ? "actived" : ""}
            >
              {t("Carpenter")}
            </div>
          )}
        </div>

        {isExpertiseAvailable && selectedFilterComponent}
      </div>
    </>
  );
};

export const ApplyFilter = (DATA, filterTags) => {
  console.log('Applying filter with data:', DATA);
  console.log('Filter tags:', filterTags);

  let filteredDATA = DATA.filter((request) => {
    if (filterTags.category.length) {
      return filterTags.category.includes(request.category.type);
    } else {
      return true;
    }
  });

  filteredDATA = filteredDATA.filter((request) => {
    if (filterTags.focus.length) {
      return filterTags.focus.includes(request.focus);
    } else {
      return true;
    }
  });

  filteredDATA = filteredDATA.filter((request) => {
    if (filterTags.requestStatus.length) {
      return filterTags.requestStatus.includes(request.status);
    } else {
      return true;
    }
  });

  console.log('Filtered Data:', filteredDATA);
  return filteredDATA;
};


export default RequestFilters;
