import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useNavigate } from "react-router-dom"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useEffect, useState,useMemo } from "react";
import { getAuthAxios } from '../../../utils/utils';
import "../AccountPage/index.css";
import { recordsPerPage } from '../../../utils/utils';
import '../../common/common.css';
import ReactPaginate from 'react-paginate';
import Loader from '../../common/Loader';
import { useDispatch } from "react-redux";
import { adminReducerAction } from '../../../redux/reducers/adminReducer';
const CompanyDetails = () => {
   const dispatch = useDispatch();
   const [companies, setCompanies] = useState(null);
   const navigate = useNavigate();
   const authAxios = getAuthAxios(navigate);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      setIsLoading(true);
      authAxios.get("/admin/getcompanies").then((response) => {
         if (response.status === 200) {
            setCompanies(response.data.companies);
            dispatch(adminReducerAction.setCompanies(response.data.companies));
            setIsLoading(false);
         }
         else {
            alert(JSON.stringify(response));
            setIsLoading(false);
         }
      }).catch((err) => {
         console.log("catch block CompanyDetails" + err);
      });
   }, []);



   const [startItemIndex, setStartItemIndex] = useState(0);
   const [pageCount, setPageCount] = useState();

   const currentItems = useMemo(() => {
      if (companies && companies.length !== 0) {
         setPageCount(Math.ceil(companies.length / recordsPerPage));
         const endItemIndex = startItemIndex + recordsPerPage;
         // console.log(`Loading items from ${startItemIndex} to ${endItemIndex}`);
         return companies.slice(startItemIndex, endItemIndex);
      } else {
         return companies;
      }
   }, [companies, startItemIndex])

   const handlePageClick = (event) => {
      const newStartIndex = (event.selected * recordsPerPage) % companies.length;
      // console.log(`User requested page number ${event.selected}, which is offset ${newStartIndex}`);
      setStartItemIndex(newStartIndex);

   };


   return <>
      <div>
         {(companies && companies.length !== 0) ?
            (<div className="accordian-request-container">
               {currentItems.map((list, index) => (
                  <Accordian key={index} details={list} index={index} />
               ))}
            </div>)
            : (isLoading &&  <Loader /> )
         }
      </div>
      <div className='custom-paginate'>
         <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
         />
      </div>
   </>
}


const Accordian = (props) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const authAxios = getAuthAxios(navigate);
   const [details, setDetails] = useState(props.details);
   const handleDeactivate = () => {
      if (window.confirm(("Are you sure You want to deactivate this account ?-" + details.name))) {
         authAxios.get("/admin/deactivate", { params: { email: details.email } }).then((response) => {
            if (response.status === 200) {
               alert("The account has been deactivated successfully.")
               setDetails(preVal => {
                  return { ...preVal, active: false }
               });
            }
            else {
               alert(JSON.stringify(response))
            }
         }).catch((err) => {
            alert("catch block" + err);
         });
      }
   }
   const handleReactivate = () => {
      if (window.confirm(("Are you sure You want to Activate this account ?-" + details.name))) {
         authAxios.get("/admin/reactivate", { params: { email: details.email } }).then((response) => {
            if (response.status === 200) {
               alert("The account has been activated successfully.")
               setDetails(preVal => {
                  return { ...preVal, active: true }
               });
            }
            else {
               alert(JSON.stringify(response))
            }
         }).catch((err) => {
            alert("catch block" + err);
         });
      }
   }
   const handleDeleteAccount = () => {
      if (window.confirm(("Are you sure you want to delete this account permanently -" + details.name))) {
         authAxios.delete("/admin/company", { params: { email: details.email } }).then((response) => {
            if (response.status === 200) {
               alert("The account has been deleted successfully.")
               setDetails(preVal => {
                  return { ...preVal, deleted: true }
               });
            }
            else {
               alert(JSON.stringify(response))
            }
         }).catch((err) => {
            alert("catch block" + err);
         });
      }
   }
   const setCurrentCompany = (details) => {
      dispatch(adminReducerAction.setCurrentCompany(details));
      navigate("/admin/companydetails/companyrequests?company=" + details.email)
   }
   return <>
      <Accordion className="Accordion">
         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="accordion-button">
            <div className="header-content admin-active">
               {details.name}
               <br></br>
               {details.email} :  {details.contactNumber}
               <br />
               <div style={{ paddingRight: "1rem" }}>
                  <Button variant="contained" size="small">{details.active ? "Active" : details.deleted ? "Deleted" : "Inactive"}</Button>
               </div>
            </div>
         </AccordionSummary>
         <AccordionDetails >
            <div style={{ width: "100%" }}>
               <Box sx={{ flexGrow: 1 }} >
                  <Grid container spacing={2} sx={{ mb: "1rem" }}>
                     <Grid item xs={3} sx={{ fontWeight: "900" }}>Commercial Register Id</Grid>
                     <Grid item xs={9} >{details.email}</Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: "1rem" }}>
                     <Grid item xs={3} sx={{ fontWeight: "900" }}>Website</Grid>
                     <Grid item xs={9}>{details.website}</Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: "1rem" }}>
                     <Grid item xs={3} sx={{ fontWeight: "900" }}>Address</Grid>
                     {/* <Grid item xs={9}>{details.address.location}</Grid> */}
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: "1rem" }}>
                     <Grid item xs={3} sx={{ fontWeight: "900" }}>Post Code</Grid>
                     {/* <Grid item xs={9}>{details.address.postCode}</Grid> */}
                  </Grid>
               </Box>

               <div className='admin-btn' style={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
                  <Button onClick={() => setCurrentCompany(details)} color={"success"} variant="contained" sx={{ alignSelf: "center" }}>
                     {/* <Link to={"/admin/companydetails/companyrequests?index=" + props.index+"&company="+details.email}>View Requests</Link> */}
                     <a>View Requests</a>
                  </Button>
                  {
                     details.active ? <Button onClick={handleDeactivate} color={"warning"} variant="contained" sx={{ alignSelf: "center" }}>Deactivate
                     </Button> : <Button onClick={handleReactivate} color={"success"} variant="contained" sx={{ alignSelf: "center" }}>Reactivate</Button>
                  }

                  <Button onClick={handleDeleteAccount} color={"error"} variant="contained" sx={{ alignSelf: "center" }}>Delete</Button>
               </div>
            </div>
         </AccordionDetails>
      </Accordion>
   </>
}
export default CompanyDetails;

