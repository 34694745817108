import { NavLink } from "react-router-dom";
import "../AccountPage/index.css";
import { useTranslation } from "react-i18next";
const ProfileMenu = () => {
  const { t } = useTranslation();
  return (
    <>
      <ul className="AccountMenu">
        <NavLink to="/account/profile/" className="text-decoration-none">
          <div className="rounded-0 border py-2">{t("edit-profile")}</div>
        </NavLink>
        <NavLink
          to="/account/profile/profilegallery"
          className="text-decoration-none"
        >
          <div className="rounded-0 border py-2">{t("gallery")}</div>
        </NavLink>
        {/* <NavLink to="/account/profile/profilespeciality" className="text-decoration-none"><div className="rounded-0 border py-2">Add Speciality</div></NavLink> */}
        <NavLink
          to="/account/profile/profilereviews"
          className="text-decoration-none"
        >
          <div className="rounded-0 border py-2">{t("rating")}</div>
        </NavLink>
        <NavLink
          to="/account/profile/profilenotification"
          className="text-decoration-none"
        >
          <div className="rounded-0 border py-2">{t("notification")}</div>
        </NavLink>
        <NavLink
          to="/account/profile/profilechangepassword"
          className="text-decoration-none"
        >
          <div className="rounded-0 border py-2">{t("change-password")}</div>
        </NavLink>
      </ul>
    </>
  );
};

export default ProfileMenu;
