import { combineReducers } from "redux"
import requestFilterReducer from "./requestFilterReducer";
import requestsReducer from "./requestsReducer"
import companyReducer from "./companyReducer"
import jwtReducer from "./jwtReducer";
import adminReducer from "./adminReducer"
import setRequest from "./setRequest"
export default combineReducers({
    requestsReducer : requestsReducer,
    companyReducer : companyReducer,
    jwtReducer : jwtReducer,
    adminReducer:adminReducer,
    setRequest:setRequest,
    requestFilterReducer:requestFilterReducer,
});
