import React from "react";
import tickmarkImg from '../../images/tickmarkImg.svg';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import MediaQuery from "react-responsive";

import './CleaningAccordian.css';
import { useTranslation } from 'react-i18next'
const CleaningAccordian = (props) => {
   const { t } = useTranslation();

   return <>

<MediaQuery minWidth={1026}>
      <div className="container accordian-container mt-10 mb-6">
         <span className="sub-heading hidden"> {t("word-Privacy")}</span>
         <div className="main-heading-custom-second mb-4" style={{ fontWeight: "bold" }}>{t("Cmp-Contract-Agr")}</div>
         {
            Object.keys(props.accordionHeading).map((jsonKey, index) => {
               return <Accordian accordionHeading={jsonKey} accordionContent={(props.accordionHeading)[jsonKey]} key={index}>
               </Accordian>;
            })
         }
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
      <div className="accordian-container-mobile mt-10 mb-6">
         <span className="sub-heading hidden"> {t("word-Privacy")}</span>
         <div className="main-heading mb-4 " style={{ fontWeight: "bold" }}>{t("Cmp-Contract-Agr")}</div>
         {
            Object.keys(props.accordionHeading).map((jsonKey, index) => {
               return <Accordian accordionHeading={jsonKey} accordionContent={(props.accordionHeading)[jsonKey]} key={index}>
               </Accordian>;
            })
         }

      </div>
      </MediaQuery>
   </>
}

const Accordian = (props) => {
   return <>
      <Accordion className="Accordion">
         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="accordion-button">
            <Typography >
               {props.accordionHeading}
            </Typography>
         </AccordionSummary>
         {
            (props.accordionContent).map((value, index) => {
               return <AccordionDetails key={index}>
                  <div className="accordian-content">
                     <img className="tickmarkImg" src={tickmarkImg} alt="tickmarkImg" />
                     <p>{value}</p>
                  </div>
               </AccordionDetails>;
            })
         }
      </Accordion>
   </>
}
export default CleaningAccordian;