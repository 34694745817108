import React, { useState } from "react";
import "./ContactUs.css";
import contactusimage from "../../images/contact_us.jpg";
import AccountHeader from "../Account/AccountPage/AccountHeader";
import Navbar from "../common/Navbar";
import { Card } from "@mui/material";
import { useTranslation } from 'react-i18next'
const ContactUs = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to open the user's default email client
  const handleMailToSubmit = () => {
    const { name, email, subject, message } = formData;
    const mailtoLink = `mailto:info@easy-compare.ch?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}%0AEmail: ${email}%0ASubject: ${subject}%0AMessage: ${message}`)}`;
    window.location.href = mailtoLink;
  };

  const { t } = useTranslation(); 

  return (
    <>
    <div className="hero-contact-us">
    <Navbar />
    </div>
    <div className="contact-us-image"></div>
<div className="contact-us-header">
  <div className="header-content">
    <h1>{t("contact-us")}</h1>
    <h3>{t("contact-us-msg")}</h3>
  </div>
</div>
      <div className="contact-us-container">
        {/* <Card> */}
        <div className="contact-info">
    <div className="info-section">
      <div className="icon">📍</div>
      <p className="info-text">Greifenseestrasse 25 8604 Volketswil-CH </p>
    </div>
    <div className="info-section">
      <div className="icon">📞</div>
      <p className="info-text">+41 43 535 65 43</p>
    </div>
    <div className="info-section">
      <div className="icon">✉️</div>
      <p className="info-text">info@easy-compare.ch</p>
    </div>
  </div>
        {/* </Card> */}
        <div className="contact-form">
    <form onSubmit={handleMailToSubmit}>
            <div className="form-group">
              <label htmlFor="name">{t("Name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">{t("Email")}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="subject">{t("Subject")}</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">{t("Message")}</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>

            <button type="submit">{t("submit-btn")}</button>
          </form>
        </div>
      </div>

      <div className="spacer"></div>
    </>
  );
};

export default ContactUs;
