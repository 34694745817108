import './App.css';
import "@fontsource/rubik";
import {Routes,Route,BrowserRouter} from 'react-router-dom';
import Moving from "./components/MovingPage/Moving";
import Cleaning from "./components/CleaningPage/Cleaning";
import Painting from "./components/PaintingPage/Painting";
import Carpenter from "./components/CarpenterPage/Carpenter";
import Login from './components/LoginPage/Login';
import Registration from './components/RegistrationPage/Registration';
import UserRegistration from './components/User/Registration';
import ServiceRequest from './components/User/ServiceRequest';
import AccountPage from "./components/Account/AccountPage";
import Admin from './components/Account/Admin';
import UserAccount from './components/User/Account';
import PrivacyPolicyContent from './components/common/PrivacyPolicyContent';
import TermsCondition from './components/common/TermsCondition';
import "./styles/styles.css";
import ContactUs from './components/ContactUs/ContactUs';
import AboutUs from './components/AboutUs/AboutUs';
import Terms from './components/TermsConditions';
import PrivacyAndPolicy from './components/PrivacyAndPolicy';
// import TipsTricks from './components/TipsTricks/TipsTricks';
// import PDFViewer from './components/TipsTricks/PDFViewer';
// import Rebrush from './Rebrush';
import Footer from './components/common/Footer';
import AdminLogin from './components/Account/Admin/AdminLogin';
import NewPasswordRoute from './components/ResetPassword/NewPasswordRoute';
function App() {
  return <>
    <BrowserRouter>
      <Routes>
        {/* <Route exact path='/' element={<Rebrush/>}/> */}
        <Route exact path='/' element={<Moving/>}/>
        <Route exact path='/Cleaning' element={<Cleaning/>}/>
        <Route exact path='/Painting' element={<Painting/>}/>
        <Route exact path='/Carpenter' element={<Carpenter/>}/>
        <Route exact path='/Login' element={<Login/>}/>
        <Route exact path='/AdminLogin' element={<AdminLogin/>}/>
        <Route exact path='/Registration' element={<Registration/>}/>
        <Route exact path='/User/Registration' element={<UserRegistration/>}/>
        <Route exact path='/User/ServiceRequest' element={<ServiceRequest/>}/>
        <Route path="/account/*" element={<AccountPage />} />
        <Route path="/admin/*" element={<Admin/>} />
        <Route path="/user/*" element={<UserAccount/>} />
        <Route path="/PrivacyPolicyContent" element={<PrivacyAndPolicy/>} />
        <Route path="/TermsCondition" element={<Terms/>} />
        <Route path="/contactus" element={<ContactUs/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/new-password" element={<NewPasswordRoute/>} />
        {/* <Route path="/tipsandtricks" element={<PDFViewer/>} /> */}
      </Routes>
   </BrowserRouter>
    <Footer/>
  </>;
}

export default App;
