import Axios from "axios";
import { useSelector } from "react-redux";
import "../../RegistrationPage/Registration.css";
import "./index.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import usePlacesAutocomplete, {
  getGeocode,
  getDetails,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { emailRegex } from "../../../utils/utils";
import "@reach/combobox/styles.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  // address geocode
  const [addressflag, setAddresflag] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const requestsData = useSelector((state) => state.companyReducer);
  const [regDetails, setRegDetails] = useState(requestsData);
  

  const handleSelect = (val) => {
    setAddresflag(false);
    const parameter = {
      address: val,
    };
  };
  // const inputEvent = (e) => {
  //   //validations
  //   const { name } = e.target;
   

  //   const inputName = e.target.name;

  //   if (inputName === "location" || inputName === "postCode") {
  //     setRegDetails((prevValues) => {
  //       return {
  //         ...prevValues,
  //         address: {
  //           ...prevValues.address,
  //           [e.target.name]: e.target.value,
  //         },
  //       };
  //     });
  //   } else {
  //     setRegDetails((prevValues) => {
  //       return { ...prevValues, [e.target.name]: e.target.value };
  //     });
  //   }
  // };
 
  const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (formvalid(formErrors)) {
  //     Axios.post(
  //       REACT_APP_BACKEND_SERVER2 + "/register/company",
  //       regDetails
  //     ).then((response) => {
  //       if (response.data.response_code === "200") {
  //         alert(t("req-success"));
  //       } else {
  //         console.log("failed request at ID 021938892");
  //       }
  //     });
  //   } else {
  //     console.error("Form Invalid - Display Error Message");
  //   }
  // };

  // const handleSubmitUpdate = (event) => {
  //   event.preventDefault();
  //   if (formvalid(formErrors)) {
  //     Axios.put(
  //       REACT_APP_BACKEND_SERVER2 + "/register/company",
  //       regDetails
  //     ).then((response) => {
  //       if (response.data.response_code === "200") {
  //         alert(t("req-success"));
  //       } else {
  //         console.log("failed request at ID 021938892");
  //       }
  //     });
  //   } else {
  //     console.error("Form Invalid - Display Error Message");
  //   }
  // };

  return (
    <>
      <Card>
        <div className="Profile-container">
        <form>
          {/* <form onSubmit={handleSubmit}> */}
            <div className="reg-card">
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  name="name"
                  label={t("company-name")}
                  // value={regDetails.name}
                />
              </div>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  name="contactNumber"
                  label={t("bis-ph-no")}
                  // value={regDetails.contactNumber}
                />
              </div>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  name="email"
                  label="EMAIL"
                  // value={regDetails.email}
                />
              </div>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  name="commercialRegister"
                  label={t("comm-reg")}
                  // value={regDetails.commercialRegister}
                />
              </div>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  name="website"
                  label="WEBSITE"
                  // value={regDetails.website}
                />
              </div>

              <div className="address-div">
                <Combobox onSelect={handleSelect} aria-labelledby="demo">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="location"
                    label={t("com-address")}
                    autoFocus
                    // value={value}
                    // onChange={handleInput}
                    // disabled={!ready}
                    className={
                      addressflag === true
                        ? "border border-danger w-100"
                        : "w-100"
                    }
                  />
                  <ComboboxInput
                    style={{ visiblity: "hidden" }}
                    // value={value}
                    // onChange={handleInput}
                    // disabled={!ready}
                    className={
                      addressflag === true
                        ? "border border-danger w-100"
                        : "w-100"
                    }
                  />

                  <ComboboxPopover>
                    <ComboboxList>
                      {/* {status === "OK" &&
                        data.map(({ place_id, description }) => (
                          <ComboboxOption key={place_id} value={description} />
                          
                          <ComboboxOption key={place_id} />
                        ))} */}
                    </ComboboxList>
                  </ComboboxPopover>
                </Combobox>
              </div>

              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="postCode"
                label="POSTCODE"
                value={regDetails.postalCode}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </>
  );
};

export default EditProfile;
