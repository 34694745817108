// import {createStore,applyMiddleware} from 'redux'
// import thunk from 'redux-thunk'

import rootReducer from './reducers'

// const initialstate={}

// const middleware = [thunk]

// const store = createStore( rootReducer , initialstate , applyMiddleware(...middleware))

// export default store

// import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import sessionStorage from 'redux-persist/es/storage/session';
import { configureStore } from "@reduxjs/toolkit";
import requestFilterReducer from './reducers/requestFilterReducer';
// import CookieStorage from 'redux-persist-cookie-storage/src/redux-persist-cookie-storage';
// import { Cookie } from '@mui/icons-material';
// import Cookies from 'js-cookie';
 
const persistConfig = {
   key: 'root',
   storage:sessionStorage,
   // storage:new CookieStorage(Cookies),
 }

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  requestFilter: requestFilterReducer,
  middleware: [thunk]
})
export const persistor = persistStore(store)


 
