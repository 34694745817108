import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useEffect, useState, useMemo } from "react";
import { getAuthAxios, colorsArray, sessionCheck } from "../../../utils/utils";
import "./index.css";
import { useDispatch } from "react-redux";
import { getRequestAction } from "../../../redux/actions/getRequestAction";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { recordsPerPage } from "../../../utils/utils";
import "../../common/common.css";
import ReactPaginate from "react-paginate";
import Loader from "../../common/Loader";

const Requests = () => {
  const dispatch = useDispatch();
  const requestsData = useSelector((state) => state.companyReducer);
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequest] = useState();

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get("/request")
      .then((response) => {
        if (response.status === 200) {
          setRequest(response.data.requests);
          dispatch(getRequestAction(response.data.requests));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (sessionCheck) {
          navigate("/Login");
          setIsLoading(false);
        }
      });
  }, []);

  const [startItemIndex, setStartItemIndex] = useState(0);
  const [pageCount, setPageCount] = useState();

  const currentItems = useMemo(() => {
    if (requests !== undefined && requests.length !== 0) {
      setPageCount(Math.ceil(requests.length / recordsPerPage));
      const endItemIndex = startItemIndex + recordsPerPage;
      return requests.slice(startItemIndex, endItemIndex);
    } else {
      return requests;
    }
  }, [requests, startItemIndex]);

  const handlePageClick = (event) => {
    const newStartIndex = (event.selected * recordsPerPage) % requests.length;
    setStartItemIndex(newStartIndex);
  };

  return (
    <>
      <div>
        {requests !== undefined &&
        requests !== null &&
        requests.length !== 0 ? (
          <div className="accordian-request-container">
            {currentItems.map((list, index) => (
              <Accordian
                key={index}
                details={list}
                index={index}
                requestsData={requestsData}
              />
            ))}
          </div>
        ) : (
          (isLoading &&  <Loader /> )
        )}
      </div>
      <div className="custom-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

const Accordian = (props) => {
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const [images, setImages] = useState([]);

  useEffect(() => {
    authAxios
      .get(`/request/${props.details.id}/images`)
      .then((response) => {
        if (response.status === 200) {
          setImages(response.data.images);
        } else {
          console.log("Smth else happened at ID 128372");
        }
      })
      .catch((err) => {
        if (sessionCheck) {
          navigate("/Login");
        }
      });
  }, []);
  return (
    <>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          className="accordion-button"
        >
          <div className="header-content">
            {props.details.category.type} in {props.details.companyPlace}
            <br></br>
            {props.details.date.split("T")[0]}
            <br />
            <div
              style={{
                paddingRight: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              Request Status
              <br />
              {props.details.status === "Processing" ? (
                <span
                  className="btn"
                  style={{ backgroundColor: "magenta", color: "white" }}
                >
                  {props.details.status}
                </span>
              ) : props.details.status === "Sent" ? (
                <span
                  className="btn"
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  {props.details.status}
                </span>
              ) : (
                <span
                  className="btn"
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  {props.details.status}
                </span>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box style={{ flexGrow: 1 }}>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Accepted By Company
              </Grid>
              <Grid item xs={9}>
                {props.details.acceptedBy === null
                  ? "Processing your request"
                  : props.details.acceptedBy}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Category
              </Grid>
              <Grid item xs={9}>
                {props.details.category.type}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Request For
              </Grid>
              <Grid item xs={9}>
                {props.details.requestType}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Description
              </Grid>
              <Grid item xs={9}>
                {props.details.description}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Areas
              </Grid>
              <Grid item xs={9}>
                {props.details.areas.map((val, index) => {
                  return (
                    <>
                      <span
                        className=""
                        key={index}
                        style={{
                          background: colorsArray[index % 5],
                          padding: "1px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          color: "white",
                          marginLeft: "0px",
                        }}
                      >
                        {val}
                      </span>
                    </>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Extras
              </Grid>
              <Grid item xs={9}>
                {props.details.extras.split(" ").map((val, index) => {
                  return val !== "" ? (
                    <span
                      className=""
                      key={index}
                      style={{
                        background: colorsArray[index % 5],
                        padding: "1px 8px",
                        borderRadius: "5px",
                        margin: "5px",
                        color: "white",
                        marginLeft: "0px",
                      }}
                    >
                      {val}
                    </span>
                  ) : (
                    <></>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Name
              </Grid>
              <Grid item xs={9}>
                {props.details.user.firstname} {props.details.user.lastname}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Contact Number
              </Grid>
              <Grid item xs={9}>
                {props.details.user.contactNo}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Email
              </Grid>
              <Grid item xs={9}>
                {props.details.user.email}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
              {props.details.category.type==="Cleaning"?"Number of room":props.details.category.type==="Moving"?"Number of floors":props.details.category.type==="Painting"?"Number of walls to paint":props.details.category.type==="Carpenter"?"Number of area to carpent":"Incorrect request type"}
              </Grid>
              <Grid item xs={9}>
                {props.details.numberOfRooms} rooms
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Object area
              </Grid>
              <Grid item xs={9}>
                {props.details.objectArea} m2
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Pollution
              </Grid>
              <Grid item xs={9}>
                {props.details.pollution}
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Distance
              </Grid>
              <Grid item xs={9}>
                {props.details.distance}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Focus
              </Grid>
              <Grid item xs={9}>
                {props.details.focus}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Frequency
              </Grid>
              <Grid item xs={9}>
                {props.details.frequency}
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Offer Type
              </Grid>
              <Grid item xs={9}>
                {props.details.offerType}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Tel. reachable
              </Grid>
              <Grid item xs={9}>
                {props.details.reachableFrom}pm-{props.details.reachableTill}pm
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Discount
              </Grid>
              <Grid item xs={9}>
                {props.details.discount}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Address
              </Grid>
              <Grid item xs={9}>
                {/* {props.details.address.location} <br />{" "}
                {props.details.address.postCode} */}
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mb: "0.2rem", mt: "1rem" }}>
              <Grid item xs={3} sx={{ fontWeight: "900" }}>
                Images
              </Grid>
              <Grid item xs={9}>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {images.length &&
                    images.map((image, index) => (
                      <img
                        key={index}
                        src={`${image}`}
                        alt={image.name}
                        style={{ height: "200px" }}
                      />
                    ))}
                </div>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default Requests;
