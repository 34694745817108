import React from 'react';
import Hero from '../common/Hero';
import ServiceModel from '../common/ServiceModel';
import ProcessSteps from "../ProcessSteps";
import ThreeStepCardComponent from "../ThreeStepCardComponent";
import FormIllustration from '../../images/FormIllustration.svg';
import OfferIllustration from '../../images/OfferIllustration.svg';
import OrderIllustration from '../../images/carpenter/CarpenterIconForOrder.svg';
import Vector3 from '../../images/Vector3.svg';
//import ServicesOffered from '../common/ServicesOffered';
//import OfferModel from '../common/OfferModel';
import Flex2fragments from '../layouts/Flex2fragments';
import HomeImage from '../../images/carpenter/carpentingHomeImage.png';
import carpenterIcon from '../../images/carpenter/carpenterIcon.png';
//import cabinateMaker from '../../images/carpenter/cabinateMaker.png';
//import carpetry from '../../images/carpenter/carpetry.png';
//import InteriorDesign from '../../images/carpenter/InteriorDesign.png';
//import joinery from '../../images/carpenter/joinery.png';
//import kitchenbuilder from '../../images/carpenter/kitchenbuilder.png';
import ServiceInfo from './ServiceInfo';
import EmployeeOffer from './EmployeeOffer';
import CarpenterWorkingProcess from './CarpenterWorkingProcess';
import whatWorkDoes from '../../images/carpenter/whatWorkDoes.png';
import infoAboutCarpenter from '../../images/carpenter/infoAboutCarpenter.png';
import ReadMoreComponent from '../common/ReadMoreComponent';
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive';
import AdvantagesComponent from '../AdvantagesComponent';
import Testimonials from '../Testimonials';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import ServicesRenovated from '../common/ServicesOffered_Revonvated';
import PDFViewer from './PDFViewerConstruct';

const Carpenter = () => {
   const navigate = useNavigate();
   const [showScrollButton, setShowScrollButton] = useState(false);
   const { t } = useTranslation();
   
   useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const handleScrollToTop = () => {
      navigate("/User/ServiceRequest?requestType=Carpenter");
    };


   return <>

      {showScrollButton && (
        <div className="scroll-button" onClick={handleScrollToTop}>
          <h3>Start Request</h3>
        </div>
      )}

      <Hero image={HomeImage}>
         <ServiceModel Serviceaction={t("Carpenter-Navbar-Label")} ServiceCardIcon={carpenterIcon} ServiceDescription={t("Carpenter-Navbar-Desc")} selopt={"Carpenter"} />
      </Hero>
      <MediaQuery minWidth={1026}>
         <ProcessSteps label={t("Carpent-Navbar-TagLine")}>
            <ThreeStepCardComponent svg={FormIllustration} title={t('Process-step-1')} content={t("Process-step1-desc")} />
            <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
            <ThreeStepCardComponent svg={OfferIllustration} title={t('Process-step-2')} content={t("Process-step2-desc")} />
            <img className="FormVector2" src={Vector3} alt="Vector2 logo" />
            <ThreeStepCardComponent svg={OrderIllustration} title={t("Process-step-3")} content={t("Process-step3-desc")} />
         </ProcessSteps>
         <AdvantagesComponent />
      </MediaQuery>

      <MediaQuery maxWidth={1024}>
         <ProcessSteps label={t("Carpent-Navbar-TagLine")}>
            <ThreeStepCardComponent svg={FormIllustration} title={t('Process-step-1')} content={t("Process-step1-desc")} />
            {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
            <ThreeStepCardComponent svg={OfferIllustration} title={t('Process-step-2')} content={t("Process-step2-desc")} />
            {/* <img className="FormVector2" src={Vector3} alt="Vector2 logo" /> */}
            <ThreeStepCardComponent svg={OrderIllustration} title={t("Process-step-3")} content={t("Process-step3-desc")} />
         </ProcessSteps>
         <AdvantagesComponent />
      </MediaQuery>
      
      {/* <ServicesOffered subHeading={t("Carpenter-Service")} heading={t("CarpenterService-Heading")} headEnd={t("offers-word-paint")}>
         <OfferModel OfferIcon={carpenterIcon} OfferName={t("CarpenterServiceOffer1")} description={t("CarpenterServiceDesc1")} />
         <OfferModel OfferIcon={kitchenbuilder} OfferName={t("CarpenterServiceOffer2")} description={t("CarpenterServiceDesc2")} />
         <OfferModel OfferIcon={cabinateMaker} OfferName={t("CarpenterServiceOffer3")} description={t("CarpenterServiceDesc3")} />
         <OfferModel OfferIcon={InteriorDesign} OfferName={t("CarpenterServiceOffer4")} description={t("CarpenterServiceDesc4")} />
         <OfferModel OfferIcon={joinery} OfferName={t("CarpenterServiceOffer5")} description={t("CarpenterServiceDesc5")} />
         <OfferModel OfferIcon={carpetry} OfferName={t("CarpenterServiceOffer6")} description={t("CarpenterServiceDesc6")} />
      </ServicesOffered> */}
      <ServicesRenovated />
      {/* <Flex2fragments img={infoAboutCarpenter} classes={"mt-10"} flexReverse={0}>
         <ServiceInfo appendCls={"carpenter"} />
      </Flex2fragments>
      <Flex2fragments img={whatWorkDoes} classes={"mb-6"} flexReverse={1}>
         <EmployeeOffer OfferContent={[t("CarpenterOffer1"), t("CarpenterOffer2"), t("CarpenterOffer3"), t("CarpenterOffer4"), t("CarpenterOffer5"), t("CarpenterOffer6"), t("CarpenterOffer7"), t("CarpenterOffer8"), t("CarpenterOffer9"), t("CarpenterOffer10")]} />
      </Flex2fragments> */}
      <CarpenterWorkingProcess>
         <ReadMoreComponent Content={[["01", t("CarpenterWPTitle0"), t("CarpenterWPDesc0")],["02", t("CarpenterWPTitle1"), t("CarpenterWPDesc1")], ["03", t("CarpenterWPTitle2"), t("CarpenterWPDesc2")], ["04", t("CarpenterWPTitle3"), t("CarpenterWPDesc3")]]}/>
      </CarpenterWorkingProcess>

      <PDFViewer />

      {/* <Testimonials /> */}
   </>
}

export default Carpenter;