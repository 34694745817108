import React from "react";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAuthAxios } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ApplyFilter } from "../Requests/RequestFilters";
import RequestListing from "../Moving/RequestListing";
import "../Requests/index.css";
import { getDistance } from "geolib";
import "../Moving/Moving.css";
import MediaQuery from "react-responsive";

function CarpenterFilter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const authAxios = getAuthAxios(navigate);
  const companyDetails = useSelector((state) => state.companyReducer);
  const [filterTags, setFilterTags] = useState(
    useSelector((state) => state.requestFilterReducer.filters),
  );
  // ------------------------------------------------------------------
  const [priceChecked, setPriceChecked] = useState(false);
  const [qualityChecked, setQualityChecked] = useState(false);
  const [maxRadiusKm, setMaxRadiusKm] = useState(0);
  // ------------------------------------------------------------------
  const [handleAreaChangeValue, setHandleAreaChangeValue] = useState("");
  const [isHandleAreaChangeValue, setIsHandleAreaChangeValue] = useState(false);
  const [handleTypeChangeValue, setHandleTypeChangeValue] = useState("");
  const [isHandleTypeChangeValue, setIsHandleTypeChangeValue] = useState(false);
  const [handleFocusChangeValue, setHandleFocusChangeValue] = useState("");
  const [isHandleFocusChangeValue, setIsHandleFocusChangeValue] =
    useState(false);
  // ------------------------------------------------------------------
  const [area0_59Checked, setArea0_59Checked] = useState(false);
  const [area60_140Checked, setArea60_140Checked] = useState(false);
  const [area141Checked, setArea141Checked] = useState(false);
  const [area141MoreChecked, setArea141MoreChecked] = useState(false);
  // ------------------------------------------------------------------
  const [requests, setRequest] = useState([]);
  const [filteredData, setFilteredData] = useState();

  const [interiorDesign, setInteriorDesignChecked] = useState(false);
  const [bathroomAndKitches, setBathroomAndKitchesChecked] = useState(false);
  const [windowsAndDoors, setWindowsAndDoorsChecked] = useState(false);
  const [repairAndRestoration, setRepairAndRestorationChecked] = useState(false);
  const [isAnyAreaCheckboxChecked, setIsAnyAreaCheckboxChecked] = useState(false);
  // ------------------------------------------------------------------

  const [referencePoint, setReferencePoint] = useState({
    latitude: 41.327953,
    longitude: 19.819025,
  });

  function getUserLocation() {
    if ("geolocation" in navigator) {
      // Check if the user already granted permission
      const hasPermission =
        localStorage.getItem("geolocationPermission") === "granted";

      if (!hasPermission) {
        // Request permission and update localStorage
        navigator.geolocation.getCurrentPosition(function (position) {
          localStorage.setItem("geolocationPermission", "granted");
          setReferencePoint({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        });
      }
    } else {
      console.log("Geolocation is already enabled.");
    }
  }

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get("/company/request")
      .then((response) => {
        if (response.status === 200) {
          setRequest(response.data); // Set the entire response data
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error happened at ID 87129372");
      });
    getUserLocation();
  }, []);


  useEffect(() => {
    if (!isLoading && requests.length > 0) {
      console.log(requests);
      const filteredRequests = requests.filter((request) =>
        [
          "Interior Design",
          "Bathroom and Kitchens",
          "Windows and Doors",
          "Repair and Restoration",
        ].includes(request.requestType),
      );

      const filteredResults = filteredRequests.filter((request) => {
        return (
          // Area checkboxes
          (area0_59Checked &&
            request.objectArea >= 0 &&
            request.objectArea <= 59) ||
          (area60_140Checked &&
            request.objectArea >= 60 &&
            request.objectArea <= 140) ||
          (area141Checked && request.objectArea === 141) ||
          (area141MoreChecked && request.objectArea > 141) ||
          // Focus checkboxes
          (priceChecked && request.focus === "price") ||
          (qualityChecked && request.focus === "quality") ||
          // Moving checkboxes
          !interiorDesign ||
          request.requestType === "Interior Design" || "Innenausbau" || "Design d'intérieur" || "Design d'interni" ||
          !bathroomAndKitches ||
          request.requestType === "Bathroom and Kitchens" || request.requestType === "Bäder und Küchen" || request.requestType === "Costruttore di bagni" || 
          request.requestType === "Salle de bains et cuisines" ||
          !windowsAndDoors ||
          request.requestType === "Windows and Doors" || request.requestType === "Fenster und Türen" || request.requestType === "Fenêtres et portes" ||
          request.requestType === "Finestre e porte" ||
          !repairAndRestoration ||
          request.requestType === "Repair and Restoration" || request.requestType === "Reparatur und Restaurierung" || request.requestType === "Réparation et restauration" ||
          request.requestType === "Riparazione e restauro" ||
          ApplyFilter(requests, filterTags)
        ); // Consider the specified radius
      });
      setFilteredData(filteredResults);
    }
  }, [
    isLoading,
    requests,
    filterTags,
    area0_59Checked,
    area60_140Checked,
    area141Checked,
    area141MoreChecked,
    priceChecked,
    qualityChecked,
    interiorDesign,
    bathroomAndKitches,
    windowsAndDoors,
    repairAndRestoration,
    maxRadiusKm, // Include radiusKm as a dependency
    handleAreaChangeValue,
    handleTypeChangeValue,
    handleFocusChangeValue,
  ]);

  const handleRadiusChange = (event) => {
    const value = event.target.value;
    setMaxRadiusKm(value); // Set the input value in km
  };

  const handleInteriorDesignChange = (event) => {
    setInteriorDesignChecked(event.target.checked);
  };
  const handleBathroomAndKitchesChange = (event) => {
    setBathroomAndKitchesChecked(event.target.checked);
  };
  const handleWindowsAndDoorsChange = (event) => {
    setWindowsAndDoorsChecked(event.target.checked);
  };
  const handleRepairAndRestorationChange = (event) => {
    setRepairAndRestorationChecked(event.target.checked);
  };

  const handleArea0_59Change = (event) => {
    setArea0_59Checked(event.target.checked);
    // Check if any area checkbox is checked and set isAnyAreaCheckboxChecked accordingly
    setIsAnyAreaCheckboxChecked(
      event.target.checked ||
        area60_140Checked ||
        area141Checked ||
        area141MoreChecked,
    );
  };

  const handleArea60_140Change = (event) => {
    setArea60_140Checked(event.target.checked);
    setIsAnyAreaCheckboxChecked(
      area0_59Checked ||
        event.target.checked ||
        area141Checked ||
        area141MoreChecked,
    );
  };

  const handleArea141Change = (event) => {
    setArea141Checked(event.target.checked);
    setIsAnyAreaCheckboxChecked(
      area0_59Checked ||
        area60_140Checked ||
        event.target.checked ||
        area141MoreChecked,
    );
  };

  const handleArea141MoreChange = (event) => {
    setArea141MoreChecked(event.target.checked);
    setIsAnyAreaCheckboxChecked(
      area0_59Checked ||
        area60_140Checked ||
        area141Checked ||
        event.target.checked,
    );
  };

  const handlePrice = (event) => {
    setPriceChecked(event.target.checked);
  };

  const handleQuality = (event) => {
    setQualityChecked(event.target.checked);
  };

  const handleAreaChange = (event) => {
    // Handle the selected value from the Area dropdown
    const selectedValue = event.target.value;
    // Implement the logic to handle the selected value as needed
    // For example, you can update state or perform any other actions
    setHandleAreaChangeValue(selectedValue);
    setIsHandleAreaChangeValue(true);
  };

  const handleTypeChange = (event) => {
    // Handle the selected value from the Type dropdown
    const selectedValue = event.target.value;
    // Implement the logic to handle the selected value as needed
    // For example, you can update state or perform any other actions
    setHandleTypeChangeValue(selectedValue);
    setIsHandleTypeChangeValue(true);
  };

  const handleFocusChange = (event) => {
    // Handle the selected value from the Focus dropdown
    const selectedValue = event.target.value;
    // Implement the logic to handle the selected value as needed
    // For example, you can update state or perform any other actions
    setHandleFocusChangeValue(selectedValue);
    setIsHandleFocusChangeValue(true);
  };

  return (
    <>
      <div className="flex-superparent">
        <MediaQuery minWidth={1026}>
        <div className="parent-container">
            <div className="filter-moving-group">
              <ul className="moving-filter">
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="0-59"
                      onChange={handleArea0_59Change}
                    />
                    0m2 - 59m2
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="60-140"
                      onChange={handleArea60_140Change}
                    />
                    60m2 - 140m2
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="141"
                      onChange={handleArea141Change}
                    />
                    141m2
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="142"
                      onChange={handleArea141MoreChange}
                    />
                    from 141m2 and more
                  </label>
                </li>
              </ul>
            </div>

            <div className="filter-moving-group">
              <ul className="moving-filter">
                <li>
                  <label>
                    <input type="checkbox" value="0-59" onChange={handleInteriorDesignChange}/>
                    {t("Interior design")}
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" value="60-140" onChange={handleBathroomAndKitchesChange}/>
                    {t("Bathroom & Kitchens")}
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" value="141" onChange={handleWindowsAndDoorsChange}/>
                    {t("Windows & Doors")}
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" value="142" onChange={handleRepairAndRestorationChange}/>
                    {t("Repair & Restoration")}
                  </label>
                </li>
              </ul>
            </div>
            <div className="filter-moving-group focus-group">
            <h2> Radius in KM </h2>
              <input
                  type="number"
                  min={0}
                  onWheel={(event) => event.currentTarget.blur()}
                  value={maxRadiusKm}
                  onChange={handleRadiusChange}
                />
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1024}>
            <div className="parent-container-mobile">
              <div className="filter-moving-group selections">
                <label>
                  Area:
                  <select onChange={handleAreaChange}>
                    <option value="0-59">0m2 - 59m2</option>
                    <option value="60-140">60m2 - 140m2</option>
                    <option value="141">141m2</option>
                    <option value="142">from 141m2 and more</option>
                  </select>
                </label>
              </div>
              <div className="filter-moving-group selections">
                <label>
                  Type:
                  <select onChange={handleTypeChange}>
                    <option value="Interior Design">Interior Design</option>
                    <option value="Bathroom and kitchens">Bathroom and kitchens</option>
                    <option value="Windows and doors">Windows and doors</option>
                    <option value="Repair and restoration">Repair and restoration</option>
                  </select>
                </label>
              </div>
              <div className="filter-moving-group selections">
                <label>
                  Focus:
                  <select onChange={handleFocusChange}>
                    <option value="Quality">Quality</option>
                    <option value="Price">Price</option>
                  </select>
                </label>
              </div>
              <div className="filter-moving-group selections">
                <label>
                  Radius in KM:
                  <input
                    type="number"
                    min={0}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={maxRadiusKm}
                    onChange={handleRadiusChange}
                  />
                </label>
              </div>
            </div>
        </MediaQuery>


        <div className="accordion-container">
          {interiorDesign && (
            <div className="accordian-request-container">
              {filteredData.map((request, index) => {
                let requestListingComponent = null;
                

                if (request.requestType === "INterior Design") {
                  const distance = getDistance(referencePoint, {
                    latitude: request.address.latitude,
                    longitude: request.address.longitude,
                  });
                  const distanceInKm = distance / 1000;

                  const areaFilter =
                    (!isAnyAreaCheckboxChecked ||
                      (area0_59Checked &&
                        request.objectArea >= 0 &&
                        request.objectArea <= 59) ||
                      (area60_140Checked &&
                        request.objectArea >= 60 &&
                        request.objectArea <= 140) ||
                      (area141Checked && request.objectArea === 141) ||
                      (area141MoreChecked && request.objectArea > 141)) &&
                    (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);

                  const priceQualityFilter =
                  (!qualityChecked || request.focus === "quality") &&
                    (!priceChecked || request.focus === "price");
                    

                  if (areaFilter && priceQualityFilter) {
                    requestListingComponent = (
                      <RequestListing
                        key={index}
                        details={request}
                        index={index}
                        companyDetails={companyDetails}
                      />
                    );
                  }
                }

                if (requestListingComponent) {
                  return requestListingComponent;
                } else {
                  return null;
                }
              })}
            </div>
          )}
          {bathroomAndKitches && (
            <div className="accordian-request-container">
              {filteredData.map((request, index) => {
                let requestListingComponent = null;
                

                if (request.requestType === "Bathroom and Kitchens") {
                  const distance = getDistance(referencePoint, {
                    latitude: request.address.latitude,
                    longitude: request.address.longitude,
                  });
                  const distanceInKm = distance / 1000;
                  const areaFilter =
                    (!isAnyAreaCheckboxChecked ||
                      (area0_59Checked &&
                        request.objectArea >= 0 &&
                        request.objectArea <= 59) ||
                      (area60_140Checked &&
                        request.objectArea >= 60 &&
                        request.objectArea <= 140) ||
                      (area141Checked && request.objectArea === 141) ||
                      (area141MoreChecked && request.objectArea > 141)) &&
                    (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);
                  const priceQualityFilter =
                  (!qualityChecked || request.focus === "quality") &&
                    (!priceChecked || request.focus === "price");
                  if (areaFilter && priceQualityFilter) {
                    requestListingComponent = (
                      <RequestListing
                        key={index}
                        details={request}
                        index={index}
                        companyDetails={companyDetails}
                      />
                    );
                  }
                }

                // Render the component if it's set
                if (requestListingComponent) {
                  return requestListingComponent;
                } else {
                  // If no component is rendered, return null
                  return null;
                }
              })}
            </div>
          )}
          {windowsAndDoors && (
            <div className="accordian-request-container">
              {filteredData.map((request, index) => {
                let requestListingComponent = null;
                

                if (request.requestType === "Windows and Doors") {
                  const distance = getDistance(referencePoint, {
                    latitude: request.address.latitude,
                    longitude: request.address.longitude,
                  });
                  const distanceInKm = distance / 1000;
                  const areaFilter =
                    (!isAnyAreaCheckboxChecked ||
                      (area0_59Checked &&
                        request.objectArea >= 0 &&
                        request.objectArea <= 59) ||
                      (area60_140Checked &&
                        request.objectArea >= 60 &&
                        request.objectArea <= 140) ||
                      (area141Checked && request.objectArea === 141) ||
                      (area141MoreChecked && request.objectArea > 141)) &&
                    (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);
                  const priceQualityFilter =
                  (!qualityChecked || request.focus === "quality") &&
                    (!priceChecked || request.focus === "price");
                  if (areaFilter && priceQualityFilter) {
                    requestListingComponent = (
                      <RequestListing
                        key={index}
                        details={request}
                        index={index}
                        companyDetails={companyDetails}
                      />
                    );
                  }
                }
                if (requestListingComponent) {
                  return requestListingComponent;
                } else {
                  return null;
                }
              })}
            </div>
          )}
          {repairAndRestoration && (
            <div className="accordian-request-container">
              {filteredData.map((request, index) => {
                let requestListingComponent = null;

                if (request.requestType === "Repair and Restoriation") {
                  const distance = getDistance(referencePoint, {
                    latitude: request.address.latitude,
                    longitude: request.address.longitude,
                  });
                  const distanceInKm = distance / 1000;
                  const areaFilter =
                    (!isAnyAreaCheckboxChecked ||
                      (area0_59Checked &&
                        request.objectArea >= 0 &&
                        request.objectArea <= 59) ||
                      (area60_140Checked &&
                        request.objectArea >= 60 &&
                        request.objectArea <= 140) ||
                      (area141Checked && request.objectArea === 141) ||
                      (area141MoreChecked && request.objectArea > 141)) &&
                    (maxRadiusKm === 0 || distanceInKm <= maxRadiusKm);
                  const priceQualityFilter =
                  (!qualityChecked || request.focus === "quality") &&
                    (!priceChecked || request.focus === "price");
                  if (areaFilter && priceQualityFilter) {
                    requestListingComponent = (
                      <RequestListing
                        key={index}
                        details={request}
                        index={index}
                        companyDetails={companyDetails}
                      />
                    );
                  }
                }
                if (requestListingComponent) {
                  return requestListingComponent;
                } else {
                  return null;
                }
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CarpenterFilter;
