import React, { useState, useEffect } from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import { MenuProps, getStyles } from "../../../utils/utils";
import { SelectChangeEvent } from "@mui/material/Select";
import { currentLang } from "../../common/Navbar";
import "./index.css";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import { flexbox } from "@material-ui/system";
// import PlacesAutocomplete from './PlacesAutocomplete '

const Address = (props) => {
  const params = new URLSearchParams(window.location.search);
  const WindowrequestType = params.get("requestType");
  return (
    <>
      {WindowrequestType === "Moving" ? (
        <MovingRequestDetails props={props} />
      ) : WindowrequestType === "Cleaning" ? (
        <CleaningRequestDetails props={props} />
      ) : WindowrequestType === "Painting" ? (
        <PaintingRequestDetails props={props} />
      ) : WindowrequestType === "Carpenter" ? (
        <CarpenterRequestDetails props={props} />
      ) : (
        "Something went wrong.."
      )}
    </>
  );
};
const MovingRequestDetails = ({ props }) => {
  const params = new URLSearchParams(window.location.search);
  const WindowrequestType = params.get("requestType");
  const { t } = useTranslation();
  const [flagdate, setFlagdate] = useState(false);
  const [categorydate, setCategorydate] = useState(
    props.resultCleaning.categorydate
  );
  const [moveIndate, setmoveIndate] = useState(props.resultCleaning.moveIndate);
  const [moveOutAddress, setmoveOutAddress] = useState(
    props.resultCleaning.moveOutAddress
  );
  const [moveInAddress, SetmoveInAddress] = useState(
    props.resultCleaning.moveInAddress
  );

  const [roomNumMoveOut, setRoomNumMoveOut] = useState(props.resultCleaning.roomnum);
  const [roomNumMoveIn, setRoomNumMoveIn] = useState(props.resultCleaning.roomnum);
  const fnRoomMoveOut = (e) => {
    setRoomNumMoveOut(e.target.value);
  };
  const fnRoomMoveIn = (e) => {
    setRoomNumMoveIn(e.target.value);
  };

  const [floors, setfloors] = useState(props.resultCleaning.floors);
  const [MoveInfloor, setMoveInfloor] = useState(
    props.resultCleaning.MoveInfloor
  );
  const [Distance, setDistance] = useState(props.resultCleaning.Distance);
  const [MovingDistance, setMovingDistance] = useState(
    props.resultCleaning.MovingDistance
  );
  const [noOfPeople, setnoOfPeople] = useState(props.resultCleaning.noOfPeople);
  const [Elevator, setElevator] = useState(props.resultCleaning.Elevator);
  const [MoveInElevator, setMoveInElevator] = useState(
    props.resultCleaning.MoveInElevator
  );
  const [floorarea, setFloorarea] = useState(props.resultCleaning.floorarea);
  const [lat, setLat] = useState(props.resultCleaning.latitude);
  const [lng, setLng] = useState(props.resultCleaning.longitude);
  const [zipcode, setZipcode] = useState(props.resultCleaning.postcode);
  const [lat1, setLat1] = useState(props.resultCleaning.latitude1);
  const [lng1, setLng1] = useState(props.resultCleaning.longitude1);
  const [zipcode1, setZipcode1] = useState(props.resultCleaning.postcode1);
  const [formErrors, setformErrors] = useState("");
  const fnCategoryDate = (e) => {
    setCategorydate(e);
    setFlagdate(false);
  };
  const fnMoveInDate = (e) => {
    setmoveIndate(e);
    setFlagdate(false);
  };
  const fnElevator = (e) => {
    setElevator(e.target.value);
  };
  const fnMoveInElevator = (e) => {
    setMoveInElevator(e.target.value);
  };
  const fnFloorArea = (e) => {
    setFloorarea(e.target.value);
  };
  const fnnoOfPeople = (e) => {
    setnoOfPeople(e.target.value);
  };
  
  const fnfloor = (e) => {
    setfloors(e.target.value);
  };
  const fnMoveInfloor = (e) => {
    setMoveInfloor(e.target.value);
  };
  const fnDistance = (e) => {
    setDistance(e.target.value);
  };
  const fnMovingDistance = (e) => {
    setMovingDistance(e.target.value);
  };
  const fnAdd = () => {
    setFloorarea(parseInt(floorarea) + 1);
  };
  const fnSub = () => {
    setFloorarea(parseInt(floorarea) - 1);
  };
  const inputEvent = (e) => {
    let name = "",
      value = "";
    if (e.target === undefined) {
      name = "date";
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    //validations

    setformErrors(e.target?.value === "" ? "Please select Category" : "");
  };

  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      moveOutAddress: moveOutAddress,
      moveInAddress: moveInAddress,
      roomNumMoveOut: roomNumMoveOut,
      roomNumMoveIn: roomNumMoveIn,
      floors: floors,
      Distance: Distance,
      noOfPeople: noOfPeople,
      floorarea: floorarea,
      latitude: lat,
      longitude: lng,
      postcode: zipcode,
      latitude1: lat1,
      longitude1: lng1,
      postcode1: zipcode1,
      moveIndate: moveIndate,
      MoveInElevator: MoveInElevator,
      MoveInfloor: MoveInfloor,
      MovingDistance: MovingDistance,
      categorydate: categorydate,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (
      (moveOutAddress &&
        (WindowrequestType !== "Moving" ||
          props.resultCleaning.requestType === "Clearance and disposal")) ||
      (moveInAddress &&
        moveOutAddress &&
        props.resultCleaning.requestType !== "Clearance and disposal")
    ) {
      const newState = Object.assign({}, props.resultCleaning, {
        moveOutAddress: moveOutAddress,
        moveInAddress: moveInAddress,
        selectedOptions: selectedOptions,
        additionalOptions: additionalOptions,
        roomNumMoveOut: roomNumMoveOut,
        roomNumMoveIn: roomNumMoveIn,
        floors: floors,
        Distance: Distance,
        noOfPeople: noOfPeople,
        floorarea: floorarea,
        latitude: lat,
        longitude: lng,
        postcode: zipcode,
        latitude1: lat1,
        longitude1: lng1,
        postcode1: zipcode1,
        moveIndate: moveIndate,
        MoveInElevator: MoveInElevator,
        MoveInfloor: MoveInfloor,
        MovingDistance: MovingDistance,
        categorydate: categorydate,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    } else {
      setformErrors("Select Address");
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleDropdownChange = (event) => {
    setSelectedOptions(event.target.value);
  };
  const options1 = {
    Einpackservice: t("Einpackservice"),
    "Möbel demontieren": t("Möbel demontieren"),
    "Lampen demontieren": t("Lampen demontieren"),
    Entsorgen: t("Entsorgen"),
    "Möbel einlagern": t("Möbel einlagern"),
  };

  const [additionalOptions, setAdditionalOptions] = useState([]);

  const handleAdditionalDropdownChange = (event) => {
    setAdditionalOptions(event.target.value);
  };

  const options2 = {
    Auspackservice: t("Auspackservice"),
    "Möbel Montieren": t("Möbel Montieren"),
    "Lampen Montieren": t("Lampen Montieren"),
  };

  return (
    <div className="category-component">
      <div className="category-container">
        <span className="card-box" style={{ flexDirection: "column" }}>
          <span className="sub-heading">
            {props.resultCleaning.requestType !== "Clearance and disposal" && (
              <span className="card-box" style={{ flexDirection: "column" }}>
                <span style={{ marginBottom: "-20px" }}>
                  {t("Here I move out:")}
                </span>
                <AutoCompleteAddress
                  address={props.resultCleaning.moveInAddress}
                  setAddress={SetmoveInAddress}
                  setLat={setLat1}
                  setLng={setLng1}
                  setZipcode={setZipcode1}
                  setformErrors={setformErrors}
                />
                {formErrors && !moveInAddress && (
                  <span className="error" style={{ marginTop: "-1.2rem" }}>
                    {formErrors}
                  </span>
                )}
                <div
                  style={{
                    display: "flex",
                    gap: "4rem",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="double-trouble"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "-35px",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      style={{ width: "22%", marginTop: "20px" }}
                    >
                      <InputLabel id="noOfPeople">
                        {t("Number of people in the household")}
                      </InputLabel>
                      <Select
                        labelId="noOfPeople"
                        required
                        id="noOfPeople"
                        name="noOfPeople"
                        value={noOfPeople}
                        label={t("Number of people in the household")}
                        style={{ height: "100%" }}
                        onChange={fnnoOfPeople}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="stock-lift">
                      <div className="order-desc-custom">
                        <Form.Label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {t("Floor")}
                        </Form.Label>
                        <ButtonGroup
                          onClick={fnMoveInfloor}
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            value="1"
                            className={
                              MoveInfloor === "1"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                          >
                            1
                          </Button>
                          <Button
                            value="2"
                            className={
                              MoveInfloor === "2"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                          >
                            2
                          </Button>
                          <Button
                            value="3"
                            className={
                              MoveInfloor === "3"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                          >
                            3
                          </Button>
                          <Button
                            value="4"
                            className={
                              MoveInfloor === "4"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                          >
                            4
                          </Button>
                          <Button
                            value="5"
                            className={
                              MoveInfloor === "5"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                          >
                            5
                          </Button>
                          <Button
                            value="6+"
                            className={
                              MoveInfloor === "6+"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                          >
                            6+
                          </Button>
                        </ButtonGroup>
                      </div>
                      <div className="order-desc-custom2">
                        <Form.Label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {t("Elevator")}
                        </Form.Label>
                        <ButtonGroup
                          onClick={fnElevator}
                          className="address-desc"
                        >
                          <Button
                            className={
                              Elevator === "Yes"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                            value="Yes"
                          >
                            {t("Yes")}
                          </Button>
                          <Button
                            className={
                              Elevator === "No"
                                ? "active-btn btn-primary"
                                : "btn-secondary"
                            }
                            value="No"
                          >
                            {t("No")}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>

                  <div className="in-order">
                    <div className="order-desc-custom2">
                      <Form.Label
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {t("FLOOR Area in sqm")}
                      </Form.Label>
                      <ButtonGroup
                        style={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="address-desc counter"
                      >
                        <Button onClick={fnSub}> - </Button>
                        <Form.Control
                          type="text"
                          onChange={fnFloorArea}
                          value={floorarea}
                          className="text-end"
                        />
                        <Button onClick={fnAdd}> + </Button>
                      </ButtonGroup>
                    </div>
                  </div>

                  <div className="order-desc">
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("Number of Rooms")}
                    </Form.Label>
                    <ButtonGroup
                      onClick={fnRoomMoveOut}
                      className="address-desc"
                      style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        value="1"
                        className={
                          roomNumMoveOut === "1"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        1
                      </Button>
                      <Button
                        value="2"
                        className={
                          roomNumMoveOut === "2"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        2
                      </Button>
                      <Button
                        value="3"
                        className={
                          roomNumMoveOut === "3"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        3
                      </Button>
                      <Button
                        value="4"
                        className={
                          roomNumMoveOut === "4"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        4
                      </Button>
                      <Button
                        value="5"
                        className={
                          roomNumMoveOut === "5"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        5
                      </Button>
                      <Button
                        value="6+"
                        className={
                          roomNumMoveOut === "6+"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        6+
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div className="in-order">
                    <div className="order-desc-custom">
                      <Form.Label
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {t(
                          "Walking distance from the front door to the truck (meters)"
                        )}
                      </Form.Label>
                      <ButtonGroup
                        onClick={fnMovingDistance}
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "1rem",
                        }}
                      >
                        <Button
                          value="5"
                          className={
                            MovingDistance === "5"
                              ? "active-btn btn-primary"
                              : "btn-secondary"
                          }
                        >
                          5
                        </Button>
                        <Button
                          value="10"
                          className={
                            MovingDistance === "10"
                              ? "active-btn btn-primary"
                              : "btn-secondary"
                          }
                        >
                          10
                        </Button>
                        <Button
                          value="20"
                          className={
                            MovingDistance === "20"
                              ? "active-btn btn-primary"
                              : "btn-secondary"
                          }
                        >
                          20
                        </Button>
                        <Button
                          value="30"
                          className={
                            MovingDistance === "30"
                              ? "active-btn btn-primary"
                              : "btn-secondary"
                          }
                        >
                          30
                        </Button>
                        <Button
                          value="50+"
                          className={
                            MovingDistance === "50+"
                              ? "active-btn btn-primary"
                              : "btn-secondary"
                          }
                        >
                          50+
                        </Button>
                        <span
                          type="button"
                          value=""
                          class="invisible-button"
                        ></span>
                      </ButtonGroup>
                    </div>

                    {/* checkboxes here */}
                    <div className="lilwrapper">
                      <div className="checkbox-container">
                        <span>{t("Additional Services")}</span>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%", marginTop: "20px" }}
                        >
                          <InputLabel id="additionalOptions-label">
                            {t("AdditionalOptions")}
                          </InputLabel>
                          <Select
                            labelId="additionalOptions-label"
                            id="additionalOptions"
                            label={t("AdditionalOptions")}
                            multiple
                            value={selectedOptions}
                            onChange={handleDropdownChange}
                          >
                            {Object.entries(options1).map(([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            )}
            <div style={{ marginTop: "25px" }}></div>

            {t(
              props.resultCleaning.requestType === "Clearance and disposal"
                ? t("Dispose of Here")
                : t("Here I move In:")
            )}
            {/* checkpoint */}
            {/* <span >{t("Here I move In:")}</span> */}
            <AutoCompleteAddress
              address={props.resultCleaning.moveOutAddress}
              setAddress={setmoveOutAddress}
              setLat={setLat}
              setLng={setLng}
              setZipcode={setZipcode}
              setformErrors={setformErrors}
            />
            {formErrors && !moveOutAddress && (
              <span className="error" style={{ marginTop: "-1.2rem" }}>
                {formErrors}
              </span>
            )}

            <div
              style={{
                display: "flex",
                gap: "4rem",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div
                className="double-trouble"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-35px",
                }}
              >
                <div className="invisible-div"></div>
                <div className="stock-lift">
                  <div className="order-desc-custom">
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("Floor")}
                    </Form.Label>
                    <ButtonGroup
                      onClick={fnfloor}
                      className=""
                      style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        value="0"
                        className={
                          floors === "0"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        0
                      </Button>
                      <Button
                        value="1"
                        className={
                          floors === "1"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        1
                      </Button>
                      <Button
                        value="2"
                        className={
                          floors === "2"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        2
                      </Button>
                      <Button
                        value="3"
                        className={
                          floors === "3"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        3
                      </Button>
                      <Button
                        value="4"
                        className={
                          floors === "4"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        4
                      </Button>
                      <Button
                        value="5"
                        className={
                          floors === "5"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                      >
                        5
                      </Button>
                    </ButtonGroup>
                  </div>

                  <div className="order-desc-custom2">
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("Elevator")}
                    </Form.Label>
                    <ButtonGroup
                      onClick={fnMoveInElevator}
                      className="address-desc"
                    >
                      <Button
                        className={
                          MoveInElevator === "Yes"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                        value="Yes"
                      >
                        {t("Yes")}
                      </Button>
                      <Button
                        className={
                          MoveInElevator === "No"
                            ? "active-btn btn-primary"
                            : "btn-secondary"
                        }
                        value="No"
                      >
                        {t("No")}
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>

              <div className="order-desc">
                <Form.Label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {t("Number of Rooms")}
                </Form.Label>
                <ButtonGroup
                  onClick={fnRoomMoveIn}
                  className="address-desc"
                  style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    value="1"
                    className={
                      roomNumMoveIn === "1"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                  >
                    1
                  </Button>
                  <Button
                    value="2"
                    className={
                      roomNumMoveIn === "2"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                  >
                    2
                  </Button>
                  <Button
                    value="3"
                    className={
                      roomNumMoveIn === "3"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                  >
                    3
                  </Button>
                  <Button
                    value="4"
                    className={
                      roomNumMoveIn === "4"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                  >
                    4
                  </Button>
                  <Button
                    value="5"
                    className={
                      roomNumMoveIn === "5"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                  >
                    5
                  </Button>
                  <Button
                    value="6+"
                    className={
                      roomNumMoveIn === "6+"
                        ? "active-btn btn-primary"
                        : "btn-secondary"
                    }
                  >
                    6+
                  </Button>
                </ButtonGroup>
              </div>

              <div className="in-order">
                <div className="order-desc-custom">
                  <Form.Label
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {t(
                      "Walking distance from the front door to the truck (meters)"
                    )}
                  </Form.Label>
                  <ButtonGroup
                    onClick={fnDistance}
                    className=""
                    style={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      value="5"
                      className={
                        Distance === "5"
                          ? "active-btn btn-primary"
                          : "btn-secondary"
                      }
                    >
                      5
                    </Button>
                    <Button
                      value="10"
                      className={
                        Distance === "10"
                          ? "active-btn btn-primary"
                          : "btn-secondary"
                      }
                    >
                      10
                    </Button>
                    <Button
                      value="20"
                      className={
                        Distance === "20"
                          ? "active-btn btn-primary"
                          : "btn-secondary"
                      }
                    >
                      20
                    </Button>
                    <Button
                      value="30"
                      className={
                        Distance === "30"
                          ? "active-btn btn-primary"
                          : "btn-secondary"
                      }
                    >
                      30
                    </Button>
                    <Button
                      value="50+"
                      className={
                        Distance === "50+"
                          ? "active-btn btn-primary"
                          : "btn-secondary"
                      }
                    >
                      50+
                    </Button>

                    <span
                      type="button"
                      value=""
                      class="invisible-button"
                    ></span>
                  </ButtonGroup>
                  {/* next */}
                </div>
                {props.resultCleaning.requestType === "Clearance and disposal" ? <div className=""> </div>: <div className="lilwrapper">
                  <div className="checkbox-container">
                    <span>{t("Additional Services")}</span>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <InputLabel id="additionalOptions-label">
                      {t("AdditionalOptions")}
                      </InputLabel>
                      <Select
                            labelId="additionalOptions-label"
                            id="additionalOptions"
                            label={t("AdditionalOptions")}
                            multiple
                            value={additionalOptions}
                            onChange={handleAdditionalDropdownChange}
                          >
                        {Object.entries(options2).map(([value, label]) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>}
              </div>
            </div>
          </span>
        </span>

        <span className="prevNext-btn card-box">
          <Button variant="warning" onClick={fnClickPrev}>
            {t("prev")}
          </Button>

          <Button variant="warning" onClick={fnClickNext}>
            {t("next")}
          </Button>
        </span>
      </div>
    </div>
  );
};
const CleaningRequestDetails = ({ props }) => {
  // German
  const options_de = {
    floorCoveringOptions: {
      Apartment: ["Parkett", "Laminat", "Linoleum", "Fliesenboden", "Teppich"],
      House: ["Parkett", "Laminat", "Linoleum", "Fliesenboden", "Teppich"],
      Office: ["Parkett", "Laminat", "Linoleum", "Fliesenboden", "Teppich"],
      Business: [
        "Parkett",
        "Laminat",
        "Linoleum",
        "Naturstein",
        "Teppich",
        "Beton",
      ],
    },

    windowsWith: {
      Apartment: [
        "Aluminium-Rolläden",
        "Textil-Rolläden",
        "Lamellenjalousien",
        "Läden",
      ],
      House: ["Aluminium-Rolläden", "Textil-Rolläden", "Jalousien", "Läden"],
      Office: [
        "Aluminium-Rolläden",
        "Textil-Rolläden",
        "Lamellenjalousien",
        "Läden",
      ],
      Business: ["Aluminium-Rolläden", "Stoff-Rolläden", "Jalousien", "Läden"],
    },
  };

  // French
  const options_fr = {
    floorCoveringOptions: {
      Apartment: ["Parquet", "Stratifié", "Linoléum", "Carrelage", "Moquette"],
      House: ["Parquet", "Stratifié", "Linoléum", "Carrelage", "Moquette"],
      Office: ["Parquet", "Stratifié", "Linoléum", "Carrelage", "Moquette"],
      Business: [
        "Parquet",
        "Stratifié",
        "Linoléum",
        "Pierre naturelle",
        "Moquette",
        "Béton",
      ],
    },

    windowsWith: {
      Apartment: [
        "Volets roulants en aluminium",
        "Volets roulants textiles",
        "Stores à lamelles",
        "Persiennes",
      ],
      House: [
        "Volets roulants en aluminium",
        "Volets roulants textiles",
        "Stores vénitiens",
        "Persiennes",
      ],
      Office: [
        "Volets roulants en aluminium",
        "Volets roulants textiles",
        "Stores à lamelles",
        "Persiennes",
      ],
      Business: [
        "Volets roulants en aluminium",
        "Volets roulants en tissu",
        "Stores vénitiens",
        "Persiennes",
      ],
    },
  };

  // Italian
  const options_it = {
    floorCoveringOptions: {
      Apartment: [
        "Parquet",
        "Laminato",
        "Linoleum",
        "Pavimento piastrellato",
        "Tappeto",
      ],
      House: [
        "Parquet",
        "Laminato",
        "Linoleum",
        "Pavimento piastrellato",
        "Tappeto",
      ],
      Office: [
        "Parquet",
        "Laminato",
        "Linoleum",
        "Pavimento piastrellato",
        "Tappeto",
      ],
      Business: [
        "Parquet",
        "Laminato",
        "Linoleum",
        "Pietra naturale",
        "Tappeto",
        "Cemento",
      ],
    },

    windowsWith: {
      Apartment: [
        "Tapparelle in alluminio",
        "Tapparelle tessili",
        "Tende a lamelle",
        "Persiane",
      ],
      House: [
        "Tapparelle in alluminio",
        "Tapparelle tessili",
        "Tende veneziane",
        "Persiane",
      ],
      Office: [
        "Tapparelle in alluminio",
        "Tapparelle tessili",
        "Tende a lamelle",
        "Persiane",
      ],
      Business: [
        "Tapparelle in alluminio",
        "Tapparelle in tessuto",
        "Tende veneziane",
        "Persiane",
      ],
    },
  };

  // English
  const options_en = {
    floorCoveringOptions: {
      Apartment: ["Parquet", "Laminate", "Linoleum", "Tiled floor", "Carpet"],
      House: ["Parquet", "Laminate", "Linoleum", "Tiled floor", "Carpet"],
      Office: ["Parquet", "Laminate", "Linoleum", "Tiled floor", "Carpet"],
      Business: [
        "Parquet",
        "Laminate",
        "Linoleum",
        "Flagstone",
        "Carpet",
        "Concrete",
      ],
    },

    windowsWith: {
      Apartment: [
        "Aluminum roller shutters",
        "Textile roller shutters",
        "Slat blinds",
        "Shutters",
      ],
      House: [
        "Aluminum roller shutters",
        "Textile roller shutters",
        "Venetian blinds",
        "Shutters",
      ],
      Office: [
        "Aluminum roller shutters",
        "Textile roller shutters",
        "Slat blinds",
        "Shutters",
      ],
      Business: [
        "Aluminum roller shutters",
        "Fabric roller shutters",
        "Venetian blinds",
        "Shutters",
      ],
    },
  };

  const translatedOptions = {
    en: options_en,
    de: options_de,
    it: options_it,
    fr: options_fr,
  };

  const translations = {
    en: {
      "Heavy soiled": "Heavy soiled",
      "Medium soiled": "Medium soiled",
      "Slightly soiled": "Slightly soiled",
    },
    de: {
      "Heavy soiled": "Stark verschmutzt",
      "Medium soiled": "Mittel verschmutzt",
      "Slightly soiled": "Leicht verschmutzt",
    },
    fr: {
      "Heavy soiled": "Très sale",
      "Medium soiled": "Moyennement sale",
      "Slightly soiled": "Légèrement sale",
    },
    it: {
      "Heavy soiled": "Pesantemente sporco",
      "Medium soiled": "Leggermente sporco",
      "Slightly soiled": "Leggermente sporco",
    },
  };

  const currentOptions = translatedOptions[currentLang];

  const { t } = useTranslation();
  const [moveOutAddress, setmoveOutAddress] = useState(
    props.resultCleaning.moveOutAddress
  );
  const [roomnum, setRoomnum] = useState(props.resultCleaning.roomnum);
  const [floorCovering, setfloorCovering] = useState(
    props.resultCleaning.floorCovering
  );
  const [windowsWith, setWindowsWith] = useState(
    props.resultCleaning.windowsWith
  );
  const [heating, setHeating] = useState(props.resultCleaning.heating);
  const [CleanBalcony, setCleanBalcony] = useState(
    props.resultCleaning.CleanBalcony
  );
  const [BalconyArea, setBalconyArea] = useState(
    props.resultCleaning.BalconyArea
  );
  const [floorarea, setFloorarea] = useState(props.resultCleaning.floorarea);
  const [degreeOfSoiling, setDegreeOfSoiling] = useState(
    props.resultCleaning.degreeOfSoiling
  );
  const [lat, setLat] = useState(props.resultCleaning.latitude);
  const [lng, setLng] = useState(props.resultCleaning.longitude);
  const [zipcode, setZipcode] = useState(props.resultCleaning.postcode);
  const [formErrors, setformErrors] = useState("");
  const fnFloorArea = (e) => {
    setFloorarea(e.target.value);
  };
  const fnRoomNUm = (e) => {
    setRoomnum(e.target.value);
  };
  const fnDegreeOfSoiling = (e) => {
    setDegreeOfSoiling(e.target.value);
  };
  const fnAdd = () => {
    setFloorarea(parseInt(floorarea) + 1);
  };
  const fnSub = () => {
    setFloorarea(parseInt(floorarea) - 1);
  };
  const fnfloorCovering = (e) => {
    setfloorCovering(e.target.value);
  };
  const fnwindowsWith = (e) => {
    setWindowsWith(e.target.value);
  };
  const fnHeating = (e) => {
    setHeating(e.target.value);
  };
  const fnCleanBalcony = (e) => {
    setCleanBalcony(e.target.value);
    setBalconyArea("");
    setformErrors("");
  };
  const fnBalconyArea = (e) => {
    setBalconyArea(e.target.value);
  };
  const inputEvent = (e) => {
    let name = "",
      value = "";
    if (e.target === undefined) {
      name = "date";
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    //validations

    setformErrors(e.target?.value === "" ? t("Please select Category") : "");
  };

  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      moveOutAddress: moveOutAddress,
      roomnum: roomnum,
      floorarea: floorarea,
      degreeOfSoiling: degreeOfSoiling,
      latitude: lat,
      longitude: lng,
      postcode: zipcode,
      floorCovering: floorCovering,
      windowsWith: windowsWith,
      heating: heating,
      CleanBalcony: CleanBalcony,
      BalconyArea: BalconyArea,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (
      moveOutAddress &&
      floorCovering &&
      windowsWith &&
      heating &&
      !(CleanBalcony === "Yes" && BalconyArea === "")
    ) {
      const newState = Object.assign({}, props.resultCleaning, {
        moveOutAddress: moveOutAddress,
        roomnum: roomnum,
        floorarea: floorarea,
        degreeOfSoiling: degreeOfSoiling,
        latitude: lat,
        longitude: lng,
        postcode: zipcode,
        floorCovering: floorCovering,
        windowsWith: windowsWith,
        heating: heating,
        CleanBalcony: CleanBalcony,
        BalconyArea: BalconyArea,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    } else {
      setformErrors(t("Please fill required fields."));
    }
  };
  return (
    <div className="category-component">
      <div className="category-container">
        <span className="card-box" style={{ flexDirection: "column" }}>
          <span className="sub-heading">
            {t("This is where it needs to be cleaned:")}
          </span>
          <AutoCompleteAddress
            address={props.resultCleaning.moveOutAddress}
            setAddress={setmoveOutAddress}
            setLat={setLat}
            setLng={setLng}
            setZipcode={setZipcode}
            setformErrors={setformErrors}
          />
          {formErrors && !moveOutAddress && (
            <span className="error" style={{ marginTop: "-1.2rem" }}>
              {formErrors}
            </span>
          )}

          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <div className="order-desc">
              <Form.Label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("Number of Rooms")}
              </Form.Label>
              <ButtonGroup onClick={fnRoomNUm} className="address-desc">
                <Button
                  value="1"
                  className={
                    roomnum === "1" ? "active-btn btn-primary" : "btn-secondary"
                  }
                >
                  1
                </Button>
                <Button
                  value="2"
                  className={
                    roomnum === "2" ? "active-btn btn-primary" : "btn-secondary"
                  }
                >
                  2
                </Button>
                <Button
                  value="3"
                  className={
                    roomnum === "3" ? "active-btn btn-primary" : "btn-secondary"
                  }
                >
                  3
                </Button>
                <Button
                  value="4"
                  className={
                    roomnum === "4" ? "active-btn btn-primary" : "btn-secondary"
                  }
                >
                  4
                </Button>
                <Button
                  value="5"
                  className={
                    roomnum === "5" ? "active-btn btn-primary" : "btn-secondary"
                  }
                >
                  5
                </Button>
                <Button
                  value="6+"
                  className={
                    roomnum === "6+"
                      ? "active-btn btn-primary"
                      : "btn-secondary"
                  }
                >
                  6+
                </Button>
              </ButtonGroup>
            </div>
            <div className="order-desc">
              <Form.Label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("Area in sqm")}
              </Form.Label>
              <ButtonGroup className="address-desc counter">
                <Button onClick={fnSub}> - </Button>
                <Form.Control
                  type="text"
                  onChange={fnFloorArea}
                  value={floorarea}
                  className="text-end"
                />
                <Button onClick={fnAdd}> + </Button>
              </ButtonGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "2rem", flexWrap: "wrap", 
          justifyContent: "center", alignItems: "center" }}>
            <FormControl sx={{ mr: 1, width: "30%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("Floor Covering")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                defaultValue={""}
                id="demo-simple-select-helper"
                name="floorCovering"
                required
                value={floorCovering}
                label={t("Floor Covering")}
                onChange={(event) => {
                  fnfloorCovering(event);
                  inputEvent(event);
                }}
              >
                <MenuItem selected value="">
                  {t("Select-Category")}
                </MenuItem>
                {currentOptions["floorCoveringOptions"][
                  props.resultCleaning.requestType
                ].map((list, index) => (
                  <MenuItem key={index + 1} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </Select>
              {formErrors && !floorCovering && (
                <span className="error">{formErrors}</span>
              )}
            </FormControl>
            <FormControl sx={{ mr: 1, width: "30%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("Windows with")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                defaultValue={""}
                id="demo-simple-select-helper"
                name="windowsWith"
                required
                value={windowsWith}
                label={t("Windows with")}
                onChange={(event) => {
                  fnwindowsWith(event);
                  inputEvent(event);
                }}
              >
                <MenuItem selected value="">
                  {t("Select-Category")}
                </MenuItem>
                {currentOptions["windowsWith"][
                  props.resultCleaning.requestType
                ].map((list, index) => (
                  <MenuItem key={index + 1} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </Select>
              {formErrors && !windowsWith && (
                <span className="error">{formErrors}</span>
              )}
            </FormControl>
            <FormControl sx={{ mr: 1, width: "30%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("Heating")}
              </InputLabel>

              <Select
                labelId="demo-simple-select-helper-label"
                defaultValue={""}
                id="demo-simple-select-helper"
                name="Heating"
                required
                value={heating}
                label={t("Heating")}
                onChange={(event) => {
                  fnHeating(event);
                  inputEvent(event);
                }}
              >
                <MenuItem selected value="">
                  {t("Select-Category")}
                </MenuItem>
                <MenuItem value="Underfloor Heating">
                  {t("Underfloor Heating")}
                </MenuItem>
                <MenuItem value="Wall Radiators">
                  {t("Wall Radiators")}
                </MenuItem>
              </Select>

              {formErrors && !heating && (
                <span className="error">{formErrors}</span>
              )}
            </FormControl>

            <FormControl sx={{ mr: 1, width: "30%" }}>
              <InputLabel id="demo-simple-select-helper-label">
              {t("degree-of-soiling")}
              </InputLabel>
            
            <Select
              labelId="demo-simple-select-helper-label"
              defaultValue={""}
              value={degreeOfSoiling}
              onChange={fnDegreeOfSoiling}
              // style={{ width: 200 }}
              label={t("degree-of-soiling")}
            >
              <MenuItem selected value="">
                {t("Select-Category")}
              </MenuItem>
              {Object.keys(translations[currentLang]).map((key) => (
                <MenuItem key={key} value={key}>
                  {translations[currentLang][key]}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            <div className="order-desc">
              <Form.Label style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{t("Clean-Balcony")}</Form.Label>
              <ButtonGroup
                onClick={fnCleanBalcony}
                className=""
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent:"center", alignItems:"center"
                }}
              >
                <Button
                  className={
                    CleanBalcony === "Yes"
                      ? "active-btn btn-primary"
                      : "btn-secondary"
                  }
                  value="Yes"
                >
                  {t("Yes")}
                </Button>
                <Button
                  className={
                    CleanBalcony === "No"
                      ? "active-btn btn-primary"
                      : "btn-secondary"
                  }
                  value="No"
                >
                  {t("No")}
                </Button>
              </ButtonGroup>
            </div>
            {CleanBalcony === "Yes" && (
              <div className="order-desc">
                <TextField
                  id="outlined-multiline-static"
                  onChange={fnBalconyArea}
                  label={t("Area in M2")}
                  multiline
                  placeholder={t("Area in M2")}
                  value={BalconyArea}
                  rows={2}
                />
                {formErrors && !BalconyArea && (
                  <span className="error">{formErrors}</span>
                )}
              </div>
            )}
          </div>
        </span>
        <span className="prevNext-btn card-box">
          <Button variant="warning" onClick={fnClickPrev}>
            {t("prev")}
          </Button>

          <Button variant="warning" onClick={fnClickNext}>
            {t("next")}
          </Button>
        </span>
      </div>
    </div>
  );
};
const PaintingRequestDetails = ({ props }) => {
  const theme = useTheme();
  const options = {
    Inside: [
      { en: "Renew walls", fr: "Rénover les murs", it: "Rinnova pareti", de: "Wände erneuern" },
      { en: "ceilings", fr: "plafonds", it: "soffitti", de: "Decken" },
      { en: "doors", fr: "portes", it: "porte", de: "Türen" },
      { en: "door frames", fr: "cadres de porte", it: "telai delle porte", de: "Türrahmen" },
      { en: "window frames", fr: "cadres de fenêtre", it: "telai delle finestre", de: "Fensterrahmen" },
      { en: "radiators", fr: "radiateurs", it: "radiatori", de: "Heizkörper" },
      { en: "staircase", fr: "escalier", it: "scala", de: "Treppe" },
      { en: "built-in closet", fr: "placard encastré", it: "armadio a muro", de: "Einbauschrank" },
      { en: "wallpaper", fr: "papier peint", it: "carta da parati", de: "Tapete" }
    ],
    Outside: [
      { en: "facade painting", fr: "peinture de façade", it: "pittura della facciata", de: "Fassadenmalerei" },
      { en: "facade cleaning", fr: "nettoyage de façade", it: "pulizia della facciata", de: "Fassadenreinigung" },
      { en: "wood painting", fr: "peinture sur bois", it: "pittura su legno", de: "Holzmalerei" },
      { en: "painting doors", fr: "peindre les portes", it: "pittura delle porte", de: "Türen malen" },
      { en: "painting door frames", fr: "peindre les cadres de porte", it: "pittura dei telai delle porte", de: "Türrahmen malen" },
      { en: "painting shutters", fr: "peindre les volets", it: "pittura delle persiane", de: "Läden malen" }
    ],
    Business: [
      { en: "Renew walls", fr: "Rénover les murs", it: "Rinnova pareti", de: "Wände erneuern" },
      { en: "ceilings", fr: "plafonds", it: "soffitti", de: "Decken" },
      { en: "doors", fr: "portes", it: "porte", de: "Türen" },
      { en: "door frames", fr: "cadres de porte", it: "telai delle porte", de: "Türrahmen" },
      { en: "window frames", fr: "cadres de fenêtre", it: "telai delle finestre", de: "Fensterrahmen" },
      { en: "radiators", fr: "radiateurs", it: "radiatori", de: "Heizkörper" },
      { en: "staircase", fr: "escalier", it: "scala", de: "Treppe" },
      { en: "built-in closet", fr: "placard encastré", it: "armadio a muro", de: "Einbauschrank" },
      { en: "wallpaper", fr: "papier peint", it: "carta da parati", de: "Tapete" }
    ]
  };
  const { t } = useTranslation();
  const [moveOutAddress, setmoveOutAddress] = useState(
    props.resultCleaning.moveOutAddress
  );
  const [roomnum, setRoomnum] = useState(props.resultCleaning.roomnum);
  const [floorarea, setFloorarea] = useState(props.resultCleaning.floorarea);
  const [lat, setLat] = useState(props.resultCleaning.latitude);
  const [lng, setLng] = useState(props.resultCleaning.longitude);
  const [zipcode, setZipcode] = useState(props.resultCleaning.postcode);
  const [selectedOption1, setSelectedOption1] = useState(
    props.resultCleaning.whatToPaint
  );
  const [PaintedLastTime, setPaintedLastTime] = useState(
    props.resultCleaning.PaintedLastTime
  );
  const [SmokingHousehold, setSmokingHousehold] = useState(
    props.resultCleaning.SmokingHousehold
  );
  const [isolatingcoating, setisolatingcoating] = useState(
    props.resultCleaning.isolatingcoating
  );
  const [formErrors, setformErrors] = useState("");
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOption1(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const fnFloorArea = (e) => {
    setFloorarea(e.target.value);
  };
  const fnRoomNUm = (e) => {
    setRoomnum(e.target.value);
  };
  const fnAdd = () => {
    setFloorarea(parseInt(floorarea) + 1);
  };
  const fnSub = () => {
    setFloorarea(parseInt(floorarea) - 1);
  };
  const fnPaintedLastTime = (e) => {
    setPaintedLastTime(e.target.value);
    setformErrors("");
  };
  const fnSmokingHousehold = (e) => {
    setSmokingHousehold(e.target.value);
  };
  const fnisolatingcoating = (e) => {
    setisolatingcoating(e.target.value);
  };
  const inputEvent = (e) => {
    let name = "",
      value = "";
    if (e.target === undefined) {
      name = "date";
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    //validations

    setformErrors(e.target?.value === "" ? "Please select Category" : "");
  };

  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      moveOutAddress: moveOutAddress,
      roomnum: roomnum,
      floorarea: floorarea,
      latitude: lat,
      longitude: lng,
      postcode: zipcode,
      whatToPaint: selectedOption1,
      PaintedLastTime: PaintedLastTime,
      SmokingHousehold: SmokingHousehold,
      isolatingcoating: isolatingcoating,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (moveOutAddress && !(selectedOption1.length === 0) && PaintedLastTime) {
      const newState = Object.assign({}, props.resultCleaning, {
        moveOutAddress: moveOutAddress,
        roomnum: roomnum,
        floorarea: floorarea,
        latitude: lat,
        longitude: lng,
        postcode: zipcode,
        whatToPaint: selectedOption1,
        PaintedLastTime: PaintedLastTime,
        SmokingHousehold: SmokingHousehold,
        isolatingcoating: isolatingcoating,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    } else {
      setformErrors(t("Please fill required fields."));
    }
  };
  return (
    <div className="category-component">
      <div className="category-container">
        <span className="card-box" style={{ flexDirection: "column" }}>
          <span className="sub-heading">{t("Here needs to be painted:")}</span>
          <AutoCompleteAddress
            address={props.resultCleaning.moveOutAddress}
            setAddress={setmoveOutAddress}
            setLat={setLat}
            setLng={setLng}
            setZipcode={setZipcode}
            setformErrors={setformErrors}
          />
          {formErrors && !moveOutAddress && (
            <span className="error" style={{ marginTop: "-1.2rem" }}>
              {formErrors}
            </span>
          )}

          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <div className="order-desc">
              <Form.Label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>{t("Number of Rooms")}</Form.Label>
              <ButtonGroup onClick={fnRoomNUm} className="address-desc">
                <Button
                  value="1"
                  className={roomnum === "1" ? "active-btn btn-primary" : "btn-secondary"}
                >
                  1
                </Button>
                <Button
                  value="2"
                  className={roomnum === "2" ? "active-btn btn-primary" : "btn-secondary"}
                >
                  2
                </Button>
                <Button
                  value="3"
                  className={roomnum === "3" ? "active-btn btn-primary" : "btn-secondary"}
                >
                  3
                </Button>
                <Button
                  value="4"
                  className={roomnum === "4" ? "active-btn btn-primary" : "btn-secondary"}
                >
                  4
                </Button>
                <Button
                  value="5"
                  className={roomnum === "5" ? "active-btn btn-primary" : "btn-secondary"}
                >
                  5
                </Button>
                <Button
                  value="6+"
                  className={roomnum === "6+" ? "active-btn btn-primary" : "btn-secondary"}
                >
                  6+
                </Button>
              </ButtonGroup>
            </div>
            <div className="order-desc">
              <Form.Label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>{t("Area in sqm")}</Form.Label>
              <ButtonGroup className="address-desc counter">
                <Button onClick={fnSub}> - </Button>
                <Form.Control
                  type="text"
                  onChange={fnFloorArea}
                  value={floorarea}
                  className="text-end"
                />
                <Button onClick={fnAdd}> + </Button>
              </ButtonGroup>
            </div>
            <div className="order-desc">
              
            <FormControl >
  <InputLabel id="demo-multiple-name-label">
    {props.resultCleaning.requestType === "Outside"
      ? t("What-work-needs-tobe-done")
      : t("what-need-tobe-painted")}
  </InputLabel>
  <Select
    labelId="demo-multiple-name-label"
    id="demo-multiple-name"
    multiple
    value={selectedOption1}
    onChange={handleChange}
    input={
      <OutlinedInput
        label={
          props.resultCleaning.requestType === "Outside"
            ? t("What-work-needs-tobe-done")
            : t("what-need-tobe-painted")
        }
      />
    }
    MenuProps={MenuProps}
  >
    {options[props.resultCleaning.requestType].map((option, index) => (
      <MenuItem
        key={index}
        value={option[currentLang]} // Assuming `currentLang` holds the selected language
        style={getStyles(option[currentLang], selectedOption1, theme)}
      >
        {option[currentLang]}
      </MenuItem>
    ))}
  </Select>
</FormControl>
              {formErrors && selectedOption1.length === 0 && (
                <span className="error">{formErrors}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            <div className="order-desc">
              <TextField
                id="outlined-multiline-static"
                onChange={fnPaintedLastTime}
                label={t("last-paint-time")}
                multiline
                placeholder={t("last-paint-time")}
                value={PaintedLastTime}
                rows={5}
              />
              {formErrors && !PaintedLastTime && (
                <span className="error">{formErrors}</span>
              )}
            </div>
            {props.resultCleaning.requestType !== "Outside" && (
              <div className="order-desc">
                <Form.Label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>{t("smoking-household")}</Form.Label>
                <ButtonGroup
                  onClick={fnSmokingHousehold}
                  className="address-desc"
                >
                  <Button
                    className={
                      SmokingHousehold === "Yes"
                        ? "btn-primary"
                        : "btn-secondary"
                    }
                    value="Yes"
                  >
                    {t("Yes")}
                  </Button>
                  <Button
                    className={
                      SmokingHousehold === "No"
                        ? "btn-primary"
                        : "btn-secondary"
                    }
                    value="No"
                  >
                    {t("No")}
                  </Button>
                </ButtonGroup>
              </div>
            )}
            {SmokingHousehold === "Yes" && (
              <div className="order-desc">
                <Form.Label>{t("isolating-coating")}</Form.Label>
                <ButtonGroup
                  onClick={fnisolatingcoating}
                  className="address-desc"
                >
                  <Button
                    className={
                      isolatingcoating === "Yes"
                        ? "btn-primary"
                        : "btn-secondary"
                    }
                    value="Yes"
                  >
                    {t("Yes")}
                  </Button>
                  <Button
                    className={
                      isolatingcoating === "No"
                        ? "btn-primary"
                        : "btn-secondary"
                    }
                    value="No"
                  >
                    {t("No")}
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </div>
        </span>
        <span className="prevNext-btn card-box">
          <Button variant="warning" onClick={fnClickPrev}>
            {t("prev")}
          </Button>

          <Button variant="warning" onClick={fnClickNext}>
            {t("next")}
          </Button>
        </span>
      </div>
    </div>
  );
};
const CarpenterRequestDetails = ({ props }) => {
  const { t } = useTranslation();
  const [moveOutAddress, setmoveOutAddress] = useState(
    props.resultCleaning.moveOutAddress
  );
  const [lat, setLat] = useState(props.resultCleaning.latitude);
  const [lng, setLng] = useState(props.resultCleaning.longitude);
  const [zipcode, setZipcode] = useState(props.resultCleaning.postcode);
  const [formErrors, setformErrors] = useState("");

  const fnClickPrev = () => {
    const newState = Object.assign({}, props.resultCleaning, {
      moveOutAddress: moveOutAddress,
      latitude: lat,
      longitude: lng,
      postcode: zipcode,
    });
    props.setResultCleaning(newState);
    props.setCurrentstep(props.currentstep - 1);
  };
  const fnClickNext = () => {
    if (moveOutAddress) {
      const newState = Object.assign({}, props.resultCleaning, {
        moveOutAddress: moveOutAddress,
        latitude: lat,
        longitude: lng,
        postcode: zipcode,
      });
      props.setResultCleaning(newState);
      props.setCurrentstep(props.currentstep + 1);
    } else {
      setformErrors("Please fill required fields.");
    }
  };

  return (
    <div className="category-component">
      <div className="category-container">
        <span className="card-box" style={{ flexDirection: "column" }}>
          <span className="sub-heading">
            {props.resultCleaning.requestType === "Interior design"
              ? t("interior-design-text")
              : props.resultCleaning.requestType === "Bathroom & Kitchens"
              ? t("bathroom-kitchen-text")
              : props.resultCleaning.requestType === "Windows & Doors"
              ? t("windows-door-text")
              : props.resultCleaning.requestType === "Repair & Restoration"
              ? t("repair-restoration-text")
              : " "}
          </span>
          <FormControl fullWidth>{/* here */}</FormControl>

          <AutoCompleteAddress
            address={props.resultCleaning.moveOutAddress}
            setAddress={setmoveOutAddress}
            setLat={setLat}
            setLng={setLng}
            setZipcode={setZipcode}
            setformErrors={setformErrors}
          />

          {formErrors && !moveOutAddress && (
            <span className="error" style={{ marginTop: "-1.2rem" }}>
              {formErrors}
            </span>
          )}
        </span>
        <span className="prevNext-btn card-box">
          <Button variant="warning" onClick={fnClickPrev}>
            {t("prev")}
          </Button>

          <Button variant="warning" onClick={fnClickNext}>
            {t("next")}
          </Button>
        </span>
      </div>
    </div>
  );
};

const AutoCompleteAddress = ({
  address,
  setAddress,
  setLat,
  setLng,
  setZipcode,
  setformErrors,
}) => {
  const { t } = useTranslation();
  const [addressflag, setAddresflag] = useState(false);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
    setformErrors("");
  };

  const handleSelect = (val) => {
    setValue(val, false);
    setAddress(val);
    setAddresflag(false);
    const parameter = {
      address: val,
    };

    getGeocode(parameter)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLat(latLng.lat);
        setLng(latLng.lng);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    getGeocode(parameter)
      .then((results) => getZipCode(results[0], false))
      .then((zipCode) => {
        setZipcode(zipCode);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    getGeocode(parameter)
      .then((results) =>
        getDetails({
          placeId: results[0].place_id,
          fields: ["name", "rating"],
        })
      )
      .then((details) => {
        console.log("Details: ", details);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  useEffect(() => {
    setValue(address);
  }, []);

  return (
    <Combobox
      onSelect={handleSelect}
      aria-labelledby="demo"
      className="combobox"
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="address"
        name="address"
        label={t("address")}
        autoFocus
        value={value}
        onChange={handleInput}
        disabled={!ready}
        className={
          addressflag
            ? "border border-danger w-100 textField"
            : "w-100 textField"
        }
      />
      <ComboboxInput
        style={{ visiblity: "hidden", height: "0px" }}
        value={value}
        onChange={handleInput}
        disabled={!ready}
      />

      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default Address;
