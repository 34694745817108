import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { getAuthAxios } from "../../../../utils/utils";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import PayPalIntegration from "./PayPalIntegration";
import PayPalAdvDeposit from "./PayPalAdv";
import { useSelector } from "react-redux";

const Finance = () => {
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const companyDetails = useSelector((state) => state.companyReducer);
  const [walletBalance, setWalletBalance] = useState(0);
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState(""); // Initialize the paymentMethod state
  const [enteredAmount, setEnteredAmount] = useState(0); // Initialize enteredAmount state
  const [creditCardInfo, setCreditCardInfo] = useState(null);
  const [transactionInfo, setTransactionInfo] = useState(null);
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  // const handleRecharge = () => {
  //   <Checkout/>
  // };

  // useEffect(() => {
  //   authAxios
  //     .get("/company/finance")
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setWalletBalance(response.data.amount);
  //       } else {
  //         alert(JSON.stringify(response));
  //       }
  //     })
  //     .catch((err) => {
  //       alert("catch block" + err);
  //     });
  // }, []);

  useEffect(() => {
    
    // Fetch credit card info when the component mounts
    authAxios
      .get(`https://api.easy-compare.chapi/creditCardInfo/${companyDetails.id}`) // Replace with the actual API endpoint
      .then((response) => {
        if (response.status === 200) {
          setCreditCardInfo(response.data);
        } else {
          // console.log(response.data);
          alert("Failed to fetch credit card info");
        }

        console.log(response.data);
      })
      .catch((err) => {
        console.log("Error Happened at ID *19827322");
      });
  }, []);

  useEffect(() => {
    
    // Fetch transaction info when the component mounts
    authAxios
      .get(`https://api.easy-compare.chtransactions/get-by-company/${companyDetails.id}`) // Replace with the actual API endpoint
      .then((response) => {
        if (response.status === 200) {
          setTransactionInfo(response.data);
        } else {
          // console.log(response.data);
          alert("Failed to fetch transaction info");
        }

        setTransactionInfo(response.data);
        console.log(response.data.dateTime)
      })
      .catch((err) => {
        
        console.log("Error happened at ID 9183929");
      });
  }, []);

  useEffect(() => {
    // Fetch amount info when the component mounts
    authAxios
      .get(`https://api.easy-compare.chapi/getCreditAmount/${companyDetails.id}`) // Replace with the actual API endpoint
      .then((response) => {
        if (response.status === 200) {
          setWalletBalance(response.data.amount);
        } else {
          // console.log(response.data);
          alert("Failed to fetch amount info");
        }

        // console.log(response.data);
      })
      .catch((err) => {
        console.log("Error Happened at ID *9218382");
      });
  }, []);

  return (
    <div>
      <Card>
        <div className="credit-info-show">
          {/* Display credit card info here */}
          {creditCardInfo && (
            <div>
              <h2>Credit Card Information</h2>
              <p>Card Number: {creditCardInfo.fullCardNumber}</p>
              <p>Card Holder: {creditCardInfo.cardHolderName}</p>
              <p>Expiry Date: {creditCardInfo.expiryDate}</p>
              <p>CVV: {creditCardInfo.cvv}</p>
              <p>Card Type: {creditCardInfo.cardType}</p>
              {/* Display other credit card information as needed */}
            </div>
          )}
        </div>
      </Card>
  

      <div className="finance-container">
        <Card>
          <CardContent>
            <div className="wallet">
              <h2 className="finance-card-title">{t("curr-bal")}</h2>
              <h2 className="finance-card-value">{walletBalance}</h2>
              <button className="btn btn-primary" >
                {t("recharge")}
              </button>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="payment-methods">
        <Card>
          <CardContent>
            <div className="payments">
              <h2>Payment Methods</h2>
              <div>
                {/* Radio buttons for payment method */}
                <label>
                  <input
                    type="radio"
                    value="credit"
                    checked={paymentMethod === "credit"}
                    onChange={handlePaymentMethodChange}
                  />
                  Desposit Cash
                </label>
                <label>
                  <input
                    type="radio"
                    value="creditCard"
                    checked={paymentMethod === "creditCard"}
                    onChange={handlePaymentMethodChange}
                  />
                  Save Credit Card Information
                </label>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="credit-card-method">
        <Card>
            {paymentMethod === "creditCard" ? (
              <div className="payment-methods">
                <div className="payments">
                <h2>Credit Card Info</h2>
                  {paymentMethod === "creditCard" ? (
                    <PayPalIntegration />
                  ) : null}
                </div>
              </div>
            ) : null}
        </Card>
      </div>

      <div className="credit-card-method">
        <Card>
            {paymentMethod === "credit" ? (
              <div className="payment-methods">
                <div className="payments">
                <h2>Credit Card Info</h2>
                  {paymentMethod === "credit" ? (
                    <PayPalAdvDeposit/>
                  ) : null}
                </div>
              </div>
            ) : null}
        </Card>
      </div>

      <div className="transactions-method">
  <Card>
    <div className="transactions-info-show">
      {/* Display credit card info here */}
      {transactionInfo && transactionInfo.length > 0 ? (
        <div>
          <h2>Transactions Information</h2>
          {transactionInfo.map((transaction, index) => (
            <div key={index}>
              <p>Transaction Type: {transaction.transactionType}</p>
              <p>Date and Time of Transaction: {transaction.dateTime}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No transactions found.</p>
      )}
    </div>
  </Card>
</div>

{/* Fix for the mobile style of deposit and save information. */}


    </div>
  );
};

export default Finance;
