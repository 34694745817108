import { Avatar, Button as MuiButton} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { createRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getAuthAxios} from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 220px;
  height: 220px;
  margin: 0 auto 8px; 

  ${({ $withBorder }) =>
    $withBorder &&
    `border: 1px solid ${grey[500]}; min-height:150px;min-width:150px;
     box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};`}
`;

const AvatarUpload = ({imageProfile}) => {
  const navigate = useNavigate();
  const authAxios = getAuthAxios(navigate);
  const { t } = useTranslation();
  const [image, _setImage] = useState(imageProfile);
  const inputFileRef = createRef(null);
   
  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const REACT_APP_BACKEND_SERVER2 = "https://api.easy-compare.ch";

  const setImage = (newImage) => {
    // if (image) {
    //   cleanup();
    // }
    _setImage(newImage);
    // if(newImage){
      authAxios.post(REACT_APP_BACKEND_SERVER2 + "/company/updateImage", newImage)
          .then((res) => {
            if (res.data.status === "Success") {
              alert(t("req-sub-success"));
            } else {
              alert(t("something-went-wrong"));
            }
          })
          .catch((error) => {
            console.log("Error happened at ID 91023883");
          });
    // }
  };

  const handleOnChange = (event) => {    
    const data = new FileReader();
    data.readAsDataURL(event.target.files[0]);
    data.onload = (event) => {
      setImage(data.result);
      console.log("image data", data.result);
    };

    data.onerror = error =>{
      console.log("Error",error)
    };
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    if (image!==null && image!==' ') {
      setImage(' ');
    }
  };

  return (
    <CenteredContent>
      <BigAvatar
        $withBorder
        alt="Avatar"
        src={image || "/static/img/avatars/default-profile.svg"}
        imgProps={{
          style: {
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          },
        }}
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      <label htmlFor="avatar-image-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          mb={2}
          onClick={handleClick}
        >
          {image!==" " && image!==null ? <DeleteIcon mr={2} /> : <UploadIcon mr={2} />}
          {image!==" " && image!==null ? "Delete" : "Upload"}
        </Button>
      </label>
      
      
    </CenteredContent> 
  );
};

export default AvatarUpload;