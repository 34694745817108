import React from 'react';
import '../CleaningPage/Pricing.css';
import '../CarpenterPage/CarpenterWorkingProcess.css';
import { useTranslation } from 'react-i18next'
import "../MovingPage/index.css"
import MediaQuery from 'react-responsive';

const PaintingProcess = (props) => {
   const { t } = useTranslation();
   return <>
      <MediaQuery minWidth={1026}>
         <div className="container moving mt-10 mb-6">
            <div className='carpenter-working-process'>
               <span className="sub-heading hidden">{t("Moving-Process")}</span>
               <div className="main-heading mb-4 " style={{}}>{t("PaintingWrokingProcess")}</div>
               <div className="circle-container ">
                  <div className="circle">{t("Client")}</div>
                  <div className="line"></div>
                  <div className="circle dark">{t("Company")}</div>
                  <div className="line"></div>
                  <div className="circle">{t("PaintingCompany")}</div>
               </div>
               <p className="work-info">
                  {t("MovingReocation-workinfo")}
               </p>
               {props.children}
            </div>
         </div>
      </MediaQuery>


      <MediaQuery maxWidth={1024}>
         <div className="moving mt-10 mb-6">
            <div className='carpenter-working-process-mobile'>
               <span className="sub-heading hidden">{t("Moving-Process")}</span>
               <div className="main-heading mb-4 " style={{}}>{t("PaintingWrokingProcess")}</div>
               <div className="circle-container ">
                  <div className="circle">{t("Client")}</div>
                  <div className="line"></div>
                  <div className="circle dark">{t("Company")}</div>
                  <div className="line"></div>
                  <div className="circle">{t("PaintingCompany")}</div>
               </div>
               <p className="work-info">
                  {t("MovingReocation-workinfo")}
               </p>
               {props.children}
            </div>
         </div>
      </MediaQuery>
   </>
}

export default PaintingProcess;