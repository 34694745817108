import Button from '@mui/material/Button';
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useEffect, useState,useMemo } from "react";
import { getAuthAxios } from "../../../utils/utils";
import RequestDrop from '../Requests/RequestDrop';
import '../Requests/index.css';
import { adminReducerAction } from '../../../redux/reducers/adminReducer';
import { useDispatch } from "react-redux";
import { getRequestAction } from '../../../redux/actions/getRequestAction';
import { recordsPerPage } from '../../../utils/utils';
import '../../common/common.css';
import ReactPaginate from 'react-paginate';
import Loader from '../../common/Loader';
const CompanyRequests = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const authAxios = getAuthAxios(navigate);
   const [isLoading, setIsLoading] = useState(false);
   const [company, setCompany] = useState(useSelector(state => state.adminReducer.currentCompany));
   const email = company.email;
   const [companyRequests, setCompanyRequests] = useState(company.requests);


   useEffect(() => {
      setIsLoading(true);
      authAxios.get("/admin/getCompanyRequests", { params: { email: email } }).then((response) => {
         if (response.status === 200) {
            setCompanyRequests(response.data.requests);
            dispatch(getRequestAction(response.data.requests));
            setIsLoading(false);
         }
         else {
            alert(JSON.stringify(response));
            setIsLoading(false);
         }
      }).catch((err) => {
         console.log("catch block CompanyDetails" + err);
      });
   }, []);


   const [startItemIndex, setStartItemIndex] = useState(0);
   const [pageCount, setPageCount] = useState();

   const currentItems = useMemo(() => {
      if (companyRequests !== undefined && companyRequests.length !== 0) {
         setPageCount(Math.ceil(companyRequests.length / recordsPerPage));
         const endItemIndex = startItemIndex + recordsPerPage;
         // console.log(`Loading items from ${startItemIndex} to ${endItemIndex}`);
         return companyRequests.slice(startItemIndex, endItemIndex);
      } else {
         return companyRequests;
      }
   }, [companyRequests, startItemIndex])

   const handlePageClick = (event) => {
      const newStartIndex = (event.selected * recordsPerPage) % companyRequests.length;
      // console.log(`User requested page number ${event.selected}, which is offset ${newStartIndex}`);
      setStartItemIndex(newStartIndex);

   };


   return <>
      <div className="company-request">
         <Button variant="contained" size="small" className="Back-btn">
            <Link to={"/admin/companydetails/"}>« Back to All companies</Link>
         </Button>
         <div className='sub-heading-2x request-cmp-title'>
            Requests for {company.name} company
         </div>
         {(companyRequests !== undefined && companyRequests !== null && companyRequests.length !== 0) ?
            (<div className="accordian-request-container">
               {currentItems.map((list, index) => (
                  <Accordian key={index} details={list} index={index} company={company} />
               ))}
            </div>)
            : (isLoading &&  <Loader /> )
         }
      </div>
      <div className='custom-paginate'>
         <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
         />
      </div>
   </>
}


const Accordian = (props) => {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const setRequestDetails = (details) => {
      dispatch(adminReducerAction.setSelectedRequest(details));
      navigate("/admin/companydetails/companyrequests/requestdetails")
   }


   return <>
      <Accordion className="Accordion">
         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="accordion-button">
            <div className="header-content">
               {/* {props.details.category.type} in {props.details.address.location} */}
               <br></br>
               {props.details.date.split("T")[0]}
               <br />
               <div style={{ paddingRight: "1rem" }}>
                  CHF 22
                  <br />
                  {
                     props.details.status === "Processing" || props.details.status === "Sent" ?
                        <Button variant="contained" size="small" onClick={() => setRequestDetails(props.details)}>
                           <a>For Purchase</a>
                           {/* <Link to={"/admin/companydetails/companyrequests/requestdetails?index=" + props.index + "&company="+props.listindex}>For Purchase</Link> */}
                        </Button>
                        : props.details.acceptedBy === props.company.email ?
                           <Button variant="contained" color={"success"} sx={{ backgroundColor: "green", color: "white" }} size="small" onClick={() => setRequestDetails(props.details)}> <a>Purchased</a></Button>
                           : <Button variant="outlined" color="error" size="small">Sold out</Button>
                  }

               </div>
            </div>
         </AccordionSummary>
         <AccordionDetails > <RequestDrop list={props.details} /> </AccordionDetails>
      </Accordion>
   </>
}
export default CompanyRequests;

